import {Box, styled} from "@mui/material";

const StyledDatePickerContainer = styled(Box)`
    .react-datepicker {
        font-family: Inter, sans-serif;
    }

    .react-datepicker__header {
        background-color: #ffeb3b !important;
    }

    .react-datepicker__current-month,
    .react-datepicker-time__header {
        color: black;
    }

    /* Style for selected date */
    .react-datepicker__day--selected,
    .react-datepicker__day--keyboard-selected {
        background-color: black !important;
        color: white !important;

        &:hover {
            background-color: #333 !important;
        }
    }

    /* Center align time list */
    .react-datepicker__time-list {
        text-align: center !important;
    }

    .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
        text-align: center !important;
        padding: 14px 0 0 0 !important;
    }

    /* Style for selected time */
    .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
        background-color: black !important;
        color: white !important;

        &:hover {
            background-color: #333 !important;
        }
    }
`;

export default StyledDatePickerContainer;