import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getToken } from '../utils/authUtils';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const getAuthHeaders = async () => {
    const token = await getToken();
    return {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
    };
};

export const createAgreement = createAsyncThunk(
    'agreement/create',
    async (agreementData, { rejectWithValue }) => {
        try {
            const headers = await getAuthHeaders();
            const response = await axios.post(`${API_BASE_URL}/agreements`, agreementData, { headers });
            return response.data.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const fetchAgreement = createAsyncThunk(
    'agreement/fetchAgreement',
    async (id, { rejectWithValue }) => {
        try {
            const headers = await getAuthHeaders();
            const response = await axios.get(`${API_BASE_URL}/agreements?id=${id}`, { headers });
            return response.data.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const getAgreements = createAsyncThunk(
    'agreement/getAgreements',
    async (params = { page: 0, pageSize: 10 }, { rejectWithValue }) => {
        try {
            const headers = await getAuthHeaders();
            const response = await axios.get(`${API_BASE_URL}/agreements`, {
                headers,
                params: {
                    page: params.page + 1, // API expects 1-based indexing
                    limit: params.pageSize,
                    search: params.searchQuery,
                },
            });

            // Ensure we have the expected data structure
            return {
                agreements: response.data.data.agreements || [],
                pagination: {
                    totalCount: response.data.data.pagination?.totalCount || 0,
                    currentPage: params.page,
                    pageSize: params.pageSize
                }
            };
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const updateAgreement = createAsyncThunk(
    'agreement/update',
    async ({ id, agreementData }, { rejectWithValue }) => {
        try {
            const headers = await getAuthHeaders();
            const response = await axios.patch(`${API_BASE_URL}/agreements?id=${id}`, agreementData, { headers });
            return response.data.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const deleteAgreement = createAsyncThunk(
    'agreement/delete',
    async (id, { rejectWithValue }) => {
        try {
            const headers = await getAuthHeaders();
            await axios.delete(`${API_BASE_URL}/agreements?id=${id}`, { headers });
            return id; // Return the id for filtering in the reducer
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

const initialState = {
    agreements: [],
    currentAgreement: null,
    status: 'idle',
    error: null,
    pagination: {
        totalCount: 0,
        currentPage: 0,
        pageSize: 10
    }
};

const agreementSlice = createSlice({
    name: 'agreement',
    initialState,
    reducers: {
        clearError: (state) => {
            state.error = null;
        },
        resetStatus: (state) => {
            state.status = 'idle';
        }
    },
    extraReducers: (builder) => {
        builder
            // Create Agreement
            .addCase(createAgreement.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(createAgreement.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.agreements = [...state.agreements, action.payload];
                state.error = null;
            })
            .addCase(createAgreement.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to create agreement';
            })

            // Get Agreements
            .addCase(getAgreements.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(getAgreements.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.agreements = action.payload.agreements;
                state.pagination = action.payload.pagination;
                state.error = null;
            })
            .addCase(getAgreements.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to fetch agreements';
            })

            // Fetch Single Agreement
            .addCase(fetchAgreement.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(fetchAgreement.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.currentAgreement = action.payload;
                state.error = null;
            })
            .addCase(fetchAgreement.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to fetch agreement';
            })

            // Update Agreement
            .addCase(updateAgreement.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(updateAgreement.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const index = state.agreements.findIndex(agreement => agreement.id === action.payload.id);
                if (index !== -1) {
                    state.agreements[index] = action.payload;
                }
                state.currentAgreement = action.payload;
                state.error = null;
            })
            .addCase(updateAgreement.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to update agreement';
            })

            // Delete Agreement
            .addCase(deleteAgreement.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(deleteAgreement.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.agreements = state.agreements.filter(agreement => agreement.id !== action.payload);
                state.error = null;
            })
            .addCase(deleteAgreement.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to delete agreement';
            });
    },
});

export const { clearError, resetStatus } = agreementSlice.actions;
export default agreementSlice.reducer;