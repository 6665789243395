import React, { useState } from 'react';
import {
    Box,
    Typography,
    Switch,
    TextField,
    Button,
    Grid,
    Paper,
    MenuItem,
    Alert,
    CircularProgress,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import {getToken} from "../../../../../../utils/authUtils";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const PaymentForm = ({ header, subHeader, imgSrc, imgAlt, onCancel, initialEnabled = false }) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const [formData, setFormData] = useState({
        enabled: initialEnabled,
        title: '',
        description: '',
        merchantId: '',
        secretKey: '',
        hashType: 'SHA256',
        environmentMode: 'test'
    });

    const handleChange = (field) => (event) => {
        const value = field === 'enabled' ? event.target.checked : event.target.value;
        setFormData(prev => ({
            ...prev,
            [field]: value
        }));
    };

    const handleSave = async () => {
        try {
            setLoading(true);
            setError(null);

            const token = await getToken();
            const payload = {
                organization_id: 12345, // Replace with your actual organization ID
                title: formData.title,
                description: formData.description || "",
                merchant_id: formData.merchantId,
                secret_key: formData.secretKey,
                hash_type: formData.hashType,
                environment_mode: formData.environmentMode,
                is_active: formData.enabled
            };

            await axios.post(
                `${API_BASE_URL}/payment-methods`,
                payload,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                }
            );

            onCancel();
        } catch (error) {
            setError(error.response?.data?.message || 'An error occurred while creating the payment method');
            console.error('Failed to create payment method:', error);
        } finally {
            setLoading(false);
        }
    };

    const isFormValid = () => {
        return formData.title &&
            formData.merchantId &&
            formData.secretKey &&
            formData.hashType &&
            formData.environmentMode;
    };

    return (
        <Paper elevation={0} sx={{ bgcolor: '#f0f0f0', p: 3, maxWidth: 600 }}>
            {error && (
                <Alert severity="error" sx={{ mb: 2 }}>
                    {error}
                </Alert>
            )}

            <Box sx={{ display: 'flex', mb: 3 }}>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 1, mr: 1 }}>
                    <Box component="img" src={imgSrc} alt={imgAlt} sx={{ width: '50px' }} />
                </Box>
                <Box>
                    <Typography variant="h6" gutterBottom>
                        {header}
                    </Typography>
                    <Typography variant="body2" gutterBottom color="text.secondary">
                        {subHeader}
                    </Typography>
                </Box>
            </Box>

            <Box component="form" sx={{ mt: 3 }}>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={4}>
                        <Typography sx={{ textAlign: 'start' }}>Enable / Disable</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Switch
                            size="small"
                            checked={formData.enabled}
                            onChange={handleChange('enabled')}
                            disabled={loading}
                        />
                        <Typography variant="body2" component="span" sx={{ ml: 1 }}>
                            Enable this payment gateway
                        </Typography>
                    </Grid>

                    <Grid item xs={4}>
                        <Typography sx={{ textAlign: 'start' }}>Title*</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <TextField
                            fullWidth
                            size="small"
                            value={formData.title}
                            onChange={handleChange('title')}
                            required
                            disabled={loading}
                        />
                    </Grid>

                    <Grid item xs={4}>
                        <Typography sx={{ textAlign: 'start' }}>Description</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <TextField
                            fullWidth
                            multiline
                            rows={4}
                            size="small"
                            value={formData.description}
                            onChange={handleChange('description')}
                            disabled={loading}
                            InputProps={{
                                style: { padding: 0 }
                            }}
                        />
                    </Grid>

                    <Grid item xs={4}>
                        <Typography sx={{ textAlign: 'start' }}>Merchant ID*</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <TextField
                            fullWidth
                            size="small"
                            value={formData.merchantId}
                            onChange={handleChange('merchantId')}
                            required
                            disabled={loading}
                        />
                    </Grid>

                    <Grid item xs={4}>
                        <Typography sx={{ textAlign: 'start' }}>Secret Key*</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <TextField
                            fullWidth
                            size="small"
                            type="password"
                            value={formData.secretKey}
                            onChange={handleChange('secretKey')}
                            required
                            disabled={loading}
                        />
                    </Grid>

                    <Grid item xs={4}>
                        <Typography sx={{ textAlign: 'start' }}>Hash Type*</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <TextField
                            fullWidth
                            size="small"
                            select
                            value={formData.hashType}
                            onChange={handleChange('hashType')}
                            required
                            disabled={loading}
                        >
                            <MenuItem value="SHA256">SHA256</MenuItem>
                            <MenuItem value="MD5">MD5</MenuItem>
                        </TextField>
                    </Grid>

                    <Grid item xs={4}>
                        <Typography sx={{ textAlign: 'start' }}>Environment Mode*</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <TextField
                            fullWidth
                            size="small"
                            select
                            value={formData.environmentMode}
                            onChange={handleChange('environmentMode')}
                            required
                            disabled={loading}
                        >
                            <MenuItem value="test">Test</MenuItem>
                            <MenuItem value="production">Production</MenuItem>
                        </TextField>
                    </Grid>
                </Grid>

                <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
                    <Button
                        variant="cancel"
                        onClick={onCancel}
                        disabled={loading}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="save"
                        onClick={handleSave}
                        disabled={!isFormValid() || loading}
                        startIcon={loading ? <CircularProgress size={20} /> : null}
                    >
                        {loading ? 'Saving...' : 'Save changes'}
                    </Button>
                </Box>
            </Box>
        </Paper>
    );
};

export default PaymentForm;