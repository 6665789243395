import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    fetchOrganizationPublicDetails,
    saveOrganizationPublicDetails,
    updateOrganizationPublicDetails
} from '../../../../redux/organizationPublicDetailsSlice';
import {
    Alert,
    Box,
    Button,
    CircularProgress,
    Divider,
    FormControl,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    Snackbar,
    TextField,
    Typography
} from "@mui/material";
import { styled } from "@mui/system";
import keycloak from "../../../../keycloak";
import EditNoteIcon from '@mui/icons-material/EditNote';

const TextFieldTitle = styled(Typography)(({ theme }) => ({
    color: 'var(--Gray-700, #344054)',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '20px',
    width: '100%',
    display: 'flex',
    marginTop: 4,
    [theme.breakpoints.up('md')]: {
        width: '312px',
    }
}));

const RequiredIndicator = styled('span')(({ theme }) => ({
    color: 'red',
    marginLeft: '4px',
}));

const PublicData = ({ requiredFields = [], onFormSubmitSuccess }) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [localDetails, setLocalDetails] = useState({});
    const [isEditing, setIsEditing] = useState(false);
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success'
    });

    const organizationPublicDetails = useSelector((state) => state.organizationPublicDetails);

    useEffect(() => {
        if (organizationPublicDetails.status === 'idle') {
            const token = keycloak.tokenParsed;
            const organizationId = token.organization_id;
            if (organizationId) {
                dispatch(fetchOrganizationPublicDetails(organizationId));
            }
        }
    }, [dispatch, organizationPublicDetails.status]);

    const { details, status, error } = organizationPublicDetails;

    useEffect(() => {
        if (organizationPublicDetails.details) {
            const publicDetails = Array.isArray(organizationPublicDetails.details) && organizationPublicDetails.details.length > 0
                ? organizationPublicDetails.details[0].data
                : organizationPublicDetails.details?.data;
            setLocalDetails(publicDetails || {});
        }
    }, [organizationPublicDetails.details]);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setLocalDetails(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        try {
            if (Object.keys(localDetails).length === 0) {
                throw new Error('Public details data is required');
            }

            let result;
            if (localDetails.id) {
                result = await dispatch(updateOrganizationPublicDetails({ id: localDetails.id, details: localDetails })).unwrap();
            } else {
                const token = keycloak.tokenParsed;
                localDetails.organization_id = token.organization_id;
                result = await dispatch(saveOrganizationPublicDetails(localDetails)).unwrap();
            }

            setSnackbar({
                open: true,
                message: 'Public details saved successfully!',
                severity: 'success'
            });
            setIsEditing(false);
            if (onFormSubmitSuccess) {
                onFormSubmitSuccess();
            }
        } catch (error) {
            console.error('Failed to save public details:', error);
            setSnackbar({
                open: true,
                message: 'Failed to save public details. Please try again.',
                severity: 'error'
            });
        } finally {
            setLoading(false);
        }
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbar({ ...snackbar, open: false });
    };

    const handleEditClick = () => {
        setIsEditing(true);
    };

    const handleCancel = () => {
        setIsEditing(false);
        if (organizationPublicDetails.details) {
            const publicDetails = Array.isArray(organizationPublicDetails.details) && organizationPublicDetails.details.length > 0
                ? organizationPublicDetails.details[0].data
                : organizationPublicDetails.details?.data;
            setLocalDetails(publicDetails || {});
        }
    };

    if (status === 'loading') {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress />
            </Box>
        );
    }

    if (status === 'failed') {
        return <Typography variant="body2" color="error">Error: {error}</Typography>;
    }

    const malaysiaStates = [
        "Johor", "Kedah", "Kelantan", "Malacca", "Negeri Sembilan",
        "Pahang", "Penang", "Perak", "Perlis", "Sabah",
        "Sarawak", "Selangor", "Terengganu", "Kuala Lumpur"
    ];

    const countries = ["Malaysia"];

    const renderField = (label, field, required = false, options = null, placeholder = '') => (
        <>
            <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} sx={{ marginBottom: '16px' }}>
                <TextFieldTitle variant="subtitle2">
                    {label} {required && <RequiredIndicator>*</RequiredIndicator>}
                </TextFieldTitle>
                {isEditing ? (
                    options ? (
                        <FormControl fullWidth sx={{ paddingRight: { md: '50px', lg: '240px' } }}>
                            <InputLabel id={`${field}-select-label`}>{`Select ${label}`}</InputLabel>
                            <Select
                                labelId={`${field}-select-label`}
                                id={`${field}-select`}
                                name={field}
                                value={localDetails[field] || ''}
                                onChange={handleInputChange}
                                required={requiredFields.includes(field)}
                            >
                                {options.map((option) => (
                                    <MenuItem key={option} value={option}>
                                        <Typography variant="body2">{option}</Typography>
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    ) : (
                        <TextField
                            name={field}
                            value={localDetails[field] || ''}
                            onChange={handleInputChange}
                            fullWidth
                            label={`Enter ${label}`}
                            placeholder={placeholder}
                            required={requiredFields.includes(field)}
                            sx={{ paddingRight: { md: '50px', lg: '240px' } }}
                        />
                    )
                ) : (
                    <Typography variant="body1">{localDetails[field] || 'N/A'}</Typography>
                )}
            </Box>
            <Divider />
        </>
    );

    return (
        <Box component="form" noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: 2 }}>
                <Typography variant="h5">
                    Public Details
                </Typography>
                {isEditing ? (
                    <Box>
                        <Button variant='cancel' onClick={handleCancel} sx={{ height: '32px', marginRight: 1 }}>
                            <Typography variant="button">Cancel</Typography>
                        </Button>
                        <Button variant='save' type="submit" sx={{ height: '32px' }} disabled={loading}>
                            <Typography variant="button">{loading ? 'Saving...' : 'Save'}</Typography>
                        </Button>
                    </Box>
                ) : (
                    <Button variant='cancel' onClick={handleEditClick} sx={{ height: '32px' }}>
                        <EditNoteIcon /> <Typography variant="button">Edit</Typography>
                    </Button>
                )}
            </Box>

            {renderField('Legal business name', 'legal_business_name', true, null, 'ys event & properties sdn bhd')}
            {renderField('Business name', 'business_name', true, null, 'ORCHARD VILLA')}
            {renderField('Statement descriptor', 'statement_descriptor', true, null, 'ORCHARDVILLA')}
            {renderField('Shortened descriptor', 'shortened_descriptor', false, null, 'OV')}

            <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} sx={{ marginBottom: '16px' }}>
                <TextFieldTitle variant="subtitle2">
                    Customer support phone number
                </TextFieldTitle>
                {isEditing ? (
                    <TextField
                        name="customer_support_phone"
                        value={localDetails.customer_support_phone || ''}
                        onChange={handleInputChange}
                        fullWidth
                        label="Enter customer support phone number"
                        placeholder='14 911 2550'
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <img
                                            src="https://upload.wikimedia.org/wikipedia/commons/6/66/Flag_of_Malaysia.svg"
                                            alt="Malaysia Flag"
                                            style={{ width: '24px', height: '16px', marginRight: '8px' }}
                                        />
                                        <Typography variant="body2">+60</Typography>
                                    </Box>
                                </InputAdornment>
                            ),
                        }}
                        sx={{ paddingRight: { md: '50px', lg: '240px' } }}
                    />
                ) : (
                    <Typography variant="body1">+60{localDetails.customer_support_phone || 'N/A'}</Typography>
                )}
            </Box>
            <Divider />

            <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} sx={{ marginBottom: '16px' }}>
                <TextFieldTitle variant="subtitle2">
                    Customer support address
                </TextFieldTitle>
                {isEditing ? (
                    <Box sx={{ width: '100%', paddingRight: { md: '50px', lg: '240px' } }}>
                        <TextField
                            name="customer_support_address_line_1"
                            value={localDetails.customer_support_address_line_1 || ''}
                            onChange={handleInputChange}
                            fullWidth
                            label="Address line 1"
                            sx={{ marginBottom: '18px' }}
                        />
                        <TextField
                            name="customer_support_address_line_2"
                            value={localDetails.customer_support_address_line_2 || ''}
                            onChange={handleInputChange}
                            fullWidth
                            label="Address line 2"
                            sx={{ marginBottom: '18px' }}
                        />
                        <TextField
                            name="postal_code"
                            value={localDetails.postal_code || ''}
                            onChange={handleInputChange}
                            fullWidth
                            label="Postal code (43000)"
                            sx={{ marginBottom: '18px' }}
                        />
                        <TextField
                            name="city"
                            value={localDetails.city || ''}
                            onChange={handleInputChange}
                            fullWidth
                            label="City"
                            sx={{ marginBottom: '18px' }}
                        />
                        <FormControl fullWidth sx={{ marginBottom: '18px' }}>
                            <InputLabel>State</InputLabel>
                            <Select
                                name="state"
                                value={localDetails.state || ''}
                                onChange={handleInputChange}
                                label="State"
                            >
                                {malaysiaStates.map((state) => (
                                    <MenuItem key={state} value={state}>
                                        {state}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl fullWidth sx={{ marginBottom: '18px' }}>
                            <InputLabel>Country</InputLabel>
                            <Select
                                name="country"
                                value={localDetails.country || ''}
                                onChange={handleInputChange}
                                label="Country"
                            >
                                {countries.map((country) => (
                                    <MenuItem key={country} value={country}>
                                        {country}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                ) : (
                    <Typography variant='body1'>
                        {[
                            localDetails.customer_support_address_line_1,
                            localDetails.customer_support_address_line_2,
                            localDetails.postal_code,
                            localDetails.city,
                            localDetails.state,
                            localDetails.country
                        ].filter(Boolean).join(', ') || 'N/A'}
                    </Typography>
                )}
            </Box>
            <Divider />

            {renderField('Customer support email', 'customer_support_email', false, null, 'ys.event.properites@gmail.com')}
            {renderField('Customer support URL', 'customer_support_url', false, null, 'https://example.com/support')}
            {renderField('Business website', 'business_website', false, null, 'www.orchardvilla.com.my')}

            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
                    <Typography variant="body2">{snackbar.message}</Typography>
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default PublicData;