import { alpha, createTheme } from '@mui/material/styles';

// Color palette definition
const colors = {
    primary: {
        main: '#ffeb3b',
        light: '#fff176',
        dark: '#fbc02d',    // Darker yellow for better visibility
        darker: '#f57f17',  // Even darker shade for icons
        lighter: '#fff9c4'
    },
    secondary: {
        main: '#8E33FF',
        light: '#B066FF',
        dark: '#6B24CC',
        lighter: '#EBD6FE',
        darker: '#48238C'
    },
    warning: {
        main: '#FFAB00',
        light: '#FFB547',
        dark: '#B76E00',
        lighter: '#FFF5CC',
        darker: '#7A4100'
    },
    error: {
        main: '#FF5630',
        light: '#FF8F73',
        dark: '#B71D18',
        lighter: '#FFE9D5',
        darker: '#7A0916'
    },
    dark: {
        50: '#71717A',
        500: '#09090B'
    },
    gray: {
        100: '#F2F4F7',
        200: '#EAECF0',
        300: '#D0D5DD',
        500: '#6D6D6D',
        700: '#344054',
        900: '#101828'
    },
    white: '#FFF',
    yellow: {
        main: '#FFED00',
        hover: '#FFC000',
        border: '#FFD600'
    },
    green: '#008c10',
    stepper: {
        incomplete: '#E0E0E0',
        active: '#FFB800',
        completed: 'orange'
    }
};

// Utility functions
export const stylesMode = {
    light: '[data-mui-color-scheme="light"] &',
    dark: '[data-mui-color-scheme="dark"] &',
};

export const mediaQueries = {
    upXs: '@media (min-width:0px)',
    upSm: '@media (min-width:600px)',
    upMd: '@media (min-width:900px)',
    upLg: '@media (min-width:1200px)',
    upXl: '@media (min-width:1536px)',
};

export function setFont(fontName) {
    return `"${fontName}",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"`;
}

export function remToPx(value) {
    return Math.round(parseFloat(value) * 16);
}

export function pxToRem(value) {
    return `${value / 16}rem`;
}

export function responsiveFontSizes({ sm, md, lg }) {
    return {
        [mediaQueries.upSm]: { fontSize: pxToRem(sm) },
        [mediaQueries.upMd]: { fontSize: pxToRem(md) },
        [mediaQueries.upLg]: { fontSize: pxToRem(lg) },
    };
}

export function hexToRgbChannel(hex) {
    if (!/^#[0-9A-F]{6}$/i.test(hex)) {
        throw new Error(`Invalid hex color: ${hex}`);
    }
    const r = parseInt(hex.substring(1, 3), 16);
    const g = parseInt(hex.substring(3, 5), 16);
    const b = parseInt(hex.substring(5, 7), 16);
    return `${r} ${g} ${b}`;
}

export function createPaletteChannel(hexPalette) {
    const channelPalette = {};
    Object.entries(hexPalette).forEach(([key, value]) => {
        channelPalette[`${key}Channel`] = hexToRgbChannel(value);
    });
    return { ...hexPalette, ...channelPalette };
}

export function varAlpha(color, opacity = 1) {
    const unsupported =
        color.startsWith('#') ||
        color.startsWith('rgb') ||
        color.startsWith('rgba') ||
        (!color.includes('var') && color.includes('Channel'));

    if (unsupported) {
        throw new Error(
            `[Alpha]: Unsupported color format "${color}".
             Supported formats are:
             - RGB channels: "0 184 217".
             - CSS variables with "Channel" prefix: "var(--palette-common-blackChannel, #000000)".`
        );
    }

    return `rgba(${color} / ${opacity})`;
}

export const hideScrollX = {
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',
    overflowX: 'auto',
    '&::-webkit-scrollbar': { display: 'none' },
};

export const hideScrollY = {
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',
    overflowY: 'auto',
    '&::-webkit-scrollbar': { display: 'none' },
};

export function textGradient(color) {
    return {
        background: `linear-gradient(${color})`,
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
        backgroundClip: 'text',
        textFillColor: 'transparent',
        color: 'transparent',
    };
}

export function bgGradient({ color, imgUrl }) {
    if (imgUrl) {
        return {
            background: `linear-gradient(${color}), url(${imgUrl})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center',
        };
    }
    return { background: `linear-gradient(${color})` };
}

export function bgBlur({ color, blur = 6, imgUrl }) {
    if (imgUrl) {
        return {
            position: 'relative',
            backgroundImage: `url(${imgUrl})`,
            '&::before': {
                position: 'absolute',
                top: 0,
                left: 0,
                zIndex: 9,
                content: '""',
                width: '100%',
                height: '100%',
                backdropFilter: `blur(${blur}px)`,
                WebkitBackdropFilter: `blur(${blur}px)`,
                backgroundColor: color,
            },
        };
    }
    return {
        backdropFilter: `blur(${blur}px)`,
        WebkitBackdropFilter: `blur(${blur}px)`,
        backgroundColor: color,
    };
}

// Typography configurations
const typography = {
    fontFamily: 'Inter',
    h1: {
        color: colors.gray[900],
        fontSize: '30px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '38px',
    },
    h2: {
        color: colors.gray[500],
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '24px',
        paddingTop: '10px',
    },
    h3: {
        color: colors.gray[500],
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '14px',
    },
};

// Component style overrides
const components = {
    MuiCssBaseline: {
        styleOverrides: {
            html: {
                backgroundColor: '#FFFFFF',
                height: '100%'
            },
            body: {
                backgroundColor: '#FFFFFF',
                height: '100%'
            }
        }
    },
    MuiAppBar: {
        styleOverrides: {
            root: {
                backgroundColor: colors.primary.main,
                color: colors.dark[500],
                boxShadow: '0px 4px 8px -2px rgba(16, 24, 40, 0.2), 0px 2px 4px -2px rgba(16, 24, 40, 0.15)'
            }
        }
    },
    MuiTabs: {
        styleOverrides: {
            indicator: {
                height: '2px',
                backgroundColor: colors.dark[500],
            },
        },
    },
    MuiTab: {
        styleOverrides: {
            root: {
                fontFamily: 'Inter',
                fontSize: '14px',
                fontStyle: 'normal',
                marginRight: '16px',
                padding: '0 4px 16px 4px',
                fontWeight: 400,
                lineHeight: '20px',
                color: colors.dark[50],
                '&.Mui-selected': {
                    color: colors.dark[500],
                    fontWeight: 600,
                },
            },
        },
    },
    MuiInputLabel: {
        styleOverrides: {
            root: {
                color: colors.gray[300],
                fontFamily: 'Inter',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '24px',
                transform: 'translate(15px, 10px) scale(1)',
                '&.MuiInputLabel-shrink': {
                    transform: 'translate(20px, -7px) scale(0.60)',
                    color: colors.gray[300],
                },
            },
        },
    },
    MuiOutlinedInput: {
        styleOverrides: {
            root: {
                borderRadius: '8px',
                backgroundColor: colors.white,
                boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
                '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: colors.gray[300],
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: colors.gray[300],
                },
            },
            input: {
                borderRadius: '8px',
                backgroundColor: colors.white,
                padding: '10px',
            },
        },
    },
    MuiButton: {
        styleOverrides: {
            root: {
                borderRadius: '8px',
                boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
                padding: '8px 16px',
                fontFamily: 'Inter',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 600,
                lineHeight: '20px',
                '&:hover': {
                    boxShadow: '0px 2px 4px 0px rgba(16, 24, 40, 0.1)',
                },
            },
        },
        variants: [
            {
                props: { variant: 'cancel' },
                style: {
                    border: `1px solid ${colors.gray[300]}`,
                    backgroundColor: colors.white,
                    height: '34px',
                    color: colors.gray[700],
                    textTransform: 'none',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    '&:hover': {
                        backgroundColor: colors.white,
                    },
                },
            },
            {
                props: { variant: 'save' },
                style: {
                    border: `1px solid ${colors.yellow.border}`,
                    backgroundColor: colors.yellow.main,
                    height: '34px',
                    color: colors.dark[500],
                    textTransform: 'none',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    '&:hover': {
                        backgroundColor: colors.yellow.hover,
                    },
                },
            },
        ],
    },
    MuiDataGrid: {
        styleOverrides: {
            root: {
                width: '100%',
                backgroundColor: 'white',
                border: 'none',
                height: 'auto',
                position: 'relative',
                '& .MuiDataGrid-root': {
                    width: '100%',
                    maxWidth: '100%',
                    overflow: 'hidden',
                },
                '& .MuiDataGrid-virtualScroller': {
                    width: '100%',
                    overflowX: 'auto',
                },
                '& .MuiDataGrid-footerContainer': {
                    width: '100%',
                    borderTop: '1px solid #eaecf0',
                    backgroundColor: '#fff',
                },
                '& .MuiDataGrid-main': {
                    padding: '0',
                    width: '100%',
                    maxWidth: '100%',
                    overflow: 'auto',
                    display: 'flex',
                    flexDirection: 'column',
                },
                '& .MuiDataGrid-columnHeaders': {
                    backgroundColor: '#f9fafb',
                    borderBottom: '1px solid #eaecf0',
                    width: '100% !important',
                    minWidth: 'fit-content',
                },
                '& .MuiDataGrid-cell': {
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    borderBottom: 'none',
                    padding: '12px 16px',
                    '&:focus': {
                        outline: 'none',
                    },
                },
                '& .MuiDataGrid-row': {
                    width: '100% !important',
                    minWidth: 'fit-content',
                    '&:hover': {
                        backgroundColor: '#f9fafb',
                    },
                },
                '& .MuiDataGrid-toolbarContainer': {
                    padding: '12px 0',
                    '& .MuiButtonBase-root': {
                        color: 'black',
                        height: '34px',
                        margin: '4px 2px',
                        minWidth: 'auto',
                        border: 'none',
                        boxShadow: 'none',
                    }
                },
                transition: 'width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
            },
            columnHeaders: {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            },
            columnHeaderTitle: {
                fontFamily: 'Inter',
                fontSize: '12px',
                fontWeight: 500,
                lineHeight: '18px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                textAlign: 'center',
            },
        },
        defaultProps: {
            slots: { toolbar: 'GridToolbar' },
            pageSizeOptions: [5, 10, 20],
            paginationMode: "server",
            checkboxSelection: true,
            disableRowSelectionOnClick: true,
            rowHeight: 70,
            autoHeight: true,
            initialState: {
                pagination: {
                    paginationModel: {
                        pageSize: 10,
                    },
                },
            },
        },
    },
    MuiCheckbox: {
        styleOverrides: {
            root: {
                '& .MuiSvgIcon-root': {
                    borderRadius: '5px',
                    fontSize: 20,
                    border: `1px solid ${colors.gray[300]}`,
                    boxShadow: 'none',
                },
                '&.Mui-checked .MuiSvgIcon-root': {
                    borderRadius: '8px',
                    backgroundColor: colors.white,
                    boxShadow: 'none',
                    overflow: 'hidden',
                    border: 'none',
                    fontSize: 22,
                },
                '& .MuiSvgIcon-root path': {
                    fill: 'none',
                    stroke: 'none',
                },
                '&.Mui-checked .MuiSvgIcon-root path': {
                    fill: '#FFB800',
                },
            },
        },
    },
    MuiMenuItem: {
        styleOverrides: {
            root: {
                border: `1px solid ${colors.gray[100]}`,
                padding: 6,
                margin: 0,
            },
        },
    },
    MuiDivider: {
        styleOverrides: {
            root: {
                height: '1px',
                alignSelf: 'stretch',
                backgroundColor: colors.gray[200],
                margin: '21px 0',
            },
        },
    },
    MuiSwitch: {
        styleOverrides: {
            switchBase: {
                '&.Mui-checked': {
                    color: '#fafafa',
                    '&:hover': {
                        backgroundColor: alpha('#4caf50', 0.12),
                    },
                },
                '&.Mui-checked + .MuiSwitch-track': {
                    backgroundColor: colors.green,
                },
            },
        },
    },
    MuiStepIcon: {
        styleOverrides: {
            root: {
                color: colors.stepper.incomplete,
                '&.Mui-completed': {
                    color: colors.stepper.completed,
                },
                '&.Mui-active': {
                    color: colors.stepper.active,
                },
            },
            text: {
                fill: '#000000',
                fontWeight: 'bold',
            },
        },
    },

    MuiCard: {
        styleOverrides: {
            root: {
                boxShadow: 'none',
                border: 'none',
                display: 'flex',
                borderRadius: '16px',
                backgroundColor: colors.white,
                height: '100%',
                cursor: 'pointer',
                overflow: 'visible',
            }
        }
    },
    MuiCardContent: {
        styleOverrides: {
            root: {
                padding: '24px',
                display: 'flex',
                gap: '16px',
                width: '100%',
                height: '130px',
                transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1), all 0.2s ease-in-out',
                backgroundColor: colors.white,
                zIndex: 0,
                position: 'relative',
                boxShadow: 'rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px',
                borderRadius: '16px',
                alignItems: 'center',
                overflow: 'hidden',
                '& .MuiCardHeader-root': {
                    padding: '24px 24px 0',
                    '& .MuiTypography-root': {
                        '&.MuiTypography-h6': {
                            variant: 'h6'
                        },
                        '&.MuiTypography-body2': {
                            variant: 'body2'
                        }
                    }
                },
                '&:hover': {
                    transform: 'translateY(-2px)',
                    boxShadow: '0px 16px 32px -4px rgba(16, 24, 40, 0.08), 0px 8px 16px -4px rgba(16, 24, 40, 0.03)',
                    backgroundColor: '#fafafa',
                },
                '&:active': {
                    transform: 'translateY(0px)',
                    boxShadow: '0px 6px 12px -4px rgba(16, 24, 40, 0.06)',
                },
                '&:last-child': {
                    paddingBottom: '24px'
                }
            }
        }
    }
};

// Create and export theme
const theme = createTheme({
    palette: {
        primary: createPaletteChannel({
            main: colors.primary.main,
            light: colors.primary.light,
            dark: colors.primary.dark,
            lighter: colors.primary.lighter,
            darker: colors.primary.darker,
        }),
        secondary: createPaletteChannel({
            main: colors.secondary.main,
            light: colors.secondary.light,
            dark: colors.secondary.dark,
            lighter: colors.secondary.lighter,
            darker: colors.secondary.darker,
        }),
        warning: createPaletteChannel({
            main: colors.warning.main,
            light: colors.warning.light,
            dark: colors.warning.dark,
            lighter: colors.warning.lighter,
            darker: colors.warning.darker,
        }),
        error: createPaletteChannel({
            main: colors.error.main,
            light: colors.error.light,
            dark: colors.error.dark,
            lighter: colors.error.lighter,
            darker: colors.error.darker,
        }),
        background: {
            default: '#FFFFFF',
        },
    },
    typography,
    components,
});

export default theme;