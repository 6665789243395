import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import {
    Box,
    Typography,
    IconButton,
    Paper,
    TextField,
    Divider,
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
    DialogContentText,
    Button,
    Snackbar,
    Alert,
    CircularProgress
} from '@mui/material';
import { Delete as DeleteIcon, Edit as EditIcon, Visibility as ViewIcon } from '@mui/icons-material';
import {
    getAgreements,
    deleteAgreement,
    fetchAgreement,
    updateAgreement,
} from '../../redux/agreementSlice';
import AddAgreement from "./AdminAgreementManagementContents/AddAgreement";
import EditAgreement from "./AdminAgreementManagementContents/EditAgreement";

const AdminAgreementPage = () => {
    const dispatch = useDispatch();

    // Safely access Redux state with default values
    const agreementState = useSelector(state => state.agreement) || {};
    const {
        agreements = [],
        status = 'idle',
        error = null,
        pagination = {
            totalCount: 0,
            currentPage: 0,
            pageSize: 10
        }
    } = agreementState;

    const [searchQuery, setSearchQuery] = useState('');
    const [openViewDialog, setOpenViewDialog] = useState(false);
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [selectedAgreement, setSelectedAgreement] = useState(null);
    const [openAddDialog, setOpenAddDialog] = useState(false);
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 10,
    });
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success'
    });
    const [confirmDialog, setConfirmDialog] = useState({
        open: false,
        agreementId: null
    });
    const [editedAgreement, setEditedAgreement] = useState(null);

    useEffect(() => {
        dispatch(getAgreements({
            page: paginationModel.page,
            pageSize: paginationModel.pageSize,
            searchQuery: searchQuery
        }));
    }, [dispatch, paginationModel, searchQuery]);

    const handleEdit = async (agreement) => {
        try {
            await dispatch(fetchAgreement(agreement.id)).unwrap();
            setEditedAgreement(agreement);
            setOpenEditDialog(true);
        } catch (error) {
            setSnackbar({
                open: true,
                message: error?.message || 'Failed to fetch agreement details',
                severity: 'error'
            });
        }
    };

    const handleSaveEdit = async () => {
        try {
            await dispatch(updateAgreement({
                id: editedAgreement.id,
                agreementData: editedAgreement
            })).unwrap();
            setSnackbar({
                open: true,
                message: 'Agreement updated successfully',
                severity: 'success'
            });
            setOpenEditDialog(false);
            dispatch(getAgreements(paginationModel));
        } catch (error) {
            setSnackbar({
                open: true,
                message: error?.message || 'Failed to update agreement',
                severity: 'error'
            });
        }
    };

    const handlePaginationModelChange = (newModel) => {
        setPaginationModel(newModel);
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
        setPaginationModel(prev => ({ ...prev, page: 0 })); // Reset to first page on search
    };

    const handleView = (agreement) => {
        setSelectedAgreement(agreement);
        setOpenViewDialog(true);
    };

    const handleDeleteClick = (id) => {
        setConfirmDialog({
            open: true,
            agreementId: id
        });
    };

    const handleConfirmDelete = async () => {
        try {
            await dispatch(deleteAgreement(confirmDialog.agreementId)).unwrap();
            setSnackbar({
                open: true,
                message: 'Agreement deleted successfully',
                severity: 'success'
            });

            // Refresh current page or go to previous if no items left
            const isLastItemOnPage = agreements.length === 1 && paginationModel.page > 0;
            if (isLastItemOnPage) {
                setPaginationModel(prev => ({ ...prev, page: prev.page - 1 }));
            } else {
                dispatch(getAgreements(paginationModel));
            }
        } catch (error) {
            setSnackbar({
                open: true,
                message: error?.message || 'Failed to delete agreement',
                severity: 'error'
            });
        } finally {
            setConfirmDialog({ open: false, agreementId: null });
        }
    };

    const handleEditChange = (event) => {
        const { name, value } = event.target;
        setEditedAgreement(prev => ({ ...prev, [name]: value }));
    };

    const handleCloseConfirmDialog = () => {
        setConfirmDialog({ open: false, agreementId: null });
    };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') return;
        setSnackbar(prev => ({ ...prev, open: false }));
    };

    const handleAddSuccess = (message, severity = 'success') => {
        setSnackbar({
            open: true,
            message,
            severity
        });
        dispatch(getAgreements(paginationModel));
        setOpenAddDialog(false);
    };

    const createMarkup = (htmlContent) => {
        return { __html: htmlContent };
    };

    const columns = [
        {
            field: 'title',
            headerName: 'Agreement Title',
            flex: 1,
            minWidth: 200
        },
        {
            field: 'status',
            headerName: 'Status',
            flex: 1,
            minWidth: 120
        },
        {
            field: 'actions',
            headerName: '',
            flex: 0.4,
            minWidth: 120,
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', gap: 1, justifyContent: 'flex-end' }}>
                    <IconButton
                        size="small"
                        onClick={() => handleView(params.row)}
                        title="View"
                    >
                        <ViewIcon />
                    </IconButton>
                    <IconButton
                        size="small"
                        onClick={() => handleEdit(params.row)}
                        title="Edit"
                    >
                        <EditIcon />
                    </IconButton>
                    <IconButton
                        size="small"
                        onClick={() => handleDeleteClick(params.row.id)}
                        title="Delete"
                    >
                        <DeleteIcon />
                    </IconButton>
                </Box>
            )
        }
    ];

    // Loading state for initial load
    if (status === 'loading' && !agreements.length) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="400px">
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Box>
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginBottom: 3
            }}>
                <Box>
                    <Typography variant="h4">Agreement Management</Typography>
                    <Typography variant="h5">Manage and track your organization's contracts and legal documents</Typography>
                </Box>
                <Button
                    variant="save"
                    onClick={() => setOpenAddDialog(true)}
                    startIcon={status === 'loading' ? <CircularProgress size={20} /> : null}
                    disabled={status === 'loading'}
                >
                    + Add Agreement
                </Button>
            </Box>

            <Divider sx={{ marginBottom: 3 }} />

            <TextField
                variant="outlined"
                placeholder="Search agreements..."
                value={searchQuery}
                onChange={handleSearchChange}
                size="small"
                sx={{ marginBottom: 3 }}
            />

            <Paper sx={{
                width: "100%",
                borderRadius: "16px",
                border: "1px solid var(--Gray-200, #EAECF0)",
                background: "var(--White, #FFF)",
                boxShadow: "0px 1px 3px 0px rgba(16, 24, 40, 0.10), 0px 1px 2px 0px rgba(16, 24, 40, 0.06)",
            }}>
                <DataGrid
                    rows={agreements}
                    columns={columns}
                    loading={status === 'loading'}
                    paginationModel={paginationModel}
                    onPaginationModelChange={handlePaginationModelChange}
                    pageSizeOptions={[5, 10, 20]}
                    rowCount={pagination.totalCount}
                    paginationMode="server"
                    disableRowSelectionOnClick
                    rowHeight={70}
                    autoHeight
                    slots={{
                        toolbar: GridToolbar,
                        loadingOverlay: CircularProgress,
                    }}
                    getRowId={(row) => row.id}
                    sx={{
                        '& .MuiDataGrid-toolbarContainer': {
                            padding: 2,
                            gap: 2
                        },
                        '& .MuiDataGrid-toolbarContainer .MuiButton-root': {
                            backgroundColor: '#737373',
                            color: '#fff',
                            height: '34px',
                            minWidth: 'auto',
                            '&:hover': {
                                backgroundColor: '#404040',
                            },
                        },
                    }}
                />
            </Paper>

            <AddAgreement
                open={openAddDialog}
                onClose={() => setOpenAddDialog(false)}
                onSuccess={handleAddSuccess}
            />

            <Dialog
                open={openViewDialog}
                onClose={() => setOpenViewDialog(false)}
                maxWidth="md"
                fullWidth
            >
                <DialogTitle>
                    {selectedAgreement?.title}
                </DialogTitle>
                <DialogContent dividers>
                    {selectedAgreement && (
                        <div dangerouslySetInnerHTML={createMarkup(selectedAgreement.content)} />
                    )}
                </DialogContent>
                <DialogActions>
                    <Button variant='cancel' onClick={() => setOpenViewDialog(false)}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            <EditAgreement
                open={openEditDialog}
                onClose={() => setOpenEditDialog(false)}
                editedAgreement={editedAgreement}
                handleEditChange={handleEditChange}
                handleSaveEdit={handleSaveEdit}
                status={status}
            />

            <Dialog
                open={confirmDialog.open}
                onClose={handleCloseConfirmDialog}
            >
                <DialogTitle>Confirm Deletion</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this agreement? This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleCloseConfirmDialog}
                        variant="cancel"
                        disabled={status === 'loading'}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={handleConfirmDelete}
                        variant="save"
                        color="error"
                        disabled={status === 'loading'}
                        startIcon={status === 'loading' ? <CircularProgress size={20} /> : null}
                    >
                        {status === 'loading' ? 'Deleting...' : 'Delete'}
                    </Button>
                </DialogActions>
            </Dialog>

            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert
                    onClose={handleCloseSnackbar}
                    severity={snackbar.severity}
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default AdminAgreementPage;