import { useEffect, useState } from 'react';
import {
    Box,
    Paper,
    Typography,
    TextField,
    IconButton,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button, Link, Skeleton, Divider
} from '@mui/material';
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { fetchCoupons, updateCoupon, deleteCoupon } from './CouponAPI';
import { DeleteIcon, EditIcon } from "../../../shared/IconButtons";
import EditCouponDialog from './EditCouponDialog';
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import EastIcon from "@mui/icons-material/East";
import AddIcon from "@mui/icons-material/Add"; // Import the new component

const CouponsList = ({ handleClickOpen }) => {
    const [coupons, setCoupons] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchQuery, setSearchQuery] = useState('');
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 10
    });
    const [totalCount, setTotalCount] = useState(0);
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [selectedCoupon, setSelectedCoupon] = useState(null);
    const [editedCoupon, setEditedCoupon] = useState(null);
    const isInitialLoad = coupons.length === 0 && loading;

    const loadCoupons = async () => {
        setLoading(true);
        try {
            const data = await fetchCoupons(paginationModel.page, paginationModel.pageSize, searchQuery);
            setCoupons(data.data.coupons);
            setTotalCount(data.data.pagination.totalCount || 0);
        } catch (error) {
            console.error('Error loading coupons:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        loadCoupons();
    }, [paginationModel, searchQuery]);

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
        setPaginationModel({ ...paginationModel, page: 0 });
    };

    const handlePaginationModelChange = (newModel) => {
        setPaginationModel(newModel);
    };

    const handleEditClick = (coupon) => {
        setSelectedCoupon(coupon);
        setEditedCoupon({ ...coupon });
        setEditDialogOpen(true);
    };

    const handleDeleteClick = (coupon) => {
        setSelectedCoupon(coupon);
        setDeleteDialogOpen(true);
    };

    const handleEditInputChange = (event) => {
        const { name, value, checked, type } = event.target;
        setEditedCoupon(prev => ({
            ...prev,
            [name]: type === 'checkbox' || type === 'switch' ? checked : value
        }));
    };

    const handleEditConfirm = async () => {
        try {
            await updateCoupon(editedCoupon.id, editedCoupon);
            loadCoupons();
            setEditDialogOpen(false);
        } catch (error) {
            console.error('Error updating coupon:', error);
        }
    };

    const handleDeleteConfirm = async () => {
        try {
            await deleteCoupon(selectedCoupon.id);
            loadCoupons();
            setDeleteDialogOpen(false);
        } catch (error) {
            console.error('Error deleting coupon:', error);
        }
    };

    const renderSkeletons = () => (
        <Box sx={{padding:'0 32px 24px 32px'}}>
            {[...Array(5)].map((_, index) => (
                <Box key={index} sx={{ display: 'flex', alignItems: 'center', mx: 1, my: 2 }}>
                    <Skeleton variant="rectangular" width={60} height={40} sx={{ mr: 5, borderRadius:'4px' }} />
                    <Box sx={{ width: '100%' }}>
                        <Skeleton variant="text" width="100%" />
                        <Skeleton variant="text" width="100%" />
                    </Box>
                </Box>
            ))}
        </Box>
    );

    const columns = [
        {
            field: 'id',
            headerName: 'ID',
            flex: 0.3,
            minWidth: 100,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                    <Typography variant="body2">{params.value}</Typography>
                </Box>
            )
        },
        {
            field: 'name',
            headerName: 'Name',
            flex: 1,
            minWidth: 150,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                    <Typography variant="body2">{params.value}</Typography>
                </Box>
            )
        },
        {
            field: 'discount_type',
            headerName: 'Discount Type',
            flex: 0.7,
            minWidth: 120,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                    <Typography variant="body2">{params.value}</Typography>
                </Box>
            )
        },
        {
            field: 'discount_value',
            headerName: 'Discount Value',
            flex: 0.7,
            minWidth: 120,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                    <Typography variant="body2">{params.value}</Typography>
                </Box>
            )
        },
        {
            field: 'apply_to_specific_products',
            headerName: 'Specific Products',
            flex: 0.8,
            minWidth: 180,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                    <Typography variant="body2">{params.value ? 'Yes' : 'No'}</Typography>
                </Box>
            )
        },
        {
            field: 'limit_total_usage',
            headerName: 'Usage Limit',
            flex: 0.7,
            minWidth: 130,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                    <Typography variant="body2">{params.value || 'Unlimited'}</Typography>
                </Box>
            )
        },
        {
            field: 'limit_date_range',
            headerName: 'Date Limited',
            flex: 0.7,
            minWidth: 130,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                    <Typography variant="body2">{params.value ? 'Yes' : 'No'}</Typography>
                </Box>
            )
        },
        {
            field: 'actions',
            headerName: '',
            flex: 0.7,
            minWidth: 150,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', height: '100%', width: '100%' }}>
                    <IconButton aria-label="edit" onClick={() => handleEditClick(params.row)}>
                        <EditIcon/>
                    </IconButton>
                    <IconButton aria-label="delete" onClick={() => handleDeleteClick(params.row)}>
                        <DeleteIcon/>
                    </IconButton>
                </Box>
            ),
        },
    ];

    return (
        <Box>
            {loading ? (
                renderSkeletons()
            ) : coupons.length === 0
                ? (
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '150px' }}>
                    <Box>
                        <IconButton sx={{ backgroundColor: '#e0e0e0', p: 2, borderRadius: 1, mb: 4 }}>
                            <LocalOfferIcon sx={{ fontSize: 40 }} />
                        </IconButton>
                        <Typography variant="h5" sx={{ fontSize: '22px', fontWeight: '700', mb: 1 }}>
                            No coupons
                        </Typography>
                        <Typography variant="body1" color="textSecondary" sx={{ maxWidth: 400, mb: 1 }}>
                            Create coupons to offer discounts on invoices, subscriptions, or entire customer accounts for however long you choose.
                        </Typography>
                        <Link href="#" variant="body2" color="#f5c542" underline="none">
                            Learn more <EastIcon sx={{ fontSize: '14px', ml: '2px' }} />
                        </Link>
                        <Box sx={{ marginTop: 3 }}>
                            <Button variant="save" startIcon={<AddIcon />} onClick={handleClickOpen}>
                                Create a coupon
                            </Button>
                        </Box>
                    </Box>
                </Box>
            ) : (
                <Box>
                    <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                        <Box />
                        <Box display="flex" gap="12px">
                            <TextField
                                variant="outlined"
                                placeholder="Search coupons"
                                value={searchQuery}
                                onChange={handleSearchChange}
                                size="small"
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        height: '34px',
                                        '& input': {
                                            height: '34px',
                                            padding: '0 14px',
                                        },
                                        '& .MuiInputBase-input': {
                                            height: '34px',
                                            padding: '0 14px',
                                        }
                                    }
                                }}
                            />
                        </Box>
                    </Box>

                    <Paper
                        sx={{
                            width: "100%",
                            borderRadius: "8px",
                            marginTop: '24px',
                            border: "1px solid var(--Gray-200, #EAECF0)",
                            background: "var(--White, #FFF)",
                            boxShadow: "0px 1px 3px 0px rgba(16, 24, 40, 0.10), 0px 1px 2px 0px rgba(16, 24, 40, 0.06)",
                        }}
                    >
                        <Typography
                            sx={{
                                color: "var(--Gray-900, #101828)",
                                fontFamily: "Inter",
                                fontSize: "18px",
                                fontStyle: "normal",
                                fontWeight: 500,
                                lineHeight: "28px",
                                padding: "20px 24px",
                            }}
                        >
                            All Coupons
                        </Typography>

                        <Divider sx={{my:0}}/>

                        <DataGrid
                            slots={{ toolbar: GridToolbar }}
                            rows={coupons}
                            columns={columns}
                            paginationModel={paginationModel}
                            onPaginationModelChange={handlePaginationModelChange}
                            rowCount={totalCount}
                            paginationMode="server"
                            loading={loading}
                            checkboxSelection
                        />
                    </Paper>
                </Box>
            )}

            {/* Edit Dialog */}
            <EditCouponDialog
                open={editDialogOpen}
                onClose={() => setEditDialogOpen(false)}
                coupon={editedCoupon}
                onChange={handleEditInputChange}
                onSave={handleEditConfirm}
            />

            {/* Delete Dialog */}
            <Dialog open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)}>
                <DialogTitle>Delete Coupon</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this coupon? This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant='cancel' onClick={() => setDeleteDialogOpen(false)}>Cancel</Button>
                    <Button variant='save' onClick={handleDeleteConfirm} color="error">Delete</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default CouponsList;
