import { useEffect, useState } from 'react';
import {
    Box,
    Paper,
    Typography,
    TextField,
    Skeleton,
    Button,
    Divider,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Alert,
    Snackbar,
} from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { useGetSpacesQuery, useDeleteSpaceMutation } from '../../../api-services/spaceApi';
import { Link, useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import { Delete as DeleteIcon, Edit as EditIcon, Visibility as ViewIcon } from '@mui/icons-material';
import { CameraAlt as CameraIcon } from '@mui/icons-material';
import ViewBookingDialog from "./ViewBookingDialog";

const SpacePlaceholder = () => (
    <Box
        sx={{
            width: '60px',
            height: '45px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#f5f5f5',
            border: '1px dashed #bdbdbd',
            borderRadius: '4px',
            gap: 0.5,
        }}
    >
        <CameraIcon sx={{ color: '#bdbdbd', fontSize: '16px' }} />
        <Typography variant="caption" sx={{ color: '#757575', fontSize: '8px' }}>
            No image
        </Typography>
    </Box>
);

const renderSkeletons = () => (
    <Box sx={{padding:'0 32px 24px 32px'}}>
        {[...Array(5)].map((_, index) => (
            <Box key={index} sx={{ display: 'flex', alignItems: 'center', mx: 1, my: 2 }}>
                <Skeleton variant="rectangular" width={60} height={40} sx={{ mr: 5, borderRadius:'4px' }} />
                <Box sx={{ width: '100%' }}>
                    <Skeleton variant="text" width="100%" />
                    <Skeleton variant="text" width="100%" />
                </Box>
            </Box>
        ))}
    </Box>
);

const BookingSetupList = () => {
    const navigate = useNavigate();
    const [searchQuery, setSearchQuery] = useState('');
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 10
    });
    const [viewDialogOpen, setViewDialogOpen] = useState(false);
    const [selectedSpace, setSelectedSpace] = useState(null);
    const [confirmDialog, setConfirmDialog] = useState({ open: false, spaceId: null });
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
    const [selectedRows, setSelectedRows] = useState([]);

    const { data, isLoading, isFetching, error } = useGetSpacesQuery({
        page: paginationModel.page + 1,
        pageSize: paginationModel.pageSize,
        search: searchQuery
    });

    const [deleteSpace] = useDeleteSpaceMutation();

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
        setPaginationModel({ ...paginationModel, page: 0 });
    };

    const handlePaginationModelChange = (newModel) => {
        setPaginationModel(newModel);
    };

    const handleEditClick = (space) => {
        navigate(`/settings/booking-setup/add-booking`, {
            state: {
                packageData: {
                    ...space,
                    id: space.id,
                    name: space.name,
                    description: space.description,
                    minimum_pax: space.minimum_pax,
                    maximum_pax: space.maximum_pax,
                    image_url: space.space_images?.[0]?.image_url,
                    spaces: space.spaces || [],
                    amenities: space.amenities || [],
                    rules: space.rules || [],
                    working_hours: space.working_hours || [],
                    pricing: space.pricing || {},
                }
            }
        });
    };

    const handleDeleteClick = (spaceId) => {
        setConfirmDialog({ open: true, spaceId });
    };

    const handleConfirmDelete = async () => {
        try {
            await deleteSpace(confirmDialog.spaceId).unwrap();
            setSnackbar({ open: true, message: 'Space deleted successfully', severity: 'success' });
            setConfirmDialog({ open: false, spaceId: null });
        } catch (error) {
            setSnackbar({
                open: true,
                message: error?.data?.message || 'Failed to delete space',
                severity: 'error'
            });
        }
    };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') return;
        setSnackbar({ ...snackbar, open: false });
    };

    const handleSelectionModelChange = (newSelectionModel) => {
        setSelectedRows(newSelectionModel);
    };

    const columns = [
        {
            field: 'image',
            headerName: '',
            flex: 0.4,
            minWidth: 80,
            renderCell: (params) => {
                const imageUrl = params.row.space_images?.[0]?.image_url;

                return (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-start',
                            width: '100%',
                            height: '100%',
                            overflow: 'hidden'
                        }}
                    >
                        {imageUrl ? (
                            <Box
                                component="img"
                                sx={{
                                    width: '60px',
                                    height: '45px',
                                    borderRadius: '4px',
                                    cursor: 'pointer',
                                    objectFit: 'cover',
                                }}
                                alt="Space Image"
                                src={imageUrl}
                                onError={(e) => {
                                    e.target.style.display = 'none';
                                    e.target.parentElement.appendChild(SpacePlaceholder());
                                }}
                            />
                        ) : (
                            <SpacePlaceholder />
                        )}
                    </Box>
                );
            }
        },
        {
            field: 'name',
            headerName: 'Name',
            flex: 1,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                    <Typography variant="body2">{params.value}</Typography>
                </Box>
            )
        },
        {
            field: 'description',
            headerName: 'Description',
            flex: 1.5,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                    <Typography variant="body2" noWrap>{params.value}</Typography>
                </Box>
            )
        },
        {
            field: 'pax',
            headerName: 'Capacity',
            flex: 0.5,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                    <Typography variant="body2">{`${params.row.minimum_pax || 0} - ${params.row.maximum_pax || 0}`}</Typography>
                </Box>
            )
        },
        {
            field: 'actions',
            headerName: '',
            flex: 0.7,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100%', height: '100%' }}>
                    <IconButton
                        aria-label="view"
                        onClick={() => {
                            setSelectedSpace(params.row);
                            setViewDialogOpen(true);
                        }}
                    >
                        <ViewIcon />
                    </IconButton>
                    <IconButton
                        aria-label="edit"
                        onClick={() => handleEditClick(params.row)}
                    >
                        <EditIcon />
                    </IconButton>
                    <IconButton
                        aria-label="delete"
                        onClick={() => handleDeleteClick(params.row.id)}
                    >
                        <DeleteIcon />
                    </IconButton>
                </Box>
            ),
        },
    ];

    return (
        <Box>
            <Box flex={1}>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', alignSelf: 'stretch', width: '100%' }}>
                    <Box>
                        <Typography variant='h4'>Booking Setup</Typography>
                        <Typography variant='h5'>View, purchase, and manage all your booking in one place.</Typography>
                    </Box>
                    <Box sx={{ ml: 'auto', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Button
                            component={Link}
                            to="/settings/booking-setup/add-booking"
                            variant="save"
                            startIcon={<AddIcon />}
                        >
                            Add New Booking
                        </Button>
                    </Box>
                </Box>

                <Divider />

                <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                    <Box />
                    <Box display="flex" gap="12px">
                        <TextField
                            variant="outlined"
                            placeholder="Search by Name"
                            value={searchQuery}
                            onChange={handleSearchChange}
                            size="small"
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    height: '34px',
                                    '& input': {
                                        height: '34px',
                                        padding: '0 14px',
                                    }
                                }
                            }}
                        />
                    </Box>
                </Box>

                <Paper
                    sx={{
                        width: "100%",
                        borderRadius: "16px",
                        marginTop: '24px',
                        border: "1px solid var(--Gray-200, #EAECF0)",
                        background: "var(--White, #FFF)",
                        boxShadow: "0px 1px 3px 0px rgba(16, 24, 40, 0.10), 0px 1px 2px 0px rgba(16, 24, 40, 0.06)",
                    }}
                >
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '20px 24px' }}>
                        <Typography
                            sx={{
                                color: "var(--Gray-900, #101828)",
                                fontFamily: "Inter",
                                fontSize: "18px",
                                fontStyle: "normal",
                                fontWeight: 500,
                                lineHeight: "28px",
                            }}
                        >
                            All Bookings
                        </Typography>
                    </Box>

                    <Divider sx={{my:0}}/>

                    {isLoading || isFetching ? (
                        renderSkeletons()
                    ) : error ? (
                        <Typography color="error" sx={{ p: 2 }}>
                            {error?.data?.message || 'Error loading spaces'}
                        </Typography>
                    ) : (
                        <DataGrid
                            rows={data?.data?.spaces || []}
                            columns={columns}
                            paginationModel={paginationModel}
                            onPaginationModelChange={handlePaginationModelChange}
                            rowCount={data?.data?.pagination?.totalCount || 0}
                            paginationMode="server"
                            checkboxSelection
                            onRowSelectionModelChange={handleSelectionModelChange}
                            slots={{ toolbar: GridToolbar }}
                        />
                    )}
                </Paper>
            </Box>

            <ViewBookingDialog
                open={viewDialogOpen}
                onClose={() => {
                    setViewDialogOpen(false);
                    setSelectedSpace(null);
                }}
                spaceData={selectedSpace}
            />

            <Dialog
                open={confirmDialog.open}
                onClose={() => setConfirmDialog({ ...confirmDialog, open: false })}
            >
                <DialogTitle>Confirm Deletion</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this space? This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setConfirmDialog({ ...confirmDialog, open: false })} variant="cancel">
                        Cancel
                    </Button>
                    <Button onClick={handleConfirmDelete} variant="save" autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>

            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default BookingSetupList;