import {alpha, Theme} from '@mui/material/styles';

import { experimental_extendTheme as extendTheme } from '@mui/material/styles';

import { shadows, typography, components, colorSchemes, customShadows } from './core';
// ----------------------------------------------------------------------

// Color palette definition
const colors = {
  primary: {
    main: '#ffeb3b',
    light: '#fff176',
    dark: '#fbc02d',    // Darker yellow for better visibility
    darker: '#f57f17',  // Even darker shade for icons
    lighter: '#fff9c4'
  },
  secondary: {
    main: '#8E33FF',
    light: '#B066FF',
    dark: '#6B24CC',
    lighter: '#EBD6FE',
    darker: '#48238C'
  },
  warning: {
    main: '#FFAB00',
    light: '#FFB547',
    dark: '#B76E00',
    lighter: '#FFF5CC',
    darker: '#7A4100'
  },
  error: {
    main: '#FF5630',
    light: '#FF8F73',
    dark: '#B71D18',
    lighter: '#FFE9D5',
    darker: '#7A0916'
  },
  dark: {
    50: '#71717A',
    500: '#09090B'
  },
  gray: {
    100: '#F2F4F7',
    200: '#EAECF0',
    300: '#D0D5DD',
    500: '#6D6D6D',
    700: '#344054',
    900: '#101828'
  },
  white: '#FFF',
  yellow: {
    main: '#FFED00',
    hover: '#FFC000',
    border: '#FFD600'
  },
  green: '#008c10',
  stepper: {
    incomplete: '#E0E0E0',
    active: '#FFB800',
    completed: 'orange'
  }
};


const components2 = () => ({
  MuiTypography: {
    styleOverrides: {
      h4: {
        fontSize: '1.3rem',
      },
      h5: {
        fontSize: '1rem',
        fontWeight: '400',
        color: '#888',
      }
    }
  },
  MuiCssBaseline: {
    styleOverrides: {
      html: {
        backgroundColor: `var(--palette-background-default)`,
        height: '100%'
      },
      body: {
        backgroundColor: `var(--palette-background-default)`,
        height: '100%'
      }
    }
  },
  MuiAppBar: {
    styleOverrides: {
      root: {
        backgroundColor: colors.primary.main,
        color: colors.dark[500],
        boxShadow: '0px 4px 8px -2px rgba(16, 24, 40, 0.2), 0px 2px 4px -2px rgba(16, 24, 40, 0.15)'
      }
    }
  },
  MuiTabs: {
    styleOverrides: {
      root: {
        textTransform: 'capitalize'
      },
      indicator: {
        height: '2px',
        backgroundColor: colors.dark[500],
      },
    },
  },
  MuiTab: {
    styleOverrides: {
      root: {
        fontFamily: 'Inter',
        fontSize: '14px',
        fontStyle: 'normal',
        marginRight: '16px',
        padding: '0 4px 16px 4px',
        fontWeight: 400,
        lineHeight: '20px',
        color: colors.dark[50],
        '&.Mui-selected': {
          color: colors.dark[500],
          fontWeight: 600,
        },
      },
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      root: {
        color: colors.gray[300],
        fontFamily: 'Inter',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '24px',
        transform: 'translate(15px, 10px) scale(1)',
        '&.MuiInputLabel-shrink': {
          transform: 'translate(20px, -7px) scale(0.60)',
          color: colors.gray[300],
        },
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        borderRadius: '8px',
        backgroundColor: colors.white,
        boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
        '&:hover .MuiOutlinedInput-notchedOutline': {
          borderColor: colors.gray[300],
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderColor: colors.gray[300],
        },
      },
      input: {
        borderRadius: '8px',
        backgroundColor: colors.white,
        padding: '10px',
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      root: {
        borderRadius: '8px',
        boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
        padding: '8px 16px',
        fontFamily: 'Inter',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '20px',
        '&:hover': {
          boxShadow: '0px 2px 4px 0px rgba(16, 24, 40, 0.1)',
        },
      },
    },
    variants: [
      {
        props: { variant: 'cancel' },
        style: {
          border: `1px solid ${colors.gray[300]}`,
          backgroundColor: colors.white,
          height: '34px',
          color: colors.gray[700],
          textTransform: 'none',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          '&:hover': {
            backgroundColor: colors.white,
          },
        },
      },
      {
        props: { variant: 'save' },
        style: {
          border: `1px solid ${colors.yellow.border}`,
          backgroundColor: colors.yellow.main,
          height: '34px',
          color: colors.dark[500],
          textTransform: 'none',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          '&:hover': {
            backgroundColor: colors.yellow.hover,
          },
        },
      },
    ],
  },
  MuiDataGrid: {
    styleOverrides: {
      root: {
        width: '100%',
        backgroundColor: 'white',
        border: 'none',
        height: 'auto',
        position: 'relative',
        '& .MuiDataGrid-root': {
          width: '100%',
          maxWidth: '100%',
          overflow: 'hidden',
        },
        '& .MuiDataGrid-virtualScroller': {
          overflow: 'auto',
        },
        '& .MuiDataGrid-footerContainer': {
          width: '100%',
          borderTop: '1px solid #eaecf0',
          backgroundColor: '#fff',
        },
        '& .MuiDataGrid-main': {
          padding: '0',
          width: '100%',
          overflow: 'auto',
          display: 'flex',
          flexDirection: 'column',
        },
        '& .MuiDataGrid-columnHeaders': {
          backgroundColor: '#f9fafb',
          borderBottom: '1px solid #eaecf0',
          width: 'auto',
          minWidth: '100%',
        },
        '& .MuiDataGrid-cell': {
          py: 1,  // Add some vertical padding
        },
        '& .MuiDataGrid-row': {
          maxHeight: 'none !important',
        },
        '& .MuiDataGrid-renderingZone': {
          maxHeight: 'none !important',
        },
        // '& .MuiDataGrid-virtualScroller': {
        //   // Remove the virtual scroller height limitation
        //   maxHeight: 'none !important',
        // },
        // '& .MuiDataGrid-cell': {
        //   py: 1,
        //   whiteSpace: 'nowrap',
        //   overflow: 'hidden',
        //   textOverflow: 'ellipsis',
        //   borderBottom: 'none',
        //   padding: '12px 16px',
        //   '&:focus': {
        //     outline: 'none',
        //   },
        // },
        // '& .MuiDataGrid-row': {
        //   width: 'auto',
        //   minWidth: '100%',
        //   '&:hover': {
        //     backgroundColor: '#f9fafb',
        //   },
        // },
        '& .MuiDataGrid-toolbarContainer': {
          padding: '12px 0',
          '& .MuiButtonBase-root': {
            color: 'black',
            height: '34px',
            margin: '4px 2px',
            minWidth: 'auto',
            border: 'none',
            boxShadow: 'none',
          }
        },
        transition: 'width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
      },
      columnHeaders: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
      },
      columnHeaderTitle: {
        fontFamily: 'Inter',
        fontSize: '12px',
        fontWeight: 500,
        lineHeight: '18px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        width: '100%',
        textAlign: 'left',
      },
    },
    defaultProps: {
      slots: { toolbar: 'GridToolbar' },
      pageSizeOptions: [5, 10, 20],
      paginationMode: "server",
      checkboxSelection: true,
      disableRowSelectionOnClick: true,
      rowHeight: 70,
      autoHeight: true,
      initialState: {
        pagination: {
          paginationModel: {
            pageSize: 10,
          },
        },
      },
    },
  },
  MuiCheckbox: {
    styleOverrides: {
      root: {
        '& .MuiSvgIcon-root': {
          borderRadius: '5px',
          fontSize: 20,
          border: `1px solid ${colors.gray[300]}`,
          boxShadow: 'none',
        },
        '&.Mui-checked .MuiSvgIcon-root': {
          borderRadius: '8px',
          backgroundColor: colors.white,
          boxShadow: 'none',
          overflow: 'hidden',
          border: 'none',
          fontSize: 22,
        },
        '& .MuiSvgIcon-root path': {
          fill: 'none',
          stroke: 'none',
        },
        '&.Mui-checked .MuiSvgIcon-root path': {
          fill: '#FFB800',
        },
      },
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        border: `1px solid ${colors.gray[100]}`,
        padding: 6,
        margin: 0,
      },
    },
  },
  MuiDivider: {
    styleOverrides: {
      root: {
        height: '1px',
        alignSelf: 'stretch',
        backgroundColor: colors.gray[200],
        margin: '21px 0',
      },
    },
  },
  MuiSwitch: {
    styleOverrides: {
      switchBase: {
        '&.Mui-checked': {
          color: '#fafafa',
          '&:hover': {
            backgroundColor: alpha('#4caf50', 0.12),
          },
        },
        '&.Mui-checked + .MuiSwitch-track': {
          backgroundColor: colors.green,
        },
      },
    },
  },
  MuiStepIcon: {
    styleOverrides: {
      root: {
        color: colors.stepper.incomplete,
        '&.Mui-completed': {
          color: colors.stepper.completed,
        },
        '&.Mui-active': {
          color: colors.stepper.active,
        },
      },
      text: {
        fill: '#000000',
        fontWeight: 'bold',
      },
    },
  },

  MuiCard: {
    styleOverrides: {
      root: {
        boxShadow: 'none',
        border: 'none',
        display: 'flex',
        borderRadius: '16px',
        backgroundColor: colors.white,
        height: '100%',
        cursor: 'pointer',
        overflow: 'visible',
      }
    }
  },
  MuiCardContent: {
    styleOverrides: {
      root: {
        padding: '24px',
        display: 'flex',
        gap: '16px',
        width: '100%',
        height: '130px',
        transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1), all 0.2s ease-in-out',
        backgroundColor: colors.white,
        zIndex: 0,
        position: 'relative',
        boxShadow: 'rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px',
        borderRadius: '16px',
        alignItems: 'center',
        overflow: 'hidden',
        '& .MuiCardHeader-root': {
          padding: '24px 24px 0',
          '& .MuiTypography-root': {
            '&.MuiTypography-h6': {
              variant: 'h6'
            },
            '&.MuiTypography-body2': {
              variant: 'body2'
            }
          }
        },
        '&:hover': {
          transform: 'translateY(-2px)',
          boxShadow: '0px 16px 32px -4px rgba(16, 24, 40, 0.08), 0px 8px 16px -4px rgba(16, 24, 40, 0.03)',
          backgroundColor: '#fafafa',
        },
        '&:active': {
          transform: 'translateY(0px)',
          boxShadow: '0px 6px 12px -4px rgba(16, 24, 40, 0.06)',
        },
        '&:last-child': {
          paddingBottom: '24px'
        }
      }
    }
  }
});

export function createTheme(): Theme {
  const initialTheme = {
    colorSchemes,
    shadows: shadows(),
    customShadows: customShadows(),
    shape: { borderRadius: 8 },
    typography,
    cssVarPrefix: '',
    shouldSkipGeneratingVar,
    components: components2()
  };

  const theme = extendTheme(initialTheme);

  return theme;
}

// ----------------------------------------------------------------------

function shouldSkipGeneratingVar(keys: string[], value: string | number): boolean {
  const skipGlobalKeys = [
    'mixins',
    'overlays',
    'direction',
    'typography',
    'breakpoints',
    'transitions',
    'cssVarPrefix',
    'unstable_sxConfig',
  ];

  const skipPaletteKeys: {
    [key: string]: string[];
  } = {
    global: ['tonalOffset', 'dividerChannel', 'contrastThreshold'],
    grey: ['A100', 'A200', 'A400', 'A700'],
    text: ['icon'],
  };

  const isPaletteKey = keys[0] === 'palette';

  if (isPaletteKey) {
    const paletteType = keys[1];
    const skipKeys = skipPaletteKeys[paletteType] || skipPaletteKeys.global;

    return keys.some((key) => skipKeys?.includes(key));
  }

  return keys.some((key) => skipGlobalKeys?.includes(key));
}
