import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {createPackage, fetchPackages, fetchPackagesByEventId} from '../../../../redux/packagesSlice';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Box,
    Typography,
    TextField,
    Select,
    MenuItem,
    Divider, Snackbar, Alert,
} from '@mui/material';
import { styled } from "@mui/system";
import AddNewPackagesTimeSlots from "./AddNewPackagesTimeSlots";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const TextFieldTitle = styled(Typography)(({ theme }) => ({
    color: 'var(--Gray-700, #344054)',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '20px',
    width: '100%',
    display: 'flex',
    marginTop: 4,
    [theme.breakpoints.up('md')]: {
        width: '312px',
    }
}));

const RequiredIndicator = styled('span')(({ theme }) => ({
    color: 'red',
    marginLeft: '4px',
}));

const AddNewPackageDialogForm = ({ eventId, open, handleClose, onPackageCreated }) => {
    const packageStatus = useSelector((state) => state.packages.status);
    const packageError = useSelector((state) => state.packages.error);
    const [packageData, setPackageData] = useState({
        event_id: eventId,
        name: '',
        description: '',
        amount: '',
        promotion_amount: '',
        currency_id: 1,
        organization_id: 1,
    });
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (packageStatus === 'succeeded') {
            handleClose();
            setSnackbar({ open: true, message: 'Package created successfully', severity: 'success' });
        } else if (packageStatus === 'failed') {
            setSnackbar({ open: true, message: packageError || 'Failed to create package', severity: 'error' });
        }
    }, [packageStatus, packageError]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setPackageData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleDialogClose = (success = false) => {
        setOpen(false);
        if (success) {
            setSnackbar({ open: true, message: 'Package added successfully', severity: 'success' });
        }
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') return;
        setSnackbar({ ...snackbar, open: false });
    };

    const handleSave = async () => {
        setLoading(true);
        try {
            const response = await axios.post(`${API_BASE_URL}/packages`, packageData);
            console.log('Package created successfully:', response.data);
            handleClose();
            if (typeof onPackageCreated === 'function') {
                onPackageCreated();
            }
            setSnackbar({ open: true, message: response.data.description || 'Package created successfully', severity: 'success' });
            // Clear form input data
            setPackageData({
                event_id: eventId,
                name: '',
                description: '',
                amount: '',
                promotion_amount: '',
                currency_id: 1,
                organization_id: 1,
            });
        } catch (error) {
            console.error('Failed to create package:', error);
            setSnackbar({ open: true, message: error.response?.data?.message || 'Failed to create package', severity: 'error' });
        } finally {
            setLoading(false);
        }
    };

    return (
        <><Dialog
            open={open}
            onClose={handleClose}
            maxWidth="md"
            fullWidth
        >
            <DialogTitle>Add New Package</DialogTitle>
            <DialogContent>
                <Box component="form" noValidate autoComplete="off">
                    {/* Package Name */}
                    <Box display="flex" flexDirection={{xs: 'column', md: 'row'}} sx={{marginBottom: '16px'}}>
                        <TextFieldTitle>
                            Package Name <RequiredIndicator>*</RequiredIndicator>
                        </TextFieldTitle>
                        <TextField
                            fullWidth
                            label="Enter package name"
                            placeholder="Package name"
                            name="name"
                            value={packageData.name}
                            onChange={handleInputChange}
                            required
                            inputProps={{maxLength: 255}}/>
                    </Box>

                    <Divider/>

                    {/* Description */}
                    <Box display="flex" flexDirection={{xs: 'column', md: 'row'}} sx={{marginBottom: '16px'}}>
                        <TextFieldTitle>
                            Description
                        </TextFieldTitle>
                        <TextField
                            fullWidth
                            label="Enter package description"
                            multiline
                            rows={7}
                            name="description"
                            value={packageData.description}
                            onChange={handleInputChange}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    padding: '15px',
                                    '& .MuiOutlinedInput-input': {
                                        padding: 0,
                                    },
                                },
                            }}/>
                    </Box>

                    <Divider/>

                    {/* Amount */}
                    <Box display="flex" flexDirection={{xs: 'column', md: 'row'}} sx={{marginBottom: '16px'}}>
                        <TextFieldTitle>
                            Amount <RequiredIndicator>*</RequiredIndicator>
                        </TextFieldTitle>
                        <TextField
                            fullWidth
                            label="Enter amount"
                            placeholder="0.00"
                            name="amount"
                            type="number"
                            value={packageData.amount}
                            onChange={handleInputChange}
                            required
                            inputProps={{min: 0}}/>
                    </Box>

                    <Divider/>

                    {/* Promotion Amount */}
                    <Box display="flex" flexDirection={{xs: 'column', md: 'row'}} sx={{marginBottom: '16px'}}>
                        <TextFieldTitle>
                            Promotion Amount
                        </TextFieldTitle>
                        <TextField
                            fullWidth
                            label="Enter promotion amount"
                            placeholder="0.00"
                            name="promotion_amount"
                            type="number"
                            value={packageData.promotion_amount}
                            onChange={handleInputChange}
                            inputProps={{min: 0}}/>
                    </Box>

                    <Divider/>

                    {/*/!* Currency *!/*/}
                    {/*<Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} sx={{ marginBottom: '16px' }}>*/}
                    {/*    <TextFieldTitle>*/}
                    {/*        Currency <RequiredIndicator>*</RequiredIndicator>*/}
                    {/*    </TextFieldTitle>*/}
                    {/*    <Select*/}
                    {/*        fullWidth*/}
                    {/*        name="currency_id"*/}
                    {/*        value={packageData.currency_id}*/}
                    {/*        onChange={handleInputChange}*/}
                    {/*        required*/}
                    {/*        displayEmpty*/}
                    {/*    >*/}
                    {/*        <MenuItem value="" disabled>Select currency</MenuItem>*/}
                    {/*        <MenuItem value={1}>USD</MenuItem>*/}
                    {/*        <MenuItem value={2}>MYR</MenuItem>*/}
                    {/*        /!* Add more currency options as needed *!/*/}
                    {/*    </Select>*/}
                    {/*</Box>*/}

                    {/*<Divider />*/}
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} variant="cancel" disabled={loading}>
                    Cancel
                </Button>
                <Button onClick={handleSave} variant="save" disabled={loading}>
                    {loading ? (
                        <>
                            <CircularProgress size={24} color="inherit" sx={{ mr: 1 }} />
                            Saving...
                        </>
                    ) : (
                        'Save'
                    )}
                </Button>
            </DialogActions>
        </Dialog><Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleSnackbarClose}>
            <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{width: '100%'}}>
                {snackbar.message}
            </Alert>
        </Snackbar></>
    );
};

export default AddNewPackageDialogForm;