import { useState } from 'react';
import {
    Box,
    Button,
    Typography,

    Dialog,
    DialogTitle,
    DialogContent,
    Divider,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CreateCoupon from './CreateCoupon';
import CouponsList from "./CouponList";

const Coupon = () => {
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    return (
        <Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', alignSelf: 'stretch', width: '100%'}}>
                <Box>
                    <Typography variant='h4'>
                        Coupons
                    </Typography >
                    <Typography variant='h5'>
                        Create and manage discount coupons for your products.
                    </Typography>
                </Box>
                <Box sx={{ ml: 'auto', display: 'flex', gap: '12px' }}>
                    <Button variant="cancel" onClick={handleClickOpen} startIcon={<AddIcon />}>
                        Create coupon
                    </Button>
                </Box>
            </Box>

            <Divider/>

            <Box>
                <CouponsList handleClickOpen={handleClickOpen}/>
            </Box>

            <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
                <Box sx={{padding:'15px 10px'}}>
                    <DialogTitle>
                        <Typography variant='h4'>
                            Create a coupon
                        </Typography>
                        <Typography variant="body1" color="textSecondary" fontSize='15px' sx={{ mb: 1 }}>
                            Coupons can be used to discount invoices, subscriptions, or entire customer accounts.
                        </Typography>
                    </DialogTitle>
                    <DialogContent>
                        <CreateCoupon onCancel={handleClose}/>
                    </DialogContent>
                </Box>
            </Dialog>
        </Box>
    );
};

export default Coupon;
