import React, { useState } from "react";
import { Box, Typography, TextField, Paper, Divider, IconButton } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Delete as DeleteIcon, Edit as EditIcon } from "@mui/icons-material";
import { ViewIcon } from "../../shared/IconButtons";

const RecentActivity = () => {
    const [searchQuery, setSearchQuery] = useState('');

    const rows = [
        { id: 1, activity: 'Logged in', user: 'John Doe', time: '10:30 AM', date: '2024-08-01' },
        { id: 2, activity: 'Updated profile', user: 'Jane Smith', time: '11:00 AM', date: '2024-08-02' },
        { id: 3, activity: 'Failed login attempt', user: 'Michael Harris', time: '12:15 PM', date: '2024-08-03' },
        { id: 4, activity: 'Viewed reports', user: 'Anna Clark', time: '1:30 PM', date: '2024-08-04' },
        { id: 5, activity: 'Changed password', user: 'William Davis', time: '2:45 PM', date: '2024-08-05' },
        { id: 6, activity: 'Account suspension lifted', user: 'Linda Brown', time: '3:00 PM', date: '2024-08-06' },
        { id: 7, activity: 'Downloaded transaction history', user: 'James Taylor', time: '4:10 PM', date: '2024-08-07' },
        { id: 8, activity: 'Logged out', user: 'Patricia Walker', time: '5:20 PM', date: '2024-08-08' },
        { id: 9, activity: 'Suspended account', user: 'Daniel White', time: '6:35 PM', date: '2024-08-09' },
        { id: 10, activity: 'Added new payment method', user: 'Nancy Moore', time: '7:50 PM', date: '2024-08-10' },
    ];

    const filteredRows = rows.filter((row) =>
        row.activity.toLowerCase().includes(searchQuery.toLowerCase()) ||
        row.user.toLowerCase().includes(searchQuery.toLowerCase()) ||
        row.time.toLowerCase().includes(searchQuery.toLowerCase()) ||
        row.date.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const columns = [
        { field: 'activity', headerName: 'Activity', flex: 1 },
        { field: 'user', headerName: 'User', flex: 1 },
        { field: 'time', headerName: 'Time', flex: 0.5 },
        { field: 'date', headerName: 'Date', flex: 0.5 },
        {
            field: 'actions',
            headerName: '',
            flex: 0.5,
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', height: '100%', width: '100%' }}>
                    <IconButton aria-label="view" onClick={() => console.log('view', params.row)}>
                        <ViewIcon />
                    </IconButton>
                    <IconButton aria-label="edit" onClick={() => console.log('edit', params.row)}>
                        <EditIcon />
                    </IconButton>
                    <IconButton aria-label="delete" onClick={() => console.log('delete', params.row.id)}>
                        <DeleteIcon />
                    </IconButton>
                </Box>
            )
        }
    ];

    return (
        <Box>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                <Typography variant="h1">Recent Activity</Typography>
                <TextField
                    variant="outlined"
                    placeholder="Search..."
                    value={searchQuery}
                    onChange={handleSearchChange}
                    size="small"
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            height: '34px',
                            '& input': {
                                height: '34px',
                                padding: '0 14px',
                            },
                            '& .MuiInputBase-input': {
                                height: '34px',
                                padding: '0 14px',
                            }
                        }
                    }}
                />
            </Box>

            <Divider/>

            <Paper
                sx={{
                    width: "100%",
                    borderRadius: "16px",
                    marginTop: '24px',
                    border: "1px solid var(--Gray-200, #EAECF0)",
                    background: "var(--White, #FFF)",
                    boxShadow: "0px 1px 3px 0px rgba(16, 24, 40, 0.10), 0px 1px 2px 0px rgba(16, 24, 40, 0.06)",
                }}
            >
                <Typography
                    sx={{
                        color: "var(--Gray-900, #101828)",
                        fontFamily: "Inter",
                        fontSize: "18px",
                        fontStyle: "normal",
                        fontWeight: 500,
                        lineHeight: "28px",
                        padding: "20px 24px",
                    }}
                >
                    Recent Activity List
                </Typography>
                <DataGrid
                    slots={{ toolbar: GridToolbar }}
                    rows={filteredRows}
                    columns={columns}
                    checkboxSelection
                    disableSelectionOnClick
                    rowHeight={70}
                    pageSizeOptions={[5, 10, 20]}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 6,
                            },
                        },
                    }}
                    sx={{
                        '& .MuiDataGrid-toolbarContainer .MuiButtonBase-root': {
                            backgroundColor: '#737373',
                            color: '#fff',
                            height: '34px',
                            my: 1,
                            minWidth: 'auto',
                            '&:hover': {
                                backgroundColor: '#404040',
                            },
                        },
                    }}
                />
            </Paper>
        </Box>
    );
};

export default RecentActivity;
