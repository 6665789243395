import React, { useState } from 'react';
import { Box, Typography, TextField, Button, Paper, Container, CircularProgress, useMediaQuery } from '@mui/material';
import {CompanyLogo} from "../header/HeaderSvgImages";
import {ThemeProvider} from "../../theme/theme-provider";
import { createTheme } from "../../theme/create-theme";


const EmailVerificationPage = () => {
    const theme = createTheme();
    const [code, setCode] = useState(['', '', '', '', '', '']);
    const [isLoading, setIsLoading] = useState(false);
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const handleChange = (index, value) => {
        const newCode = [...code];
        newCode[index] = value;
        setCode(newCode);

        // Move focus to the next input
        if (value && index < 5) {
            document.getElementById(`code-${index + 1}`).focus();
        }
    };

    const handleVerify = () => {
        setIsLoading(true);
        // Simulating verification process
        setTimeout(() => setIsLoading(false), 2000);
    };

    return (
        <ThemeProvider theme={theme}>
            <Box
                sx={{
                    minHeight: '80vh',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Container maxWidth="sm">
                    <Paper elevation={3} sx={{ p: { xs: 2, sm: 4 }, backgroundColor: '#ffeb3b' }}>
                        <Box sx={{display:'flex', justifyContent:'center', alignItems:'center', mt:1, mb:{ xs: 3, sm: 6 }}}>
                            <CompanyLogo />
                        </Box>
                        <Typography sx={{fontSize: { xs: '20px', sm: '24px' }, fontWeight:'700', textAlign:'center'}}>
                            Please check your email
                        </Typography>
                        <Typography variant="body2" gutterBottom align="center" color="text.secondary" sx={{ fontSize: { xs: '14px', sm: '16px' } }}>
                            We've sent a code to hello@gmail.com
                        </Typography>
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center', my: { xs: 2, sm: 4 } }}>
                            {code.map((digit, index) => (
                                <React.Fragment key={index}>
                                    <TextField
                                        id={`code-${index}`}
                                        value={digit}
                                        onChange={(e) => handleChange(index, e.target.value)}
                                        variant="outlined"
                                        inputProps={{
                                            maxLength: 1,
                                            sx: { textAlign: 'center', backgroundColor: 'white' },
                                        }}
                                        sx={{
                                            width: { xs: '35px', sm: '40px' },
                                            m: { xs: 0.5, sm: 1 }
                                        }}
                                    />
                                    {index < code.length - 1 && !isMobile && (
                                        <Typography sx={{fontSize:'24px', fontWeight:'200', textAlign:'center', mx:1}}>-</Typography>
                                    )}
                                </React.Fragment>
                            ))}
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                            <Button
                                variant="cancel"
                                color="primary"
                                onClick={handleVerify}
                                disabled={isLoading}
                                sx={{
                                    width: { xs: '120px', sm: '150px' },
                                    borderRadius: '12px',
                                    mt: 2,
                                    mb: 2,
                                    fontSize: { xs: '14px', sm: '16px' }
                                }}
                            >
                                {isLoading ? <CircularProgress size={24} /> : 'Verify'}
                            </Button>
                        </Box>
                    </Paper>
                </Container>
            </Box>
        </ThemeProvider>
    );
};

export default EmailVerificationPage;