// eventlah-react-merchant/src/components/settings/product-settings/ProductCatalog/AddProduct/ProductVariant/ProductVariant.jsx

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {createProductVariant, fetchProductVariants} from '../../../../../../redux/productSlice';
import axios from 'axios';
import {
    Box,
    Snackbar,
    Alert, Skeleton,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import VariantForm from './VariantForm';
import VariantPreview from './VariantPreview';
import { getToken } from '../../../../../../utils/authUtils';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const SkeletonLoader = () => (
    <Box sx={{ width: '100%' }}>
        <Skeleton variant="rectangular" width="100%" height={60} sx={{ mb: 2 }} />
        <Skeleton variant="rectangular" width="100%" height={400} />
    </Box>
);

const ProductVariant = ({ productId }) => {
    const [variants, setVariants] = useState([]);
    const [loading, setLoading] = useState(false);
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success'
    });
    const [selectedVariantIndexes, setSelectedVariantIndexes] = useState({});
    const [quantity, setQuantity] = useState(1);

    const { control, handleSubmit, setValue, watch, formState: { errors } } = useForm({
        defaultValues: {
            variants: []
        }
    });

    const fetchVariants = async () => {
        if (productId) {
            try {
                setLoading(true);
                const token = await getToken();
                const response = await axios.get(`${API_BASE_URL}/product-variants?productId=${productId}`, {
                    headers: { Authorization: `Bearer ${token}` }
                });
                setVariants(response.data);
                setValue('variants', response.data);
            } catch (err) {
                console.error("Error fetching product variants:", err);
                setSnackbar({
                    open: true,
                    message: 'Error fetching variants. Please try again.',
                    severity: 'error'
                });
            } finally {
                setLoading(false);
            }
        }
    };

    // Fetch product data (you might need to implement this)
    const product = useSelector(state => state.products.currentProduct);

    useEffect(() => {
        fetchVariants();
    }, [productId]); // This will call fetchVariants when the component mounts and when productId changes


    useEffect(() => {
        if (variants.data && variants.data.length > 0) {
            const formattedVariants = variants.data.reduce((acc, variant) => {
                const existingVariant = acc.find(v => v.optionName === variant.variant_name);
                if (existingVariant) {
                    existingVariant.options.push({
                        id: variant.id,
                        optionValue: variant.variant_value,
                        price: variant.price,
                        stock: variant.stock,
                        image: variant.variant_image
                    });
                } else {
                    acc.push({
                        id: Date.now() + Math.random(),
                        optionName: variant.variant_name,
                        options: [{
                            id: variant.id,
                            optionValue: variant.variant_value,
                            price: variant.price,
                            stock: variant.stock,
                            image: variant.variant_image
                        }]
                    });
                }
                return acc;
            }, []);

            console.log('Formatted variants:', formattedVariants);
            setValue('variants', formattedVariants);
        }
    }, [variants, setValue]);

    const onSubmit = async (data) => {
        if (!productId) {
            setSnackbar({
                open: true,
                message: 'Please save the product details first before adding variants.',
                severity: 'warning'
            });
            return;
        }

        setLoading(true);

        try {
            const token = await getToken();
            const variantsToSave = data.variants.flatMap(variant =>
                variant.options.map(option => ({
                    product_id: productId,
                    variant_name: variant.optionName,
                    variant_value: option.optionValue,
                    price: option.price || "0.00",
                    stock: parseInt(option.stock) || 0,
                    variant_image: option.image || null
                }))
            );

            await axios.post(`${API_BASE_URL}/product-variants?productId=${productId}`, variantsToSave, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            });

            setSnackbar({
                open: true,
                message: 'Variants saved successfully',
                severity: 'success'
            });

            // Refresh the variants
            await fetchVariants();
        } catch (error) {
            console.error("Error saving variants:", error);
            setSnackbar({
                open: true,
                message: 'Error saving variants. Please try again.',
                severity: 'error'
            });
        } finally {
            setLoading(false);
        }
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbar({ ...snackbar, open: false });
    };

    const getAuthHeaders = async () => {
        const token = await getToken();
        return {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
        };
    };

    console.log('VariantForm props:', { control, errors, loading });
    console.log('onSubmit:', handleSubmit(onSubmit));

    const handleOptionSelect = (variantIndex, optionIndex) => {
        setSelectedVariantIndexes(prev => ({
            ...prev,
            [variantIndex]: optionIndex
        }));
    };

    const handleQuantityChange = (newQuantity) => {
        setQuantity(Math.max(1, newQuantity));
    };

    const calculatePrice = () => {
        let basePrice = product?.price || 0;
        const selectedVariants = watch('variants');
        selectedVariants.forEach((variant, index) => {
            const selectedOptionIndex = selectedVariantIndexes[index] || 0;
            const selectedOption = variant.options[selectedOptionIndex];
            if (selectedOption && selectedOption.price) {
                basePrice += parseFloat(selectedOption.price);
            }
        });
        return basePrice.toFixed(2);
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            {loading ? (
                <SkeletonLoader />
            ) : (
            <Box sx={{ display: 'flex', gap: 2 }}>
                <VariantForm
                    control={control}
                    errors={errors}
                    onSubmit={handleSubmit(onSubmit)}
                    loading={loading}
                    variants={watch('variants')}
                />
                {/*<VariantPreview*/}
                {/*    variants={watch('variants')}*/}
                {/*    productImages={product?.product_images || []}*/}
                {/*    selectedVariantIndexes={selectedVariantIndexes}*/}
                {/*    handleOptionSelect={handleOptionSelect}*/}
                {/*    quantity={quantity}*/}
                {/*    handleQuantityChange={handleQuantityChange}*/}
                {/*    calculatePrice={calculatePrice}*/}
                {/*/>*/}
            </Box>
            )}
            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
            >
                <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default ProductVariant;