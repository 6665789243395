import {
    Dialog,
    DialogContent,
    IconButton,
    Typography,
    Box,
    Grid,
    Chip,
    Divider,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PersonIcon from '@mui/icons-material/Person';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import InfoIcon from '@mui/icons-material/Info';
import { CameraAlt as CameraIcon } from '@mui/icons-material';

const SpacePlaceholder = () => (
    <Box
        sx={{
            width: '100%',
            height: '300px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#f5f5f5',
            border: '1px dashed #bdbdbd',
            gap: 1,
        }}
    >
        <CameraIcon sx={{ color: '#bdbdbd', fontSize: '48px' }} />
        <Typography variant="body1" sx={{ color: '#757575' }}>
            No image available
        </Typography>
    </Box>
);

const ViewBookingDialog = ({ open, onClose, spaceData }) => {
    if (!spaceData) return null;

    const styles = {
        dialog: {
            '& .MuiDialog-paper': {
                maxWidth: '800px',
                width: '100%',
                borderRadius: '12px',
                overflow: 'hidden'
            }
        },
        header: {
            bgcolor: '#ffeb3b',
            py: 2,
            px: 3,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
        },
        closeButton: {
            color: '#000',
            '&:hover': {
                bgcolor: 'rgba(0, 0, 0, 0.04)'
            }
        },
        imageSection: {
            width: '100%',
            height: '300px',
            overflow: 'hidden',
            position: 'relative'
        },
        image: {
            width: '100%',
            height: '100%',
            objectFit: 'cover'
        },
        contentSection: {
            p: 3
        },
        infoCard: {
            bgcolor: '#fff',
            p: 2,
            borderRadius: 2,
            border: '1px solid #e0e0e0',
            height: '100%'
        },
        chip: {
            bgcolor: '#ffeb3b',
            color: '#000',
            fontWeight: 600,
            fontSize: '0.875rem'
        },
    };

    const imageUrl = spaceData.space_images?.[0]?.image_url;

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="md"
            fullWidth
            sx={styles.dialog}
        >
            {/* Header */}
            <Box sx={styles.header}>
                <Typography variant="h6" fontWeight="600" color="black">
                    Booking Details
                </Typography>
                <IconButton onClick={onClose} sx={styles.closeButton}>
                    <CloseIcon />
                </IconButton>
            </Box>

            {/* Image Section */}
            <Box sx={styles.imageSection}>
                {imageUrl ? (
                    <img
                        src={imageUrl}
                        alt={spaceData.name}
                        style={styles.image}
                        onError={(e) => {
                            e.target.style.display = 'none';
                            e.target.parentElement.appendChild(SpacePlaceholder());
                        }}
                    />
                ) : (
                    <SpacePlaceholder />
                )}
            </Box>

            {/* Content Section */}
            <DialogContent sx={styles.contentSection}>
                <Grid container spacing={3}>
                    {/* Left Column */}
                    <Grid item xs={12} md={8}>
                        <Typography variant="h5" fontWeight="600" gutterBottom>
                            {spaceData.name}
                        </Typography>

                        <Box sx={{ mb: 3 }}>
                            <Typography variant="body1" color="text.secondary" sx={{ mb: 2 }}>
                                {spaceData.description}
                            </Typography>
                        </Box>

                        <Divider sx={{ my: 2 }} />

                        <Typography variant="h6" gutterBottom>
                            Booking Features
                        </Typography>

                        <Grid container spacing={2}>
                            {['Goal Posts', 'Artificial Turf', 'Scoreboard', 'Floodlights', 'Changing Rooms', 'Seating Area', 'Refreshments', 'First Aid Station', 'Parking', 'Training Equipment']
                                .map((feature) => (
                                    <Grid item key={feature}>
                                        <Chip label={feature} sx={styles.chip} />
                                    </Grid>
                                ))}
                        </Grid>
                    </Grid>

                    {/* Right Column */}
                    <Grid item xs={12} md={4}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Box sx={styles.infoCard}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                                        <PersonIcon sx={{ mr: 1, color: '#ffeb3b' }} />
                                        <Typography variant="subtitle1" fontWeight="600">
                                            Capacity
                                        </Typography>
                                    </Box>
                                    <Typography variant="body2" color="text.secondary">
                                        Min: {spaceData.minimum_pax} pax
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        Max: {spaceData.maximum_pax} pax
                                    </Typography>
                                </Box>
                            </Grid>

                            <Grid item xs={12}>
                                <Box sx={styles.infoCard}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                                        <CalendarMonthIcon sx={{ mr: 1, color: '#ffeb3b' }} />
                                        <Typography variant="subtitle1" fontWeight="600">
                                            Availability
                                        </Typography>
                                    </Box>
                                    <Typography variant="body2" color="text.secondary">
                                        Monday - Friday
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        9:00 AM - 6:00 PM
                                    </Typography>
                                </Box>
                            </Grid>

                            <Grid item xs={12}>
                                <Box sx={styles.infoCard}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                                        <InfoIcon sx={{ mr: 1, color: '#ffeb3b' }} />
                                        <Typography variant="subtitle1" fontWeight="600">
                                            Additional Info
                                        </Typography>
                                    </Box>
                                    <Typography variant="body2" color="text.secondary">
                                        24-hour advance booking required
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        Cancellation policy applies
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default ViewBookingDialog;