import React, { useState, useEffect } from 'react';
import { Box, Tabs, Tab, Divider, Typography} from '@mui/material';
import Verified from "./AdminAccountStatusContents/Verified";
import Warning from "./AdminAccountStatusContents/Warning";
import Incomplete from "./AdminAccountStatusContents/Incomplete";
import Expired from "./AdminAccountStatusContents/Expired";
import Billing from "./AdminAccountStatusContents/Billing";

const AdminAccountStatus = () => {
    const [value, setValue] = useState(0);
    useEffect(() => {
        // Check for hash fragment on component mount
        const hash = window.location.hash;
        if (hash === '#billing') {
            setValue(4);  // Set to Billing tab
        }
    }, []);

    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box>
            <Box flex={1}>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', alignSelf: 'stretch', width: '100%'}}>
                    <Box>
                        <Typography variant='h4'>
                            Account Status
                        </Typography >
                        <Typography variant='h5' sx={{ mb: { xs: 3, md: 5 } }}>
                            Overview of Your Current Account Activity
                        </Typography>
                    </Box>
                </Box>

                <Box height='38px'>
                    <Tabs value={value} onChange={handleTabChange} aria-label="service tabs">
                        <Tab label="Verified" />
                        <Tab label="Warning" />
                        <Tab label="Incomplete" />
                        <Tab label="Expired" />
                        <Tab label="Billing" />
                    </Tabs>
                </Box>

                <Divider sx={{margin: '10px 0 10px 0'}}/>

                <Box>
                    { value === 0 && <Verified/> }
                    { value === 1 && <Warning/> }
                    { value === 2 &&  <Incomplete/> }
                    { value === 3 &&  <Expired/> }
                    { value === 4 &&  <Billing/> }
                </Box>
            </Box>
        </Box>

    );
};

export default AdminAccountStatus;
