import React, {useEffect, useState} from 'react';
import {Box, Tabs, Tab, Divider, Typography, IconButton, Grid} from '@mui/material';
import OrdersList from './OrdersList';
import AnalyticsWidgetSummary from "../../shared/AnalyticsWidgetSummary";
import {
    LocalShipping as LocalShippingIcon,
    PaidOutlined as PaidIcon,
    ShoppingBasket as ShoppingBasketIcon,
    ErrorOutline as ErrorIcon
} from '@mui/icons-material';
import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const OrdersOverview = () => {
    const [value, setValue] = useState(0);
    const [openQRScanner, setOpenQRScanner] = useState(false);

    const [monthlyOrders, setMonthlyOrders] = useState(0);
    const [todayTotalRevenue, setTodayTotalRevenue] = useState(0);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [ordersResponse, revenueResponse] = await Promise.all([
                    axios.get(`${API_BASE_URL}/purchases?status_id=3`),
                    axios.get(`${API_BASE_URL}/purchases`, {
                        params: {
                            today_total_revenue: true,
                            purchase_type: 5,
                            status_id: 3
                        }
                    })
                ]);
                setMonthlyOrders(ordersResponse.data.data.total || 0);
                setTodayTotalRevenue(revenueResponse.data.data.todayTotalRevenue || 0);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, []);

    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleQRIconClick = () => {
        setOpenQRScanner(true);
    };

    const handleCloseQRScanner = () => {
        setOpenQRScanner(false);
    };

    return (
        <Box sx={{ display:'flex', padding:{xs:1,md:'32px'} }}>
            <Box flex={1}>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', alignSelf: 'stretch', width: '100%'}}>
                    <Box>
                        <Typography variant='h4'>Orders Overview</Typography>
                        <Typography variant='h5' sx={{ mb: { xs: 3, md: 5 } }}>All of your product's orders.</Typography>
                    </Box>
                </Box>

                {/* Analytic Graphs */}
                <Grid container spacing={3} sx={{ mb: 3 }}>
                    <Grid item xs={12} sm={6} md={3}>
                        <AnalyticsWidgetSummary
                            title="Monthly Orders"
                            percent={3.2}
                            total={monthlyOrders}
                            icon={<LocalShippingIcon sx={{ width: 32, height: 32, color: '#ffeb3b' }} />}
                            chart={{
                                categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug'],
                                series: [1800, 2400, 800, 2200, 1200, 2600, 1500, 2845],
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} md={3}>
                        <AnalyticsWidgetSummary
                            title="Today's Revenue"
                            percent={4.5}
                            total={todayTotalRevenue}
                            color="secondary"
                            icon={<PaidIcon sx={{ width: 32, height: 32, color: 'secondary.main' }} />}
                            chart={{
                                categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug'],
                                series: [12000, 7000, 13500, 8500, 14800, 10000, 13000, 15420],
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} md={3}>
                        <AnalyticsWidgetSummary
                            title="Average Order Value"
                            percent={1.8}
                            total={542}
                            color="warning"
                            icon={<ShoppingBasketIcon sx={{ width: 32, height: 32, color: 'warning.main' }} />}
                            chart={{
                                categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug'],
                                series: [400, 250, 480, 320, 500, 380, 450, 542],
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} md={3}>
                        <AnalyticsWidgetSummary
                            title="Failed Orders"
                            percent={-0.5}
                            total={124}
                            color="error"
                            icon={<ErrorIcon sx={{ width: 32, height: 32, color: 'error.main' }} />}
                            chart={{
                                categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug'],
                                series: [220, 280, 180, 250, 160, 200, 140, 124],
                            }}
                        />
                    </Grid>
                </Grid>

                <Box height='38px'>
                    <Tabs value={value} onChange={handleTabChange} aria-label="service tabs">
                        <Tab label="Pending" />
                        <Tab label="Processing" />
                        <Tab label="Completed" />
                        <Tab label="Cancelled" />
                        <Tab label="Rejected" />
                    </Tabs>
                </Box>

                <Divider sx={{margin: '10px 0 10px 0'}}/>

                <Box>
                    {value === 0 && <OrdersList status="pending" />}
                    {value === 1 && <OrdersList status="processing" />}
                    {value === 2 && <OrdersList status="completed" />}
                    {value === 3 && <OrdersList status="cancelled" />}
                    {value === 4 && <OrdersList status="rejected" />}
                </Box>

            </Box>
        </Box>
    );
};

export default OrdersOverview;