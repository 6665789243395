import { useState } from 'react';
import {
    Box,
    Button,
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    Divider,
    Snackbar,
    Alert,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import AddShippingRate from './AddShippingRate';
import ShippingRateList from "./ShippingRateList";

const ShippingRate = () => {
    const [open, setOpen] = useState(false);
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success'
    });

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbar({ ...snackbar, open: false });
    };

    return (
        <Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', alignSelf: 'stretch', width: '100%'}}>
                <Box>
                    <Typography variant='h4'>Shipping Rates</Typography>
                    <Typography variant='h5'>Manage delivery costs and options to optimize your global logistics.</Typography>
                </Box>
                <Box sx={{ ml: 'auto', display: 'flex', gap: '12px' }}>
                    <Button variant="cancel" onClick={handleClickOpen} startIcon={<AddIcon />}>
                        Create shipping rate
                    </Button>
                </Box>
            </Box>

            <Divider/>

            <Box>
                <ShippingRateList
                    handleClickOpen={handleClickOpen}
                    onSnackbarChange={setSnackbar}
                />
            </Box>

            <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
                <Box sx={{padding:'15px 10px', backgroundColor:'#f5f5f5'}}>
                    <DialogTitle>
                        <Typography variant='h4' fontSize='25px' fontWeight='700'>
                            Add shipping rate
                        </Typography>
                    </DialogTitle>
                    <DialogContent>
                        <AddShippingRate
                            onCancel={handleClose}
                            onSnackbarChange={(newSnackbar) => setSnackbar(newSnackbar)}
                        />
                    </DialogContent>
                </Box>
            </Dialog>

            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                sx={{ zIndex: (theme) => theme.zIndex.modal + 1 }}
            >
                <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default ShippingRate;