import axios from 'axios';
import keycloak from "../../../keycloak";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
// const API_BASE_URL_2 = process.env.REACT_APP_API_BASE_URL;

export const fetchTicketingOverviewCurrentData = async (paginationModel, searchQuery, organizationId) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/purchase-ticketings`, {
            params: {
                page: paginationModel.page + 1,
                pageSize: paginationModel.pageSize,
                search: searchQuery,
                organization_id: organizationId,
                // purchase_type: 1,
                // status_id: 3,
                timeframe: 'current'
            },
            headers: {
                Authorization: `Bearer ${keycloak.token}`
            }
        });

        return response.data;
    } catch (error) {
        throw new Error('Failed to fetch ticket data: ' + (error.message || 'Unknown error'));
    }
};
