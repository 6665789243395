import { useEffect, useState } from 'react';
import {
    Box,
    Paper,
    Typography,
    TextField,
    Skeleton, Chip,
} from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { fetchDailyEvents } from './DailyEventsAPI';

const SkeletonRow = () => (
    <Box sx={{ display: 'flex', alignItems: 'center', height: 52, px: 2 }}>
        <Skeleton variant="rectangular" width="20%" height={20} sx={{ mr: 2 }} />
        <Skeleton variant="rectangular" width="30%" height={20} sx={{ mr: 2 }} />
        <Skeleton variant="rectangular" width="15%" height={20} sx={{ mr: 2 }} />
        <Skeleton variant="rectangular" width="15%" height={20} sx={{ mr: 2 }} />
        <Skeleton variant="circular" width={24} height={24} sx={{ mr: 1 }} />
        <Skeleton variant="circular" width={24} height={24} />
    </Box>
);

const DailyEvents = ({eventId}) => {
    const [events, setEvents] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [searchQuery, setSearchQuery] = useState('');
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 10
    });
    const [totalCount, setTotalCount] = useState(0);

    const loadEvents = async () => {
        setLoading(true);
        try {
            const data = await fetchDailyEvents(eventId, paginationModel.page, paginationModel.pageSize, searchQuery);
            const eventsDaily = data.data.eventsDaily;

            // Map eventItem and include the name at the top level
            const eventData = eventsDaily.map(eventItem => ({
                ...eventItem,
                name: eventItem.event.name, // Adding the event name to the top level
                packages: eventItem.packages
            }));

            setEvents(eventData); // Set the modified data to the state
            setTotalCount(data.data.pagination.totalCount || 0);
        } catch (error) {
            console.error('Error loading events:', error);
            setError('Failed to load events');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        loadEvents();
    }, [paginationModel, searchQuery]);

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
        setPaginationModel({ ...paginationModel, page: 0 });
    };

    const handlePaginationModelChange = (newModel) => {
        setPaginationModel(newModel);
    };

    const renderPackages = (packages) => (
        <Box>
            {packages.map((pkg) => (
                <Chip
                    key={pkg.id}
                    label={`${pkg.name} - RM${pkg.amount} - RM${pkg.promotion_amount}`}
                    variant="outlined"
                    style={{ margin: '2px' }}
                />
            ))}
        </Box>
    );

    const columns = [
        { field: 'id', headerName: 'ID', flex: 0.5, minWidth: 80 },
        { field: 'name', headerName: 'Event Name', flex: 2, minWidth: 150},
        { field: 'event_date', headerName: 'Event Date', flex: 1.5, minWidth: 130 },
        { field: 'status_id', headerName: 'Status', flex: 0.8, minWidth: 110 },
        { field: 'minimum_pax', headerName: 'Min Pax', flex: 0.8, minWidth: 110 },
        { field: 'maximum_pax', headerName: 'Max Pax', flex: 0.8, minWidth: 110 },
        { field: 'current_pax', headerName: 'Current Pax', flex: 0.8, minWidth: 140 },
        {
            field: 'packages',
            headerName: 'Packages',
            flex: 2,
            minWidth: 200,
            renderCell: (params) => {
                if (!params.value || params.value.length === 0) {
                    return 'No packages';
                }
                return (
                    <Box>
                        {params.value.map((pkg) => (
                            <Chip
                                key={pkg.id}
                                label={
                                    <span>
                                {pkg.name} -
                                        {pkg.promotion_amount ? (
                                            <>
                                                <span style={{ textDecoration: 'line-through' }}>RM{pkg.amount}</span>
                                                {' RM' + pkg.promotion_amount}
                                            </>
                                        ) : (
                                            'RM' + pkg.amount
                                        )}
                            </span>
                                }
                                variant="outlined"
                                style={{ margin: '2px' }}
                            />
                        ))}
                    </Box>
                );
            }
        },
    ];

    return (
        <Box>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                <Box />
                <Box display="flex" gap="12px">
                    <TextField
                        variant="outlined"
                        placeholder="Search events"
                        value={searchQuery}
                        onChange={handleSearchChange}
                        size="small"
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                height: '34px',
                                '& input': {
                                    height: '34px',
                                    padding: '0 14px',
                                },
                                '& .MuiInputBase-input': {
                                    height: '34px',
                                    padding: '0 14px',
                                }
                            }
                        }}
                    />
                </Box>
            </Box>

            <Paper
                sx={{
                    width: "100%",
                    borderRadius: "16px",
                    marginTop: '24px',
                    border: "1px solid var(--Gray-200, #EAECF0)",
                    background: "var(--White, #FFF)",
                    boxShadow: "0px 1px 3px 0px rgba(16, 24, 40, 0.10), 0px 1px 2px 0px rgba(16, 24, 40, 0.06)",
                }}
            >
                <Typography
                    sx={{
                        color: "var(--Gray-900, #101828)",
                        fontFamily: "Inter",
                        fontSize: "18px",
                        fontStyle: "normal",
                        fontWeight: 500,
                        lineHeight: "28px",
                        padding: "20px 24px",
                    }}
                >
                    Scheduled Events
                </Typography>

                {loading ? (
                    <Box>
                        <Box sx={{ px: 2, py: 1 }}>
                            <Skeleton variant="rectangular" height={52} />
                        </Box>
                        {[...Array(5)].map((_, index) => (
                            <SkeletonRow key={index} />
                        ))}
                    </Box>
                ) : error ? (
                    <Typography color="error" sx={{ p: 2 }}>{error}</Typography>
                ) : (
                    <DataGrid
                        slots={{ toolbar: GridToolbar }}
                        rows={events}
                        columns={columns}
                        paginationModel={paginationModel}
                        onPaginationModelChange={handlePaginationModelChange}
                        pageSizeOptions={[5, 10, 20]}
                        rowCount={totalCount}
                        paginationMode="server"
                        checkboxSelection
                        disableRowSelectionOnClick
                        getRowHeight={() => 'auto'}
                        loading={loading}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 10,
                                },
                            },
                        }}
                    />
                    // <DataGrid
                    //     slots={{ toolbar: GridToolbar }}
                    //     rows={events}
                    //     columns={columns}
                    //     paginationModel={paginationModel}
                    //     onPaginationModelChange={handlePaginationModelChange}
                    //     pageSizeOptions={[5, 10, 20]}
                    //     rowCount={totalCount}
                    //     paginationMode="server"
                    //     checkboxSelection
                    //     disableRowSelectionOnClick
                    //     getRowHeight={() => 'auto'}
                    //     loading={loading}
                    //     initialState={{
                    //         pagination: {
                    //             paginationModel: {
                    //                 pageSize: 10,
                    //             },
                    //         },
                    //     }}
                    //     components={{
                    //         Row: (props) => {
                    //             const { row } = props;
                    //             return (
                    //                 <React.Fragment>
                    //                     <tr {...props} />
                    //                     {row.packages && row.packages.length > 0 && (
                    //                         <tr>
                    //                             <td colSpan={columns.length} style={{ paddingLeft: '16px', paddingBottom: '8px' }}>
                    //                                 {renderPackages(row.packages)}
                    //                             </td>
                    //                         </tr>
                    //                     )}
                    //                 </React.Fragment>
                    //             );
                    //         },
                    //     }}
                    // />
                )}
            </Paper>
        </Box>
    );
};

export default DailyEvents;
