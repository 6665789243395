import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Box, IconButton, Paper, Typography } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import keycloak from "../../../../keycloak";

const staticImages = [
    "/1.jpeg",
    "/2.jpeg",
    "/3.jpeg",
    "/4.jpeg",
    "/5.jpeg",
    "/6.jpeg",
];

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const PLACEHOLDER_IMAGE = staticImages[0];
const THUMBNAILS_TO_SHOW = 6;
const IMAGE_URL="https://s3.ap-southeast-5.amazonaws.com/my.merchants/";

const ProductDetails = () => {
    const { id } = useParams();
    const [product, setProduct] = useState(null);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [startIndex, setStartIndex] = useState(0);

    useEffect(() => {
        const fetchProduct = async () => {
            try {
                const response = await axios.get(`${API_BASE_URL}/products?id=${id}`, {
                    headers: {
                        'Authorization': `Bearer ${keycloak.token}`
                    }
                });
                setProduct(response.data.data);
            } catch (error) {
                console.error("Error fetching product:", error);
            }
        };

        fetchProduct();
    }, [id, keycloak.token]);

    // Helper function to determine which image array to use
    const getImageArray = () => {
        if (!product?.product_images || product.product_images.length <= 1) {
            return staticImages.map(url => ({ image_url: url }));
        }
        return product.product_images;
    };

    const handlePrevImage = () => {
        const images = getImageArray();
        setCurrentImageIndex((prevIndex) =>
            prevIndex === 0 ? images.length - 1 : prevIndex - 1
        );
    };

    const handleNextImage = () => {
        const images = getImageArray();
        setCurrentImageIndex((prevIndex) =>
            prevIndex === images.length - 1 ? 0 : prevIndex + 1
        );
    };

    const handleScrollUp = () => {
        setStartIndex((prev) => Math.max(0, prev - 1));
    };

    const handleScrollDown = () => {
        const images = getImageArray();
        setStartIndex((prev) =>
            Math.min(
                prev + 1,
                images.length - THUMBNAILS_TO_SHOW
            )
        );
    };

    const getCurrentImage = () => {
        const images = getImageArray();
        if (images.length > 0) {
            return images[currentImageIndex].image_url;
        }
        return PLACEHOLDER_IMAGE;
    };

    const visibleThumbnails = getImageArray().slice(
        startIndex,
        startIndex + THUMBNAILS_TO_SHOW
    );

    return (
        <Box>
            <Paper
                sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', md: 'row' },
                    padding: '32px',
                    alignItems: { xs: 'center', md: 'flex-start' },
                    justifyContent: 'center',
                    borderRadius: '12px',
                    elevation: 1,
                    width: 'fit-content',
                    margin: 'auto',
                }}
            >
                <Box sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', md: 'row' },
                    alignItems: 'center',
                    gap: '16px'
                }}>
                    {/* Thumbnail Carousel */}
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: '8px'
                    }}>
                        <IconButton
                            onClick={handleScrollUp}
                            disabled={startIndex === 0}
                            size="small"
                        >
                            <KeyboardArrowUpIcon />
                        </IconButton>

                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '8px',
                            maxHeight: '400px'
                        }}>
                            {visibleThumbnails.map((image, index) => (
                                <Box
                                    key={startIndex + index}
                                    component="img"
                                    src={`${IMAGE_URL}${image.image_url}`}
                                    alt={`Thumbnail ${startIndex + index + 1}`}
                                    sx={{
                                        width: '60px',
                                        height: '60px',
                                        objectFit: 'cover',
                                        borderRadius: '4px',
                                        cursor: 'pointer',
                                        border: currentImageIndex === (startIndex + index)
                                            ? '2px solid #1976d2'
                                            : '2px solid transparent',
                                        '&:hover': {
                                            opacity: 0.8
                                        }
                                    }}
                                    onClick={() => setCurrentImageIndex(startIndex + index)}
                                />
                            ))}
                        </Box>

                        <IconButton
                            onClick={handleScrollDown}
                            disabled={startIndex >= (getImageArray().length - THUMBNAILS_TO_SHOW)}
                            size="small"
                        >
                            <KeyboardArrowDownIcon />
                        </IconButton>
                    </Box>

                    {/* Main Image Section */}
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Box
                            component="img"
                            sx={{
                                width: { xs: '320px', md: '500px' },
                                height: 'auto',
                                borderRadius: '8px',
                                marginBottom: '16px',
                            }}
                            alt="Product Image"
                            src={getCurrentImage()}
                        />
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                            <IconButton onClick={handlePrevImage}>
                                <ArrowBackIosIcon />
                            </IconButton>
                            <Typography>
                                {currentImageIndex + 1} / {getImageArray().length}
                            </Typography>
                            <IconButton onClick={handleNextImage}>
                                <ArrowForwardIosIcon />
                            </IconButton>
                        </Box>
                    </Box>
                </Box>

                {/* Product Details Section */}
                <Box sx={{ marginLeft: { xs: 0, md: '48px' }, marginTop: { xs: 5, md: 0 }, textAlign: { xs: 'center', md: 'left' } }}>
                    <Typography variant="h1" component="h1">
                        {product?.name}
                    </Typography>
                    <Typography variant="h2" >
                        {product?.description}
                    </Typography>
                    <Typography variant="h2">
                        Price: RM {product?.price}
                    </Typography>
                    {product?.variants && product.variants.length > 0 && (
                        <Box sx={{ marginTop: 2 }}>
                            <Typography variant="h3">Variants:</Typography>
                            {product.variants.map((variant, index) => (
                                <Typography key={index} variant="body1">
                                    {variant.variant_name}: {variant.variant_value} - RM {variant.price}
                                </Typography>
                            ))}
                        </Box>
                    )}
                </Box>
            </Paper>
        </Box>
    );
};

export default ProductDetails;