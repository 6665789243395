import React, { useState } from "react";
import { Box, Typography, TextField, Paper, Divider, IconButton } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Delete as DeleteIcon, Edit as EditIcon } from "@mui/icons-material";
import { ViewIcon } from "../../shared/IconButtons";

const AdminBusinessDetails = () => {
    const [searchQuery, setSearchQuery] = useState('');

    const rows = [
        { id: 1, companyName: 'Anderson Trading Sdn. Bhd.', registrationNo: '2020010001', address: '12, Jalan Ampang, Kuala Lumpur', postcode: '50450', state: 'Kuala Lumpur', country: 'Malaysia' },
        { id: 2, companyName: 'Miller Ventures Sdn. Bhd.', registrationNo: '2019023456', address: '45, Jalan Tun Razak, Johor Bahru', postcode: '80200', state: 'Johor', country: 'Malaysia' },
        { id: 3, companyName: 'Harris Engineering Sdn. Bhd.', registrationNo: '2018056789', address: '56, Lorong Raja Bot, Klang', postcode: '41000', state: 'Selangor', country: 'Malaysia' },
        { id: 4, companyName: 'Clark Solutions Sdn. Bhd.', registrationNo: '2017065432', address: '78, Jalan Bukit Bintang, Kuala Lumpur', postcode: '55100', state: 'Kuala Lumpur', country: 'Malaysia' },
        { id: 5, companyName: 'Davis & Co. Sdn. Bhd.', registrationNo: '2016106789', address: '23, Jalan Imbi, George Town', postcode: '10050', state: 'Penang', country: 'Malaysia' },
        { id: 6, companyName: 'Brown Innovations Sdn. Bhd.', registrationNo: '2015034567', address: '89, Jalan Tunku Abdul Rahman, Ipoh', postcode: '30000', state: 'Perak', country: 'Malaysia' },
        { id: 7, companyName: 'Taylor Health Solutions Sdn. Bhd.', registrationNo: '2014098765', address: '34, Jalan Sultan Ahmad Shah, Alor Setar', postcode: '05100', state: 'Kedah', country: 'Malaysia' },
        { id: 8, companyName: 'Walker Technologies Sdn. Bhd.', registrationNo: '2013123456', address: '67, Jalan Dato Bandar Tunggal, Seremban', postcode: '70000', state: 'Negeri Sembilan', country: 'Malaysia' },
        { id: 9, companyName: 'White Consulting Sdn. Bhd.', registrationNo: '2012067890', address: '78, Jalan Taming Sari, Melaka', postcode: '75000', state: 'Melaka', country: 'Malaysia' },
        { id: 10, companyName: 'Moore Marketing Sdn. Bhd.', registrationNo: '2011090123', address: '90, Jalan Pahlawan, Kuching', postcode: '93000', state: 'Sarawak', country: 'Malaysia' },
    ];

    const filteredRows = rows.filter((row) =>
        row.companyName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        row.registrationNo.toLowerCase().includes(searchQuery.toLowerCase()) ||
        row.address.toLowerCase().includes(searchQuery.toLowerCase()) ||
        row.postcode.toLowerCase().includes(searchQuery.toLowerCase()) ||
        row.state.toLowerCase().includes(searchQuery.toLowerCase()) ||
        row.country.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const columns = [
        { field: 'companyName', headerName: 'Company Name', flex: 1 },
        { field: 'registrationNo', headerName: 'Registration No.', flex: 1 },
        { field: 'address', headerName: 'Company Address', flex: 2 },
        { field: 'postcode', headerName: 'Postcode', flex: 0.5 },
        { field: 'state', headerName: 'State', flex: 0.5 },
        { field: 'country', headerName: 'Country', flex: 0.5 },
        {
            field: 'actions',
            headerName: '',
            flex: 0.7,
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', height: '100%', width: '100%' }}>
                    <IconButton aria-label="view" onClick={() => console.log('view', params.row)}>
                        <ViewIcon />
                    </IconButton>
                    <IconButton aria-label="edit" onClick={() => console.log('edit', params.row)}>
                        <EditIcon />
                    </IconButton>
                    <IconButton aria-label="delete" onClick={() => console.log('delete', params.row.id)}>
                        <DeleteIcon />
                    </IconButton>
                </Box>
            )
        }
    ];

    return (
        <Box>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                <Typography variant="h1">Business Details</Typography>
                <TextField
                    variant="outlined"
                    placeholder="Search..."
                    value={searchQuery}
                    onChange={handleSearchChange}
                    size="small"
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            height: '34px',
                            '& input': {
                                height: '34px',
                                padding: '0 14px',
                            },
                            '& .MuiInputBase-input': {
                                height: '34px',
                                padding: '0 14px',
                            }
                        }
                    }}
                />
            </Box>

            <Divider/>

            <Paper
                sx={{
                    width: "100%",
                    borderRadius: "16px",
                    marginTop: '24px',
                    border: "1px solid var(--Gray-200, #EAECF0)",
                    background: "var(--White, #FFF)",
                    boxShadow: "0px 1px 3px 0px rgba(16, 24, 40, 0.10), 0px 1px 2px 0px rgba(16, 24, 40, 0.06)",
                }}
            >
                <Typography
                    sx={{
                        color: "var(--Gray-900, #101828)",
                        fontFamily: "Inter",
                        fontSize: "18px",
                        fontStyle: "normal",
                        fontWeight: 500,
                        lineHeight: "28px",
                        padding: "20px 24px",
                    }}
                >
                    Business Details List
                </Typography>
                <DataGrid
                    slots={{ toolbar: GridToolbar }}
                    rows={filteredRows}
                    columns={columns}
                    checkboxSelection
                    disableSelectionOnClick
                    rowHeight={70}
                    pageSizeOptions={[5, 10, 20]}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 6,
                            },
                        },
                    }}
                    sx={{
                        '& .MuiDataGrid-toolbarContainer .MuiButtonBase-root': {
                            backgroundColor: '#737373',
                            color: '#fff',
                            height: '34px',
                            my: 1,
                            minWidth: 'auto',
                            '&:hover': {
                                backgroundColor: '#404040',
                            },
                        },
                    }}
                />
            </Paper>
        </Box>
    );
};

export default AdminBusinessDetails;
