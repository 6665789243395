import {Box, Button, Divider, Typography} from "@mui/material";
import React from "react";
import Verification from "../../merchant/Verification";

const ComplianceDocuments = () => {
    return(
        <>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', alignSelf: 'stretch', width: '100%'}}>
                <Box >
                    <Typography variant='h4'>
                        Compliance and Documents
                    </Typography >
                    <Typography variant='h5'>
                        PCI compliance, documents, and legacy exports.
                    </Typography>
                </Box>
                <Box sx={{ ml: 'auto', display: 'flex', gap: '12px' }}>
                    <Button variant="cancel" onClick={ ()=>{} }>Cancel</Button>
                    <Button variant="save" onClick={ ()=>{} }>Save as Draft</Button>
                </Box>
            </Box>

            <Divider/>

            <Verification/>
        </>
    )
}

export default ComplianceDocuments;