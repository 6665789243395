import React, { useState, useEffect } from 'react';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    IconButton,
    Typography,
    Box,
    Grid,
    Paper,
    Divider,
    Tooltip
} from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

const PurchaseDetailsModal = ({ id }) => {
    const [open, setOpen] = useState(false);
    const [purchaseDetails, setPurchaseDetails] = useState(null);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        if (open) {
            // Simulated API call to fetch booking details
            // Replace this with your actual API call
            const fetchPurchaseDetails = async () => {
                // Simulating an API delay
                await new Promise(resolve => setTimeout(resolve, 500));

                // Placeholder data - replace with actual API response
                const details = {
                    eventName: `Event for Booking ${id}`,
                    bookingId: `BK${id}`,
                    ticketType: "VIP Pass",
                    quantity: 2,
                    totalPrice: "RM200",
                    bookingDate: "2023-07-15",
                    eventDate: "2023-08-20"
                };
                setPurchaseDetails(details);
            };

            fetchPurchaseDetails();
        }
    }, [open, id]);

    return (
        <Box>
            <Tooltip title="Resend email to customer">
                <IconButton onClick={handleClickOpen} size="small">
                    <OpenInNewIcon />
                </IconButton>
            </Tooltip>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle id="alert-dialog-title">
                    {"Purchase Details"}
                </DialogTitle>
                <DialogContent>
                    <Typography variant="body2" color="text.secondary" gutterBottom>
                        View your purchase details below.
                    </Typography>
                    {purchaseDetails ? (
                        <Paper elevation={3} sx={{ p: 2, mt: 2 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="h6">{purchaseDetails.eventName}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body2" color="text.secondary">Booking ID</Typography>
                                    <Typography variant="body1">{purchaseDetails.bookingId}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body2" color="text.secondary">Ticket Type</Typography>
                                    <Typography variant="body1">{purchaseDetails.ticketType}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body2" color="text.secondary">Quantity</Typography>
                                    <Typography variant="body1">{purchaseDetails.quantity}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body2" color="text.secondary">Total Price</Typography>
                                    <Typography variant="body1">{purchaseDetails.totalPrice}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider sx={{ my: 1 }} />
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body2" color="text.secondary">Booking Date</Typography>
                                    <Typography variant="body1">{purchaseDetails.bookingDate}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body2" color="text.secondary">Event Date</Typography>
                                    <Typography variant="body1">{purchaseDetails.eventDate}</Typography>
                                </Grid>
                            </Grid>
                        </Paper>
                    ) : (
                        <Typography>Loading booking details...</Typography>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="cancel">
                        Close
                    </Button>
                    <Button onClick={handleClose} variant="save" >
                        Resend Email
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default PurchaseDetailsModal;