import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getToken } from '../utils/authUtils';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const getAuthHeaders = async () => {
    const token = await getToken();
    return {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
    };
};

export const fetchProductCategories = createAsyncThunk(
    'productCategories/fetchProductCategories',
    async (params = {}, { rejectWithValue }) => {
        try {
            const { page = 1, pageSize = 1000, searchQuery = '' } = params;
            const headers = await getAuthHeaders();
            const response = await axios.get(`${API_BASE_URL}/product-categories`, {
                headers,
                params: {
                    page,
                    limit: pageSize,
                    search: searchQuery,
                },
            });
            return response.data.data;
        } catch (error) {
            console.error('Error fetching product categories:', error);
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const createProductCategory = createAsyncThunk(
    'productCategories/createProductCategory',
    async (categoryData, { rejectWithValue }) => {
        try {
            const headers = await getAuthHeaders();
            const response = await axios.post(`${API_BASE_URL}/product-categories`, categoryData, { headers });
            return response.data.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const updateProductCategory = createAsyncThunk(
    'productCategories/updateProductCategory',
    async ({ id, categoryData }, { rejectWithValue }) => {
        try {
            const headers = await getAuthHeaders();
            const response = await axios.patch(`${API_BASE_URL}/product-categories/${id}`, categoryData, { headers });
            return response.data.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const deleteProductCategory = createAsyncThunk(
    'productCategories/deleteProductCategory',
    async (id, { rejectWithValue }) => {
        try {
            const headers = await getAuthHeaders();
            await axios.delete(`${API_BASE_URL}/product-categories/${id}`, { headers });
            return id;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

const productCategorySlice = createSlice({
    name: 'productCategories',
    initialState: {
        categories: [],
        status: 'idle',
        error: null,
        totalCount: 0,
        newlyCreatedCategory: null,
    },
    reducers: {
        clearNewlyCreatedCategory: (state) => {
            state.newlyCreatedCategory = null;
        },
        clearError: (state) => {
            state.error = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchProductCategories.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchProductCategories.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.productCategories = action.payload.productCategories;
                state.totalCount = action.payload.pagination.totalCount;
            })
            .addCase(fetchProductCategories.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to fetch product categories';
            })
            .addCase(createProductCategory.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.categories.push(action.payload);
                state.newlyCreatedCategory = action.payload;
            })
            .addCase(createProductCategory.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to create product category';
            })
            .addCase(updateProductCategory.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const index = state.categories.findIndex(category => category.id === action.payload.id);
                if (index !== -1) {
                    state.categories[index] = action.payload;
                }
            })
            .addCase(deleteProductCategory.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.categories = state.categories.filter(category => category.id !== action.payload);
            });
    }
});

export const { clearNewlyCreatedCategory, clearError } = productCategorySlice.actions;

export default productCategorySlice.reducer;