import React, {useState} from "react";
import { Box, Button, Divider, Paper, Typography, Stepper, Step, StepLabel } from "@mui/material";
import { useNavigate } from 'react-router-dom';  // Import useNavigate
import OnlinePayment from "../settings/others/Payment/PaymentMethod/OnlinePayment";
import keycloak from "../../keycloak";
import {setCurrentStep, updateOnboardingRecord} from "../../redux/accountOnboardingSlice";
import {useDispatch} from "react-redux";

const steps = ['Step 1', 'Step 2', 'Step 3', 'Step 4', 'Step 5']; // Stepper steps

const FifthStep = () => {
    const [activeStep, setActiveStep] = useState(4);
    const navigate = useNavigate();  // Initialize the useNavigate hook
    const dispatch = useDispatch();
    // Function to handle "Next" button click
    const handleConfirm = async () => {
        // navigate('/finish');  // Navigate to /finish
        dispatch(setCurrentStep('finish'));
        const token = keycloak.tokenParsed;
        try {
            const result = await dispatch(updateOnboardingRecord({
                email: token?.email,
                onboardingData: {
                    current_step: 'completed',
                    completed_steps: ['first', 'second', 'third', 'fourth', 'fifth']
                }
            })).unwrap();
            // navigate('/finish');
        } catch (error) {
            console.error('Failed to update server:', error);
            // Handle the error (e.g., show an error message to the user)
        }
    };

    // Function to handle "Previous" button click
    const handleCancel = () => {
        dispatch(setCurrentStep('fourth'));
        navigate('/fourth');  // Navigate to /fourth
    };

    return (
        <>
            <Stepper activeStep={4} alternativeLabel>
                {steps.map((label, index) => (
                    <Step key={index}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>

            <Paper elevation={5} sx={{ padding: { xs: '16px', sm: '24px', md: '32px' }, margin: '32px', borderRadius: '12px' }}>
                <Box>
                    <Typography variant='h4'>
                        Payment Method
                    </Typography>
                    <Typography variant='h5'>
                        Select your preferred payment method.
                    </Typography>
                </Box>

                <Divider sx={{ my: 2 }} />

                <OnlinePayment />

                <Box sx={{ display: 'flex', gap: '12px', justifyContent: { xs: 'center', md: 'flex-end' }, mt: 5 }}>
                    <Button
                        onClick={handleConfirm}
                        sx={{
                            textTransform: 'none',
                            color: 'orange',
                            border: 'none',
                            boxShadow: 'none',
                            '&:hover': {
                                backgroundColor: 'transparent',
                                border: 'none',
                            },
                        }}
                    >
                        Skip for now
                    </Button>
                    <Button variant="cancel" onClick={handleCancel}>Previous</Button>
                    <Button
                        variant="save"
                        onClick={handleConfirm}
                        sx={{ textTransform: 'none', height: '34px' }}
                    >
                        Next
                    </Button>
                </Box>
            </Paper>
        </>
    );
}

export default FifthStep;
