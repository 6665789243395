import { Outlet } from 'react-router-dom';
import { Box, } from "@mui/material";

const SettingsLayout = () => {
    return (
        // <Box sx={{ padding:{xs:1,md:'32px'}, height: '100%', minHeight: '100vh', backgroundColor: '#f5f5f5' }}>
        <Box sx={{ padding:{xs:1,md:'32px'} }}>
            <Outlet />
        </Box>
    );
};

export default SettingsLayout;
