import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    Box,
    IconButton,
    Select,
    MenuItem,
    CircularProgress,
    Typography,
} from '@mui/material';
import {
    FormatBold,
    FormatItalic,
    FormatUnderlined,
    Delete as DeleteIcon,
} from '@mui/icons-material';
import { Editor, EditorState, RichUtils } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import 'draft-js/dist/Draft.css';
import { createAgreement } from '../../../redux/agreementSlice';

const FONT_SIZES = [8, 9, 10, 11, 12, 14, 16, 18, 20, 24, 30, 36, 48, 60, 72, 96];

const CustomEditor = ({ editorState, onEditorChange }) => {
    const handleKeyCommand = (command, editorState) => {
        const newState = RichUtils.handleKeyCommand(editorState, command);
        if (newState) {
            onEditorChange(newState);
            return 'handled';
        }
        return 'not-handled';
    };

    const toggleInlineStyle = (inlineStyle) => {
        onEditorChange(RichUtils.toggleInlineStyle(editorState, inlineStyle));
    };

    const handleFontSizeChange = (event) => {
        const fontSize = event.target.value;
        onEditorChange(RichUtils.toggleInlineStyle(editorState, `FONTSIZE_${fontSize}`));
    };

    const customStyleMap = FONT_SIZES.reduce((acc, size) => {
        acc[`FONTSIZE_${size}`] = { fontSize: `${size}px` };
        return acc;
    }, {});

    return (
        <Box>
            <Box sx={{
                display: 'flex',
                gap: 1,
                alignItems: 'center',
                mb: 1,
                backgroundColor: '#f5f5f5',
                padding: '8px',
                borderRadius: '4px'
            }}>
                <IconButton
                    onClick={() => toggleInlineStyle('BOLD')}
                    size="small"
                    title="Bold"
                >
                    <FormatBold />
                </IconButton>
                <IconButton
                    onClick={() => toggleInlineStyle('ITALIC')}
                    size="small"
                    title="Italic"
                >
                    <FormatItalic />
                </IconButton>
                <IconButton
                    onClick={() => toggleInlineStyle('UNDERLINE')}
                    size="small"
                    title="Underline"
                >
                    <FormatUnderlined />
                </IconButton>
                <Select
                    value=""
                    onChange={handleFontSizeChange}
                    displayEmpty
                    size="small"
                    sx={{ minWidth: 100 }}
                >
                    <MenuItem value="" disabled>Font Size</MenuItem>
                    {FONT_SIZES.map((size) => (
                        <MenuItem key={size} value={size}>{size}px</MenuItem>
                    ))}
                </Select>
            </Box>
            <Box sx={{
                border: '1px solid #ccc',
                borderRadius: '8px',
                minHeight: '200px',
                padding: '16px',
                backgroundColor: '#fff'
            }}>
                <Editor
                    editorState={editorState}
                    onChange={onEditorChange}
                    handleKeyCommand={handleKeyCommand}
                    customStyleMap={customStyleMap}
                    placeholder="Enter content here..."
                />
            </Box>
        </Box>
    );
};

const AddAgreement = ({ open, onClose, onSuccess }) => {
    const dispatch = useDispatch();
    const agreementState = useSelector(state => state.agreement) || {};
    const { status = 'idle' } = agreementState;

    const [title, setTitle] = useState('');
    const [sections, setSections] = useState([
        { subtitle: '', content: EditorState.createEmpty() }
    ]);

    const handleTitleChange = (event) => {
        setTitle(event.target.value);
    };

    const handleSubtitleChange = (index, event) => {
        const newSections = [...sections];
        newSections[index].subtitle = event.target.value;
        setSections(newSections);
    };

    const handleContentChange = (index, newEditorState) => {
        const newSections = [...sections];
        newSections[index].content = newEditorState;
        setSections(newSections);
    };

    const addSection = () => {
        setSections([...sections, { subtitle: '', content: EditorState.createEmpty() }]);
    };

    const removeSection = (index) => {
        if (sections.length > 1) {
            const newSections = sections.filter((_, i) => i !== index);
            setSections(newSections);
        }
    };

    const createFullHtmlSource = (title, sections) => {
        const sectionHtml = sections.map(section => `
            ${section.subtitle ? `<h2>${section.subtitle}</h2>` : ''}
            ${stateToHTML(section.content.getCurrentContent())}
        `).join('');

        return `
            <!DOCTYPE html>
            <html lang="en">
            <head>
                <meta charset="UTF-8">
                <meta name="viewport" content="width=device-width, initial-scale=1.0">
                <title>${title}</title>
                <style>
                    body {
                        font-family: Arial, sans-serif;
                        line-height: 1.6;
                        color: #333;
                        max-width: 800px;
                        margin: 0 auto;
                        padding: 20px;
                    }
                    h1 { color: #2c3e50; }
                    h2 { color: #34495e; }
                </style>
            </head>
            <body>
                <h1>${title}</h1>
                ${sectionHtml}
            </body>
            </html>
        `.trim();
    };

    const handleSave = async () => {
        if (!title.trim()) {
            onSuccess('Please enter a title', 'error');
            return;
        }

        const htmlSource = createFullHtmlSource(title, sections);

        const newAgreement = {
            title: title.trim(),
            content: htmlSource,
            status: 1
        };

        try {
            await dispatch(createAgreement(newAgreement)).unwrap();
            onSuccess('Agreement created successfully');
            handleClose();
        } catch (error) {
            onSuccess(error?.message || 'Failed to create agreement', 'error');
        }
    };

    const handleClose = () => {
        setTitle('');
        setSections([{ subtitle: '', content: EditorState.createEmpty() }]);
        onClose();
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth="md"
            fullWidth
            PaperProps={{
                sx: { maxHeight: '90vh' }
            }}
        >
            <DialogTitle>
                <Typography variant="h6">Add New Agreement</Typography>
            </DialogTitle>

            <DialogContent dividers>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, mt: 1 }}>
                    <TextField
                        label="Agreement Title"
                        value={title}
                        onChange={handleTitleChange}
                        fullWidth
                        required
                        error={!title.trim()}
                        helperText={!title.trim() ? 'Title is required' : ''}
                    />

                    {sections.map((section, index) => (
                        <Box key={index} sx={{
                            border: '1px solid #e0e0e0',
                            borderRadius: '8px',
                            padding: 2,
                            backgroundColor: '#fafafa'
                        }}>
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1,
                                mb: 2
                            }}>
                                <TextField
                                    label={`Section ${index + 1} Title`}
                                    value={section.subtitle}
                                    onChange={(e) => handleSubtitleChange(index, e)}
                                    fullWidth
                                />
                                <IconButton
                                    onClick={() => removeSection(index)}
                                    disabled={sections.length === 1}
                                    color="error"
                                    title="Remove section"
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </Box>
                            <CustomEditor
                                editorState={section.content}
                                onEditorChange={(newState) => handleContentChange(index, newState)}
                            />
                        </Box>
                    ))}

                    <Button
                        onClick={addSection}
                        variant="cancel"
                        sx={{ alignSelf: 'flex-start' }}
                    >
                        + Add Section
                    </Button>
                </Box>
            </DialogContent>

            <DialogActions sx={{ padding: 2 }}>
                <Button
                    variant="cancel"
                    onClick={handleClose}
                    disabled={status === 'loading'}
                >
                    Cancel
                </Button>
                <Button
                    variant="save"
                    onClick={handleSave}
                    disabled={status === 'loading' || !title.trim()}
                    startIcon={status === 'loading' ? <CircularProgress size={20} /> : null}
                >
                    {status === 'loading' ? 'Creating...' : 'Create Agreement'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AddAgreement;