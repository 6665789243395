import React from 'react';
import { ArrowLeft, AlertTriangle, Copy, ExternalLink } from 'lucide-react';
import { Box, Typography, Button, IconButton, List, ListItem, ListItemIcon, ListItemText, Paper, Alert, AlertTitle, Table, TableHead, TableBody, TableRow, TableCell, Link } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';

const DomainVerification = () => {
    const navigate = useNavigate();
    const location = useLocation();

    // Extract the query parameter
    const queryParams = new URLSearchParams(location.search);
    const domain = queryParams.get('domain');

    const dnsRecords = [
        {
            type: 'A',
            name: '@',
            ttl: '60 mins',
            currentValue: '18.255.247.75',
            requiredValue: '18.255.247.76'
        },
        {
            type: 'CNAME',
            name: 'www',
            ttl: '60 mins',
            currentValue: 'example.com',
            requiredValue: 'anotherexample.com'
        }
    ];

    const handleCopyValue = (value) => {
        navigator.clipboard.writeText(value);
    };

    return (
        <Box sx={{ maxWidth: '800px', margin: 'auto', p: 3, bgcolor: 'background.paper', borderRadius: '10px',
            boxShadow: '0 0 10px rgba(0, 0, 0, 0.05)' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
                <IconButton onClick={() => navigate(-1)}>
                    <ArrowLeft />
                </IconButton>
                {/* ini komen
                <Typography variant="h6">Domain Verification</Typography>
                <Typography variant="h6" sx={{ mr: 2 }}>Domain: {domain}</Typography>
                */}
                <Typography variant="h6" sx={{ mr: 2 }}>{domain}</Typography>
                <Typography variant="h6" sx={{
                    bgcolor: '#FFF4EA',
                    px: 1.5,
                    py: 0.5,
                    borderRadius: '4px',
                    fontSize: {
                        xs: '11px',  // for extra-small screens 12px
                        sm: '12px',  // for small screens 13px
                        md: '13px'   // 14px usually for medium and larger screens
                    },
                    color: '#945700'
                }}>
                    Needs setup
                </Typography>
            </Box>

           {/* ini warning box */}
            <Box sx={{border: '1px solid rgba(169, 169, 169, 0.3)', borderRadius: '10px', boxShadow: '0 0 10px rgba(128, 128, 128, 0.2)', mb: 3}}>
                <Alert severity="warning" icon={<AlertTriangle />} sx={{display: 'flex',
                    alignItems: 'center', borderRadius: '10px 10px 0 0'}}>
                    Warning: This domain requires verifications
                </Alert>
                <Box
                    sx={{
                        bgcolor: 'background.paper',
                        borderRadius: '0 0 10px 10px',
                        p: 2
                    }}
                >
                    <Typography variant="subtitle1">
                        Please ensure the DNS records match the required values for successful domain verification.
                    </Typography>
                </Box>
            </Box>

            {/*
            <Box sx={{mt: 3, mb: 2, display: 'flex', alignItems: 'center', pt: 1, pb: 1 }}>
                <Typography variant="h6" sx={{ mr: 2 }}>Domain: {domain}</Typography>
                <Typography variant="h6" sx={{
                    bgcolor: '#FFF4EA',
                    px: 1.5,
                    py: 0.5,
                    borderRadius: '4px',
                    fontSize: {
                        xs: '11px',  // for extra-small screens 12px
                        sm: '12px',  // for small screens 13px
                        md: '13px'   // 14px usually for medium and larger screens
                    },
                    color: '#945700'
                }}>
                    Needs setup
                </Typography>
            </Box>
            */}

             {/* THE REAL Step 1*/}
            <Paper
                elevation={1}
                sx={{
                    mb: 3,
                    p: 2,
                    borderRadius: 2,
                    border: '1px solid rgba(169, 169, 169, 0.3)'
                }}
            >
                <Typography variant="h6" sx={{ mb: 2 }}>
                    Step 1: Log in to Your Domain Registrar
                </Typography>
                <Box
                    sx={{
                        mb: 1,
                        p: 2,
                        borderRadius: 2,
                        border: '1px solid rgba(169, 169, 169, 0.3)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                    }}
                >
                    <Typography variant="subtitle1">
                        Log in to your Domain Registrar.
                    </Typography>
                </Box>
            </Paper>

            {/* THE REAL Step 2*/}
            <Paper
                elevation={1}
                sx={{
                    mb: 3,
                    p: 2,
                    borderRadius: 2,
                    border: '1px solid rgba(169, 169, 169, 0.3)'
                }}
            >
                <Typography variant="h6" sx={{ mb: 2 }}>
                    Step 2: Point domain to our landing pages.
                </Typography>

                <Typography sx={{ mb: 2 }}>
                    Open <Link href="#" color="primary" sx={{ display: 'inline-flex', alignItems: 'center' }}>
                    DNS Management
                    {/*<ExternalLink size={16} style={{ marginLeft: 4 }} />*/}
                </Link> for {domain} and update current values to match new values below.
                    Use the default TTL values if provided.
                </Typography>

                <Box sx={{
                    mb: 2,
                    borderRadius: 1,
                    border: '1px solid rgba(169, 169, 169, 0.3)',
                    overflow: 'auto'
                }}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>Type</TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell>TTL</TableCell>
                                <TableCell>Current value</TableCell>
                                <TableCell>Required value</TableCell>
                                <TableCell padding="none" width={48} />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {dnsRecords.map((record, index) => (
                                <TableRow key={index}>
                                    <TableCell>{record.type}</TableCell>
                                    <TableCell>{record.name}</TableCell>
                                    <TableCell>{record.ttl}</TableCell>
                                    <TableCell>{record.currentValue}</TableCell>
                                    <TableCell>{record.requiredValue}</TableCell>
                                    <TableCell padding="none">
                                        <IconButton
                                            size="small"
                                            sx={{ mx: 1 }}
                                            onClick={() => handleCopyValue(record.requiredValue)}
                                        >
                                            <Copy size={16} />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Box>

                <Box sx={{borderRadius: '0 0 10px 10px'}}>
                    <Typography variant="body2" color="text.secondary">
                        Need more help? Refer to our guide{' '}
                        <Link
                            href="#"
                            color="primary"
                            sx={{ display: 'inline-flex', alignItems: 'center' }}
                        >
                            Connecting your domain to Eventlah
                            <ExternalLink size={16} style={{ marginLeft: 4 }} />
                        </Link>
                    </Typography>
                </Box>

            </Paper>



{/*
            <List>
                {dnsRecords.map((record, index) => (
                    <ListItem
                        key={index}
                        component={Paper}
                        elevation={0}
                        sx={{
                            mb: 2,
                            p: 2,
                            '&:hover': { bgcolor: 'action.hover' }
                        }}
                    >
                        <ListItemIcon>
                            <ExternalLink />
                        </ListItemIcon>
                        <ListItemText
                            primary={`Type: ${record.type}, Name: ${record.name}`}
                            secondary={`TTL: ${record.ttl}, Current Value: ${record.currentValue}, Required Value: ${record.requiredValue}`}
                        />
                        <IconButton edge="end" aria-label="copy" onClick={() => handleCopyValue(record.requiredValue)}>
                            <Copy />
                        </IconButton>
                    </ListItem>
                ))}
            </List>
*/}

            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
                <Button variant="save" color="primary" onClick={() => navigate('/')}>
                    Verify Domain
                </Button>
            </Box>
        </Box>

    );
};

export default DomainVerification;