import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Tabs, Tab, Divider, Typography } from '@mui/material';
import CustomizeProduct from "./CustomizeProduct";
import ProductImage from "./ProductImage";
import ProductVariant from "./ProductVariant/ProductVariant";

const AddProduct = () => {
    const [value, setValue] = useState(0);
    const [hasVariants, setHasVariants] = useState(false);
    const location = useLocation();
    const [product, setProduct] = useState(location.state?.product || {});
    const [productId, setProductId] = useState(null);
    const [productSaved, setProductSaved] = useState(false);

    useEffect(() => {
        if (location.state?.product?.id) {
            setProductId(location.state.product.id);
            setHasVariants(location.state.product.has_variants || false);
            setProductSaved(true);
            setProduct(location.state.product);
        }
    }, [location.state?.product]);

    const onProductSave = (savedProduct) => {
        setProductSaved(true);
        if (savedProduct) {
            setProductId(savedProduct.id);
            setProduct(savedProduct);
            setHasVariants(savedProduct.has_variants || false);
        }
    };

    const handleTabChange = (event, newValue) => {
        if (productSaved) {
            setValue(newValue);
        }
    };

    const handleVariantsChange = (variantsEnabled) => {
        setHasVariants(variantsEnabled);
        setProduct(prevProduct => ({...prevProduct, has_variants: variantsEnabled}));
        if (!variantsEnabled && value === 2) {
            setValue(0);
        }
    };

    return (
        <Box display='flex'>
            <Box flex={1}>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', alignSelf: 'stretch', width: '100%'}}>
                    <Box>
                        <Typography variant='h4'>
                            {productId ? 'Edit Product' : 'Add New Product'}
                        </Typography>
                        <Typography variant='h5'>
                            {productId ? 'Edit your product details.' : 'Add a new product to your catalog.'}
                        </Typography>
                    </Box>
                </Box>

                <Box height='38px' paddingTop='32px'>
                    <Tabs value={value} onChange={handleTabChange} aria-label="product tabs">
                        <Tab label="customize product" />
                        <Tab label="product image" disabled={!productSaved} />
                        <Tab
                            label="product variant"
                            disabled={!productSaved || !hasVariants}
                            sx={{
                                '&.Mui-disabled': {
                                    color: 'background.default',
                                }
                            }}
                        />
                    </Tabs>
                </Box>

                <Divider sx={{margin: '41px 0 32px 0'}}/>

                <Box>
                    {value === 0 && (
                        <CustomizeProduct
                            product={product}
                            onVariantsChange={handleVariantsChange}
                            onProductSave={onProductSave}
                        />
                    )}
                    {value === 1 && productSaved && (
                        <ProductImage productId={productId} product={product} />
                    )}
                    {value === 2 && productSaved && hasVariants && (
                        <ProductVariant productId={productId} product={product} />
                    )}
                </Box>
            </Box>
        </Box>
    );
};

export default AddProduct;