import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getToken } from '../utils/authUtils'; // Import the getToken utility

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

// Helper function to generate headers with token
const getAuthHeaders = async () => {
    const token = await getToken(); // Fetch the token
    return {
        'Authorization': `Bearer ${token}`, // Add the Authorization header
        'Content-Type': 'application/json'
    };
};

// Thunk to fetch all event types
export const fetchEventTypes = createAsyncThunk('eventTypes/fetchAll', async (_, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders(); // Use async headers function
        const response = await axios.get(`${API_BASE_URL}/event-types`, {headers});
        return response?.data?.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Thunk to create a new event type
export const createEventType = createAsyncThunk('eventTypes/create', async (eventTypeData, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders(); // Use async headers function
        const response = await axios.post(`${API_BASE_URL}/event-types`, eventTypeData, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Thunk to update an event type
export const updateEventType = createAsyncThunk('eventTypes/update', async (eventTypeData, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders(); // Use async headers function
        const response = await axios.patch(`${API_BASE_URL}/administrations/event-types`, eventTypeData, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Thunk to fetch a specific event type by ID
export const fetchEventTypeById = createAsyncThunk('eventTypes/fetchById', async (id, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders(); // Use async headers function
        const response = await axios.get(`${API_BASE_URL}/administrations/event-types/${id}`, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Thunk to delete an event type by ID
export const deleteEventType = createAsyncThunk('eventTypes/delete', async (id, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders(); // Use async headers function
        await axios.delete(`${API_BASE_URL}/administrations/event-types/${id}`, { headers });
        return id;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Slice for Event Types Operations
const eventTypesSlice = createSlice({
    name: 'eventTypes',
    initialState: {
        eventTypes: [],
        selectedEventType: null,
        status: 'idle',
        error: null,
        newlyCreatedEventType: null,
    },
    reducers: {
        clearNewlyCreatedEventType: (state) => {
            state.newlyCreatedEventType = null;
        },
        clearError: (state) => {
            state.error = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchEventTypes.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchEventTypes.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.eventTypes = action.payload.eventTypes; // Adjust based on actual response structure
            })
            .addCase(fetchEventTypes.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to fetch event types';
            })

            .addCase(createEventType.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(createEventType.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.eventTypes.push(action.payload);
                state.newlyCreatedEventType = action.payload;
            })
            .addCase(createEventType.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to create event type';
            })

            .addCase(updateEventType.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(updateEventType.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const index = state.eventTypes.findIndex(type => type.id === action.payload.id);
                if (index !== -1) state.eventTypes[index] = action.payload;
                if (state.selectedEventType && state.selectedEventType.id === action.payload.id) {
                    state.selectedEventType = action.payload;
                }
            })
            .addCase(updateEventType.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to update event type';
            })

            .addCase(fetchEventTypeById.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchEventTypeById.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.selectedEventType = action.payload;
            })
            .addCase(fetchEventTypeById.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to fetch event type by ID';
            })

            .addCase(deleteEventType.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(deleteEventType.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.eventTypes = state.eventTypes.filter(type => type.id !== action.payload);
                if (state.selectedEventType && state.selectedEventType.id === action.payload) {
                    state.selectedEventType = null;
                }
            })
            .addCase(deleteEventType.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to delete event type';
            });
    },
});

export const { clearNewlyCreatedEventType, clearError } = eventTypesSlice.actions;
export default eventTypesSlice.reducer;
