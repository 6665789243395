import React from 'react';
import { Grid, Card, CardContent, Typography, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { varAlpha } from '../../theme/styles';
import { alpha } from '@mui/material/styles';

import {
    AccountBox as AccountBoxIcon,
    Email as EmailIcon,
    Public as PublicIcon,
    Business as BusinessIcon,
    Description as DescriptionIcon,
    ViewAgenda as PlanIcon,
    Security as SecurityIcon,
    Assessment as AssessmentIcon,
    Store as StorefrontIcon,
    PersonAddAlt1 as PersonAddAlt1Icon,
    Payment as PaymentIcon,
    UploadFile as UploadFileIcon,
    ShoppingCart as ShoppingCartIcon,
    ConfirmationNumber as TicketingIcon,
    RoomPreferences as RoomPreferencesIcon,
    LocalOffer as LocalOfferIcon,
    LocalShipping as LocalShippingIcon,
    Assignment as AssignmentIcon,
    CalendarToday as CalendarTodayIcon,
    People as PeopleIcon,
    Person as PersonIcon,
    Info as InfoIcon,
    ManageAccounts as ManageAccountsIcon,
    Bookmark as BookmarkIcon,
    History as HistoryIcon,
    Contacts as ContactsIcon,
    Receipt as BillingIcon,
    AttachMoney as AttachMoneyIcon,
    PriceCheck as PriceCheckIcon
} from '@mui/icons-material';
import theme from "../../theme";

const DescriptionTypography = styled(Typography)(({ theme }) => ({
    color: 'var(--Gray-500, #667085)',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '18px',
    marginTop: '4px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    maxHeight: '54px',
}));

const SettingsPage = () => {
    const navigate = useNavigate();

    const businessSettings = [
        { icon: <AccountBoxIcon />, title: 'Profile Settings', description: 'Manages user information, business public details.', path: 'profile-settings' },
        { icon: <StorefrontIcon />, title: 'Store Setup', description: 'Configure your store settings, layout, and branding.', path: 'store-setup' },
        { icon: <PublicIcon />, title: 'Landing Page', description: 'Design and manage your landing page.', path: 'landing-page' },
        { icon: <BusinessIcon />, title: 'Branding', description: 'Account details, account health, public info, payouts, legal entity, custom domains, and more.', path: 'branding' },
        { icon: <EmailIcon />, title: 'Email Notification', description: 'Set up and manage email notifications.', path: 'email-notifications' },
        { icon: <DescriptionIcon />, title: 'Compliance and Documents', description: 'PCI compliance, documents, and legacy exports.', path: 'compliance-documents' },
        { icon: <PlanIcon />, title: 'Subscriptions', description: 'Manage your subscription plans.', path: 'subscriptions' },
        { icon: <SecurityIcon />, title: 'Team and Security', description: 'Team members, roles, account security, authorized apps, and shared resources.', path: 'team-security' },
        { icon: <AssessmentIcon />, title: 'Account Status', description: 'Check the status of your account.', path: 'account-status' },
        { icon: <PublicIcon />, title: 'Domains Setup', description: 'Set up and verify domains for your online stores.', path: 'domain-setup' },
        { icon: <PersonAddAlt1Icon />, title: 'Agent Setup', description: 'Manage and configure your agents for affiliate purposes.', path: 'agent-setup' },
        { icon: <PaymentIcon />, title: 'Payment', description: 'Set up and manage payment methods and transactions.', path: 'payment' },
        { icon: <UploadFileIcon />, title: 'Uploads', description: 'Manage and organize your uploaded files and media content.', path: 'uploads' },
    ];

    const productSettings = [
        { icon: <ShoppingCartIcon />, title: 'Product Setup', description: 'Manage your products, categories, and inventory.', path: 'product-catalog' },
        { icon: <TicketingIcon />, title: 'Ticketing Setup', description: 'Manage your tickets.', path: 'ticketing-setup' },
        { icon: <RoomPreferencesIcon />, title: 'Booking Setup', description: 'Configure and manage bookings for your spaces or services.', path: 'booking-setup' },
        { icon: <LocalOfferIcon />, title: 'Coupons', description: 'Create and manage coupons and discount code.', path: 'coupon' },
        { icon: <CalendarTodayIcon />, title: 'Appointments', description: 'Schedule and manage customer appointments.', path: 'appointments' },
        { icon: <PeopleIcon />, title: 'Customers', description: 'View and manage customer records.', path: 'customer-records' },
    ];

    // const otherSettings = [
    //     { icon: <CalendarTodayIcon />, title: 'Appointments', description: 'Manage your appointments and schedules.', path: 'appointments' },
    //     { icon: <BookmarkIcon />, title: 'Booking/Order/Ticketing Status', description: 'Track the status of your bookings, orders, and tickets.', path: 'booking-order-status' },
    //     { icon: <HistoryIcon />, title: 'Transaction History', description: 'View your transaction history and details.', path: 'transaction-history' },
    //     { icon: <ContactsIcon />, title: 'Customer Record & Profile', description: 'Manage customer records and profiles.', path: 'customer-records' },
    //     { icon: <BillingIcon />, title: 'Billing & Plans', description: 'Easily Access and Update Your Plans and Billing Information.', path: 'billing' },
    //     { icon: <PaymentIcon />, title: 'Payment', description: 'Set up and manage payment methods and transactions.', path: 'payment' },
    // ];

    const handleCardClick = (path) => {
        if (path === 'landing-page') {
            window.location.href = 'https://pages.eventlah.com';
        } else {
            navigate(path);
        }
    };

    const SettingsSection = ({ title, subtitle, settings }) => (
        <Box sx={{ paddingTop: title === 'Product Settings' || title === 'Others' ? '32px' : 0 }}>
            <Typography variant="h4">
                {title}
            </Typography>
            <Typography variant="h5" sx={{ paddingBottom: '22px' }}>
                {subtitle}
            </Typography>
            <Grid container spacing={3}>
                {settings.map((item, index) => (
                    <Grid item xs={12} sm={12} md={4} lg={4} key={index}>
                        <Card onClick={() => handleCardClick(item.path)}>
                            <CardContent>
                                <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                                    <Box sx={{ mr: 2, mt: 0.5, flexShrink: 0 }}>
                                        {React.cloneElement(item.icon, {
                                            style: { width: 24, height: 24, color: theme.palette.primary.contrastText }
                                        })}
                                    </Box>
                                    <Box sx={{ flexGrow: 1 }}>
                                        <Typography variant="subtitle1" sx={{ mb: 0.5 }}>
                                            {item.title}
                                        </Typography>
                                        <DescriptionTypography>
                                            {item.description}
                                        </DescriptionTypography>
                                    </Box>
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );

    return (
        <Box sx={{ px: { xs: 2, lg: 3 } }}>
            <SettingsSection
                title="Business Settings"
                subtitle="Manage your business preferences and configurations"
                settings={businessSettings}
            />
            <SettingsSection
                title="Product Settings"
                subtitle="Control and configure your product settings"
                settings={productSettings}
            />
        </Box>
    );
};

export default SettingsPage;