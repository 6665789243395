import React, { useState, useEffect, memo } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import { useTheme } from '@mui/material/styles';
import Chart from 'react-apexcharts';
import { Icon } from '@iconify/react';

const fShortenNumber = (number) => {
    if (number == null) return '0';
    const suffixes = ['', 'K', 'M', 'B', 'T'];
    const value = Math.abs(number);
    const suffixNum = Math.floor(String(value).length / 3);
    let shortValue = parseFloat((suffixNum !== 0 ? value / Math.pow(1000, suffixNum) : value).toPrecision(2));
    if (shortValue % 1 !== 0) {
        shortValue = shortValue.toFixed(1);
    }
    return `${number < 0 ? '-' : ''}${shortValue}${suffixes[suffixNum]}`;
};

const fPercent = (number) => {
    if (number == null) return '0%';
    return number.toFixed(1) + '%';
};

const varAlpha = (color, opacity) => {
    return `rgba(${color} / ${opacity})`;
};

const bgGradient = ({ color }) => {
    return {
        background: `linear-gradient(${color})`,
    };
};

const SvgColor = ({ src, sx, ...other }) => {
    return (
        <Box
            component="span"
            sx={{
                width: 24,
                height: 24,
                display: 'inline-block',
                bgcolor: 'currentColor',
                mask: `url(${src}) no-repeat center / contain`,
                WebkitMask: `url(${src}) no-repeat center / contain`,
                ...sx,
            }}
            {...other}
        />
    );
};

const AnalyticsWidgetSummary = ({
                                    title = '',
                                    total = 0,
                                    icon,
                                    percent = 0,
                                    chart = { categories: [], series: [] },
                                    color = 'primary',
                                    sx,
                                    ...other
                                }) => {
    const theme = useTheme();
    const [key, setKey] = useState(0);

    useEffect(() => {
        setKey(prev => prev + 1);
    }, [chart]);

    const chartConfig = {
        options: {
            chart: {
                background: 'transparent',
                sparkline: { enabled: true },
                toolbar: { show: false },
                zoom: { enabled: false },
                animations: { enabled: false }
            },
            stroke: {
                curve: 'smooth',
                width: 2,
                lineCap: 'round'
            },
            grid: { show: false },
            colors: [theme.palette[color]?.dark || theme.palette.primary.dark],
            xaxis: {
                categories: chart.categories,
                axisBorder: { show: false },
                axisTicks: { show: false },
                // labels: { show: false }
            },
            yaxis: {
                show: false,
                min: Math.min(...chart.series) * 0.8,
                max: Math.max(...chart.series) * 1.2
            },
            tooltip: {
                enabled: true,
                followCursor: true,
                intersect: false,
                shared: true,
                // x: { show: false }, // Hide the x-axis label (month)
                marker: { show: true },
                fixed: {
                    enabled: true,
                    position: 'topRight',
                    offsetY: -5
                },
                y: {
                    formatter: function(value) {
                        return fShortenNumber(value); // Will only show the value like "2.4K"
                    },
                    title: {
                        formatter: function() {
                            return ''; // Remove the series name
                        }
                    }
                }
            },
            markers: {
                size: 0,
                strokeColors: theme.palette[color]?.dark || theme.palette.primary.dark,
                hover: {
                    size: 5,
                    sizeOffset: 3
                }
            },
            legend: { show: false }
        },
        series: [{
            name: title,
            data: chart.series
        }]
    };

    const safeColor = theme.palette[color] ? color : 'primary';

    const styledIcon = React.cloneElement(icon, {
        sx: {
            ...icon.props.sx,
            color: `${safeColor}.dark` // Use dark variant for better visibility
        }
    });

    return (
        <Card
            sx={{
                ...bgGradient({
                    color: `135deg, ${varAlpha(theme.palette[safeColor].lighterChannel || theme.palette[safeColor].light, 0.48)}, ${varAlpha(
                        theme.palette[safeColor].lightChannel || theme.palette[safeColor].light,
                        0.48
                    )}`,
                }),
                p: '18px 24px 24px 24px',
                height: '186px',
                boxShadow: 'none',
                color: `${safeColor}.darker`,
                backgroundColor: 'common.white',
                borderRadius: '20px',
                position: 'relative',
                overflow: 'hidden',
                '&:hover .apexcharts-canvas': {
                    cursor: 'pointer'
                },
                ...sx,
            }}
            {...other}
        >

            <SvgColor
                src="/shape-square.svg"
                sx={{
                    position: 'absolute',
                    left: 0,
                    top: '50%',
                    transform: 'translateY(-50%)',
                    width: 240,
                    height: 240,
                    opacity: 0.24,
                    color: theme.palette[safeColor].dark,
                    pointerEvents: 'none',
                }}
            />

            <Box
                sx={{
                    display: 'flex',
                    width: '100%',
                    position: 'relative',
                    zIndex: 1,
                }}
            >
                <Box sx={{
                    width: '100%',
                    height: 143,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                }}>
                    <Box sx={{display:'flex', alignItems:'center', justifyContent:'space-between'}}>
                        <Box sx={{ml:1.5,mt:0}}>
                            {styledIcon}
                        </Box>
                        <Box sx={{ display:'flex', mb: 5}}>
                            <Box >
                                {/*<Icon*/}
                                {/*    icon={percent < 0 ? 'eva:trending-down-fill' : 'eva:trending-up-fill'}*/}
                                {/*    width={20}*/}
                                {/*    height={20}*/}
                                {/*/>*/}
                            </Box>
                            <Box component="span" sx={{ typography: 'subtitle2' }}>
                                {/*{percent > 0 && '+'}*/}
                                {/*{fPercent(percent)}*/}
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={{display:'flex', alignItems:'center', justifyContent:'space-between',px:1,}}>
                        <Box sx={{mb:1}}>
                            <Box sx={{ fontSize:'13px', fontWeight: '500', mb: 1, whiteSpace: 'nowrap' }}>{title}</Box>
                            <Box sx={{ fontSize:'25px', fontWeight: 'bold' }}>{fShortenNumber(total)}</Box>
                        </Box>
                        <Box >
                            <Chart
                                key={key}
                                type="line"
                                options={chartConfig.options}
                                series={chartConfig.series}
                                width={84}
                                height={56}
                            />
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Card>
    );
};

export default memo(AnalyticsWidgetSummary);