import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Box,
    Typography,
    TextField,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Grid,
    Paper,
    Button,
    Popover,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Checkbox,
    FormControlLabel,
    Link,
    CircularProgress,
    Alert,
    Snackbar,
} from '@mui/material';
import { styled } from '@mui/system';
import { ChromePicker } from 'react-color';
import { CompanyLogo } from "../../../header/HeaderSvgImages";
import {
    fetchThemeComponents,
    updateThemeComponent,
    selectAllThemeComponents,
    selectThemeComponentsStatus,
    selectThemeComponentsError,
    selectUpdateStatus,
    selectUpdateError,
    resetUpdateStatus
} from '../../../../redux/themeComponentsSlice';
import keycloak from "../../../../keycloak";
import { PutObjectCommand, S3Client } from "@aws-sdk/client-s3";
import { v4 as uuidv4 } from 'uuid';
import { fetchOrganizationPublicDetails } from "../../../../redux/organizationPublicDetailsSlice";

const s3Client = new S3Client({
    region: "ap-southeast-5",
    credentials: {
        accessKeyId: process.env.REACT_APP_AWS_BITBUCKET_ACCESS_KEY_ID,
        secretAccessKey: process.env.REACT_APP_AWS_BITBUCKET_SECRET_ACCESS_KEY
    },
});

const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(2),
    margin: theme.spacing(2),
}));

const ColorBox = styled(Box)(({ bgcolor }) => ({
    width: '24px',
    height: '24px',
    backgroundColor: bgcolor,
    cursor: 'pointer',
    border: '1px solid #000',
    borderRadius: '50%',
    marginRight: '8px',
}));

const defaultFormData = {
    logo: <CompanyLogo/>,
    mainText: 'Receipt',
    description: "Thank you for your purchase! Below is a detailed summary of your transaction.",
    receiptNumber: 'RCP-001',
    receiptDate: '2024-09-28',
    customerName: 'John Doe',
    customerEmail: 'john.doe@example.com',
    items: [
        { description: 'Product 1', quantity: 2, price: 50 },
        { description: 'Product 2', quantity: 1, price: 100 },
    ],
    subtotal: 200,
    tax: 20,
    total: 220,
    paymentButton: 'Pay Now',
    footerText: 'Thank you for your business!',
    font: 'Helvetica',
    companyName: '',
    companyAddress: '',
    businessWebsite: '',
    showCompanyName: false,
    showCompanyAddress: false,
    showBusinessWebsite: false,
    colors: {
        body: {
            text: '#333333',
            buttonsAndLinks: '#ffeb3b',
            buttonLabelsAndOutlines: '#434040',
        },
        background: {
            background1: '#FFFFFF',
            background2: '#F5F5F5',
            border: '#000000',
        },
        footer: {
            textAndIcons: '#666666',
            background: '#F9F9F9',
        },
    },
};

const ReceiptTab = () => {
    const dispatch = useDispatch();
    const themeComponents = useSelector(selectAllThemeComponents);
    const fetchStatus = useSelector(selectThemeComponentsStatus);
    const fetchError = useSelector(selectThemeComponentsError);
    const updateStatus = useSelector(selectUpdateStatus);
    const updateError = useSelector(selectUpdateError);

    const [isEditing, setIsEditing] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [formData, setFormData] = useState(defaultFormData);
    const [colorPickerAnchor, setColorPickerAnchor] = useState(null);
    const [activeColorField, setActiveColorField] = useState(null);

    const organizationPublicDetails = useSelector((state) => state.organizationPublicDetails.details);

    // Fetch initial data
    useEffect(() => {
        const fetchData = async () => {
            try {
                await keycloak.updateToken(30);
                const organizationId = keycloak.tokenParsed?.organization_id;

                if (organizationId) {
                    await dispatch(fetchOrganizationPublicDetails(organizationId));
                    await dispatch(fetchThemeComponents({
                        page: 1,
                        limit: 10,
                        organization_id: organizationId
                    }));
                } else {
                    throw new Error('Organization ID not found');
                }
            } catch (error) {
                console.error('Failed to fetch initial data:', error);
                setSnackbarSeverity('error');
                setSnackbarMessage('Failed to load data. Please refresh the page.');
                setSnackbarOpen(true);
            }
        };

        if (fetchStatus === 'idle') {
            fetchData();
        }
    }, [dispatch, fetchStatus]);

    // Update form with organization details
    useEffect(() => {
        if (organizationPublicDetails?.length > 0) {
            const details = organizationPublicDetails[0]?.data;
            setFormData(prevData => ({
                ...prevData,
                companyName: details?.business_name || '',
                companyAddress: [
                    details?.customer_support_address_line_1,
                    details?.customer_support_address_line_2,
                    details?.postal_code,
                    details?.city,
                    details?.state
                ].filter(Boolean).join(', '),
                businessWebsite: details?.business_website || '',
            }));
        }
    }, [organizationPublicDetails]);

    // Update form with theme component data
    useEffect(() => {
        if (fetchStatus === 'succeeded' && themeComponents?.length > 0 && !isEditing) {
            const receiptComponent = themeComponents.find(component => component.name === 'receipt');
            if (receiptComponent) {
                setFormData(prevData => ({
                    ...prevData,
                    mainText: receiptComponent.main_text || 'Receipt',
                    description: receiptComponent.description || "Thank you for your purchase! Below is a detailed summary of your transaction.",
                    font: receiptComponent.typography || 'Helvetica',
                    showCompanyName: receiptComponent.show_company_name || false,
                    showCompanyAddress: receiptComponent.show_company_address || false,
                    showBusinessWebsite: receiptComponent.show_business_website || false,
                    colors: {
                        body: {
                            text: receiptComponent.body_text_color || '#333333',
                            buttonsAndLinks: receiptComponent.button_color || '#ffeb3b',
                            buttonLabelsAndOutlines: receiptComponent.button_label_color || '#434040',
                        },
                        background: {
                            background1: receiptComponent.background_color_1 || '#FFFFFF',
                            background2: receiptComponent.background_color_2 || '#F5F5F5',
                            border: receiptComponent.border_color || '#000000',
                        },
                        footer: {
                            textAndIcons: receiptComponent.footer_text_color || '#666666',
                            background: receiptComponent.footer_background_color || '#F9F9F9',
                        },
                    },
                    footerText: receiptComponent.other_text_1 || 'Thank you for your business!',
                    paymentButton: receiptComponent.other_text_2 || 'Pay Now',
                    logo: receiptComponent.logo || <CompanyLogo/>,
                }));
            }
        }
    }, [themeComponents, isEditing, fetchStatus]);

    // Monitor update status
    useEffect(() => {
        if (updateStatus === 'succeeded') {
            setSnackbarSeverity('success');
            setSnackbarMessage('Changes saved successfully!');
            setSnackbarOpen(true);
            setIsEditing(false);
            dispatch(resetUpdateStatus());
        } else if (updateStatus === 'failed') {
            setSnackbarSeverity('error');
            setSnackbarMessage(updateError || 'Failed to save changes.');
            setSnackbarOpen(true);
            dispatch(resetUpdateStatus());
        }
    }, [updateStatus, updateError, dispatch]);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setIsEditing(true);
        setFormData(prevData => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        setIsEditing(true);
        setFormData(prevData => ({
            ...prevData,
            [name]: checked,
        }));
    };

    const handleColorChange = (color) => {
        if (!activeColorField) return;

        setIsEditing(true);
        setFormData(prevData => ({
            ...prevData,
            colors: {
                ...prevData.colors,
                [activeColorField.group]: {
                    ...prevData.colors[activeColorField.group],
                    [activeColorField.field]: color.hex,
                },
            },
        }));
    };

    const handleColorBoxClick = (event, group, field) => {
        setColorPickerAnchor(event.currentTarget);
        setActiveColorField({ group, field });
    };

    const handleSave = async () => {
        const receiptComponent = themeComponents.find(component => component.name === 'receipt');
        if (!receiptComponent) {
            setSnackbarSeverity('error');
            setSnackbarMessage('Receipt component not found.');
            setSnackbarOpen(true);
            return;
        }

        try {
            const themeComponentData = {
                organization_id: keycloak.tokenParsed?.organization_id,
                name: 'receipt',
                main_text: formData.mainText,
                description: formData.description,
                typography: formData.font,
                show_company_name: formData.showCompanyName,
                show_company_address: formData.showCompanyAddress,
                show_business_website: formData.showBusinessWebsite,
                body_text_color: formData.colors.body.text,
                button_color: formData.colors.body.buttonsAndLinks,
                button_label_color: formData.colors.body.buttonLabelsAndOutlines,
                background_color_1: formData.colors.background.background1,
                background_color_2: formData.colors.background.background2,
                border_color: formData.colors.background.border,
                footer_text_color: formData.colors.footer.textAndIcons,
                footer_background_color: formData.colors.footer.background,
                other_text_1: formData.footerText,
                other_text_2: formData.paymentButton,
                logo: typeof formData.logo === 'string' ? formData.logo : null,
            };

            console.log('Sending update with data:', themeComponentData);

            await dispatch(updateThemeComponent({
                id: receiptComponent.id,
                themeComponentData
            })).unwrap();

            const organizationId = keycloak.tokenParsed?.organization_id;
            if (organizationId) {
                await dispatch(fetchThemeComponents({
                    page: 1,
                    limit: 10,
                    organization_id: organizationId
                }));
            }

        } catch (error) {
            console.error('Save error:', error);
            setSnackbarSeverity('error');
            setSnackbarMessage(error.message || 'Failed to update theme component.');
            setSnackbarOpen(true);
        }
    };

    const handleLogoUpload = async (event) => {
        const file = event.target.files[0];
        if (!file) return;

        setUploading(true);
        try {
            const timestamp = Date.now();
            const uniqueId = uuidv4().slice(0, 6);
            const fileNameParts = file.name.split('.');
            const fileExtension = fileNameParts.pop();
            const fileName = fileNameParts.join('.');
            const uniqueFileName = `${fileName}_${timestamp}_${uniqueId}.${fileExtension}`;
            const organizationId = keycloak.tokenParsed?.organization_id || '1';
            const key = `${organizationId}/${uniqueFileName}`;

            const params = {
                Bucket: "my.merchants",
                Key: key,
                Body: file,
                ContentType: file.type,
            };

            await s3Client.send(new PutObjectCommand(params));
            const fileUrl = `https://s3.ap-southeast-5.amazonaws.com/my.merchants/${encodeURIComponent(key)}`;

            const receiptComponent = themeComponents.find(component => component.name === 'receipt');
            if (receiptComponent) {
                await dispatch(updateThemeComponent({
                    id: receiptComponent.id,
                    themeComponentData: { logo: fileUrl }
                }));

                setFormData(prevData => ({
                    ...prevData,
                    logo: fileUrl,
                }));
                setIsEditing(true);

                setSnackbarSeverity('success');
                setSnackbarMessage('Logo uploaded successfully!');
                setSnackbarOpen(true);
            }
        } catch (err) {
            console.error("Error uploading file:", err);
            setSnackbarSeverity('error');
            setSnackbarMessage('Failed to upload logo.');
            setSnackbarOpen(true);
        } finally {
            setUploading(false);
        }
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') return;
        setSnackbarOpen(false);
    };

    const renderColorOption = (group, field, label, allowNone = false) => {
        const color = formData.colors[group][field];
        return (
            <Box display="flex" alignItems="center" mb={1}>
                <ColorBox
                    bgcolor={color || 'transparent'}
                    onClick={(e) => handleColorBoxClick(e, group, field)}
                />
                <Typography variant="body2">
                    {label}
                    {color ? ` ${color}` : ' None'}
                </Typography>
            </Box>
        );
    };

    const fontOptions = [
        'Arial', 'Helvetica', 'Verdana', 'Trebuchet MS', 'Gill Sans',
        'Noto Sans', 'Avantgarde', 'Optima', 'Arial Narrow', 'sans-serif',
        'Times', 'Times New Roman', 'Didot', 'Georgia', 'Palatino',
        'Bookman', 'Comic Sans MS', 'Trebuchet MS', 'Arial Black', 'Impact'
    ];

    if (fetchStatus === 'loading') {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <CircularProgress />
            </Box>
        );
    }

    if (fetchStatus === 'failed') {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <Typography color="error">Error: {fetchError}</Typography>
            </Box>
        );
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' },
                minHeight: '100vh',
            }}
        >
            {/* Form Section */}
            <StyledPaper
                elevation={3}
                sx={{
                    flex: { xs: 1, md: 0.8 },
                    overflowY: 'auto',
                    borderRadius: '12px',
                    p: { xs: 2, md: 3 },
                }}
            >
                {/* Logo Upload Section */}
                <Typography sx={{ fontSize: '16px', fontWeight: '700' }} gutterBottom>
                    Logo
                </Typography>
                <Typography variant="caption" gutterBottom>
                    Recommended image size: 250px x 250px
                </Typography>
                <br />
                <label htmlFor="raised-button-file">
                    <input
                        accept="image/*"
                        style={{ display: 'none' }}
                        id="raised-button-file"
                        type="file"
                        onChange={handleLogoUpload}
                    />
                    <Button
                        variant="save"
                        component="span"
                        disabled={uploading || updateStatus === 'loading'}
                    >
                        {uploading ? <CircularProgress size={24} /> : 'Upload Logo'}
                    </Button>
                </label>

                {/* Main Text Section */}
                <Typography variant="h6" gutterBottom sx={{ fontSize: '16px', fontWeight: '700', mt: 4 }}>
                    Main Text
                </Typography>
                <TextField
                    fullWidth
                    variant="outlined"
                    name="mainText"
                    value={formData.mainText}
                    onChange={handleInputChange}
                    sx={{ mb: 2 }}
                />

                {/* Description Section */}
                <Typography gutterBottom sx={{ fontSize: '16px', fontWeight: '700', mt: 2 }}>
                    Description
                </Typography>
                <TextField
                    fullWidth
                    variant="outlined"
                    name="description"
                    value={formData.description}
                    onChange={handleInputChange}
                    sx={{ mb: 2 }}
                />

                {/* Typography Section */}
                <Typography gutterBottom sx={{ fontSize: '16px', fontWeight: '700', mt: 2 }}>
                    Typography
                </Typography>
                <FormControl fullWidth variant="outlined" sx={{ mb: 2 }}>
                    <InputLabel>Font</InputLabel>
                    <Select
                        value={formData.font}
                        onChange={handleInputChange}
                        label="Font"
                        name="font"
                    >
                        {fontOptions.map((font) => (
                            <MenuItem key={font} value={font}>
                                {font}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                {/* Company Information Section */}
                <Typography sx={{ fontSize: '16px', fontWeight: '700', mt: 2 }} gutterBottom>
                    Company Information
                    <Alert severity="info" sx={{ mt: 1, mb: 2 }}>
                        If you want to change your business details,{' '}
                        <Link
                            href="/settings/profile-settings#public-details"
                            underline="always"
                            sx={{ color: 'black', '&:hover': { color: 'black' }, fontWeight: 'bold' }}
                        >
                            click here
                        </Link>
                    </Alert>
                </Typography>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={formData.showCompanyName}
                                onChange={handleCheckboxChange}
                                name="showCompanyName"
                            />
                        }
                        label="Show Company Name"
                        sx={{ marginLeft: 0 }}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={formData.showCompanyAddress}
                                onChange={handleCheckboxChange}
                                name="showCompanyAddress"
                            />
                        }
                        label="Show Company Address"
                        sx={{ marginLeft: 0 }}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={formData.showBusinessWebsite}
                                onChange={handleCheckboxChange}
                                name="showBusinessWebsite"
                            />
                        }
                        label="Show Business Website"
                        sx={{ marginLeft: 0 }}
                    />
                </Box>

                {/* Colors Section */}
                <Typography sx={{ fontSize: '16px', fontWeight: '700', mt: 2 }} gutterBottom>
                    Colors
                </Typography>
                <Box mb={2}>
                    <Typography variant="subtitle1">Body</Typography>
                    {renderColorOption('body', 'text', 'Text')}
                    {renderColorOption('body', 'buttonsAndLinks', 'Buttons and links')}
                    {renderColorOption('body', 'buttonLabelsAndOutlines', 'Button labels and outlines')}
                </Box>
                <Box mb={2}>
                    <Typography variant="subtitle1">Background</Typography>
                    {renderColorOption('background', 'background1', 'Background 1')}
                    {renderColorOption('background', 'background2', 'Background 2')}
                    {renderColorOption('background', 'border', 'Border')}
                </Box>
                <Box mb={2}>
                    <Typography variant="subtitle1">Footer</Typography>
                    {renderColorOption('footer', 'textAndIcons', 'Text and icons', true)}
                    {renderColorOption('footer', 'background', 'Background', true)}
                </Box>

                {/* Other Texts Section */}
                <Typography sx={{ fontSize: '16px', fontWeight: '700', mt: 2 }} gutterBottom>
                    Other Texts
                </Typography>
                {[
                    { name: 'footerText', label: 'Footer Text' },
                    { name: 'paymentButton', label: 'Payment Button Text' },
                ].map(({ name, label }) => (
                    <TextField
                        key={name}
                        fullWidth
                        variant="outlined"
                        name={name}
                        label={label}
                        value={formData[name]}
                        onChange={handleInputChange}
                        sx={{ mb: 2 }}
                    />
                ))}

                {/* Save Button */}
                <Button
                    variant="save"
                    onClick={handleSave}
                    disabled={updateStatus === 'loading' || !isEditing}
                >
                    {updateStatus === 'loading' ? <CircularProgress size={24} /> : 'Save Changes'}
                </Button>
            </StyledPaper>

            {/* Preview Section */}
            <StyledPaper
                elevation={3}
                sx={{
                    flex: { xs: 1, md: 1 },
                    borderRadius: '12px',
                    mt: { xs: 3, md: 0 },
                    p: { xs: 2, md: 3 },
                }}
            >
                <Box
                    sx={{
                        bgcolor: formData.colors.background.background1,
                        p: 2,
                        mb: 6,
                        mt: 3,
                        borderRadius: '12px',
                        fontFamily: formData.font,
                    }}
                >
                    <Box sx={{ mb: 3, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        {typeof formData.logo === 'string' ? (
                            <img src={formData.logo} alt="Company Logo" style={{ maxWidth: '100px', height: 'auto' }} />
                        ) : (
                            formData.logo
                        )}
                        <Typography variant="h4" sx={{ color: formData.colors.body.text, fontFamily: 'inherit' }}>
                            {formData.mainText}
                        </Typography>
                    </Box>
                    <Box sx={{ backgroundColor: formData.colors.background.border, height: '1px', mx: 'auto', mb: 3 }} />

                    <Box sx={{ mb: 2 }}>
                        {formData.showCompanyName && (
                            <Typography variant="h6" sx={{ color: formData.colors.body.text, fontFamily: 'inherit' }}>
                                {formData.companyName}
                            </Typography>
                        )}
                        {formData.showCompanyAddress && (
                            <Typography variant="body2" sx={{ color: formData.colors.body.text, fontFamily: 'inherit' }}>
                                {formData.companyAddress}
                            </Typography>
                        )}
                        {formData.showBusinessWebsite && (
                            <Link
                                href={formData.businessWebsite}
                                target="_blank"
                                rel="noopener noreferrer"
                                sx={{
                                    color: formData.colors.body.buttonLabelsAndOutlines,
                                    fontFamily: 'inherit',
                                    textDecoration: 'none',
                                    '&:hover': {
                                        textDecoration: 'underline',
                                    },
                                }}
                            >
                                {formData.businessWebsite}
                            </Link>
                        )}
                    </Box>
                    <Typography variant="body1" sx={{ color: formData.colors.body.text, fontFamily: 'inherit', pb: 2 }}>
                        {formData.description}
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Typography variant="body2" sx={{ color: formData.colors.body.text, fontFamily: 'inherit' }}>
                                Receipt Number: {formData.receiptNumber}
                            </Typography>
                            <Typography variant="body2" sx={{ color: formData.colors.body.text, fontFamily: 'inherit' }}>
                                Receipt Date: {formData.receiptDate}
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="body2" sx={{ color: formData.colors.body.text, fontFamily: 'inherit' }}>
                                Customer: {formData.customerName}
                            </Typography>
                            <Typography variant="body2" sx={{ color: formData.colors.body.text, fontFamily: 'inherit' }}>
                                Email: {formData.customerEmail}
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>

                <Box
                    sx={{
                        bgcolor: formData.colors.background.background2,
                        p: 2,
                        mb: 6,
                        borderRadius: '12px',
                        fontFamily: formData.font,
                    }}
                >
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Description</TableCell>
                                    <TableCell align="right">Quantity</TableCell>
                                    <TableCell align="right">Price</TableCell>
                                    <TableCell align="right">Total</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {formData.items.map((item, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{item.description}</TableCell>
                                        <TableCell align="right">{item.quantity}</TableCell>
                                        <TableCell align="right">RM {item.price.toFixed(2)}</TableCell>
                                        <TableCell align="right">RM {(item.quantity * item.price).toFixed(2)}</TableCell>
                                    </TableRow>
                                ))}
                                <TableRow>
                                    <TableCell colSpan={2} />
                                    <TableCell align="right">Subtotal:</TableCell>
                                    <TableCell align="right">RM {formData.subtotal.toFixed(2)}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={2} />
                                    <TableCell align="right">Tax:</TableCell>
                                    <TableCell align="right">RM {formData.tax.toFixed(2)}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={2} />
                                    <TableCell align="right">
                                        <strong>Total:</strong>
                                    </TableCell>
                                    <TableCell align="right">
                                        <strong>RM {formData.total.toFixed(2)}</strong>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <Box
                        sx={{
                            bgcolor: formData.colors.footer.background || 'transparent',
                            color: formData.colors.footer.textAndIcons || formData.colors.body.text,
                            p: 2,
                            mt: 2,
                            textAlign: 'center',
                            fontFamily: formData.font,
                            borderRadius: '4px',
                        }}
                    >
                        <Typography variant="body1" sx={{ fontFamily: 'inherit' }}>
                            {formData.footerText}
                        </Typography>
                        <Button
                            variant="contained"
                            sx={{
                                mt: 2,
                                bgcolor: formData.colors.body.buttonsAndLinks,
                                color: formData.colors.body.buttonLabelsAndOutlines,
                                fontFamily: 'inherit',
                                '&:hover': {
                                    bgcolor: formData.colors.body.buttonsAndLinks,
                                    opacity: 0.9,
                                },
                            }}
                        >
                            {formData.paymentButton}
                        </Button>
                    </Box>
                </Box>
            </StyledPaper>

            {/* Color Picker Popover */}
            <Popover
                open={Boolean(colorPickerAnchor)}
                anchorEl={colorPickerAnchor}
                onClose={() => {
                    setColorPickerAnchor(null);
                    setActiveColorField(null);
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <ChromePicker
                    color={activeColorField ? formData.colors[activeColorField.group][activeColorField.field] : '#fff'}
                    onChange={handleColorChange}
                />
            </Popover>

            {/* Snackbar for notifications */}
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            >
                <Alert
                    onClose={handleSnackbarClose}
                    severity={snackbarSeverity}
                    sx={{ width: '100%' }}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default ReceiptTab;