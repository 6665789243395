import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { styled, alpha } from "@mui/material/styles";
import {
    Drawer,
    Box,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Toolbar,
    Collapse,
    useMediaQuery,
    useTheme,
    Typography,
    Menu,
    MenuItem,
    IconButton, Chip, Button, drawerClasses,
} from '@mui/material';
import {
    Dashboard as DashboardIcon,
    People as PeopleIcon,
    Receipt as ReceiptIcon,
    ExitToApp as ExitToAppIcon,
    ExpandLess,
    ExpandMore,
    AdminPanelSettings as AdminIcon,
    Storefront as StorefrontIcon,
    Assignment as AssignmentIcon,
    EventNote as EventNoteIcon,
    CalendarToday as CalendarTodayIcon,
    AccountBalanceWallet as AccountBalanceWalletIcon,
    KeyboardArrowDown as KeyboardArrowDownIcon,
    Payments as PaymentsIcon,
    AttachMoney as AttachMoneyIcon,
    Check as CheckIcon,
    RoomPreferences as RoomPreferencesIcon,
    PersonAddAlt1 as PersonAddAlt1Icon,
} from '@mui/icons-material';
import keycloak from "../keycloak";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import PublicIcon from "@mui/icons-material/Public";

const DRAWER_WIDTH = 250;
const ROLES = {
    MERCHANT: 'merchants',
    ADMIN: 'eventlah-admin',
    AGENT: 'eventlah-agent'
};

// Styled Components
const CustomListItemText = styled(ListItemText)(({ theme }) => ({
    '& .MuiTypography-root': {
        // color: 'var(--gray-400, #888)',
        color: 'var(--gray-400, #888)',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '18px',
    },
}));

const CustomListItemIcon = styled(ListItemIcon)(({ theme }) => ({
    '& .MuiSvgIcon-root': {
        color: 'var(--gray-400, #888)',
        fontSize: '20px',
    },
    minWidth: '40px',
}));

const StyledMenu = styled(Menu)(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[500],
        boxShadow: 'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            padding: '10px 16px',
            '& .MuiListItemIcon-root': {
                minWidth: 24,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
            '&.Mui-selected': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
                '&:hover': {
                    backgroundColor: alpha(
                        theme.palette.primary.main,
                        theme.palette.action.selectedOpacity + theme.palette.action.hoverOpacity,
                    ),
                },
            },
        },
    },
}));

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
    '&:hover': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.hoverOpacity),
    },
    transition: theme.transitions.create(['background-color'], {
        duration: theme.transitions.duration.shorter,
    }),
}));

// Helper Components
const LogoPlaceholder = ({ width = 32, height = 32, bgColor = '#f0f0f0', textColor = '#888' }) => (
    <div className="flex items-center justify-center" style={{ width, height }}>
        <svg
            width={width}
            height={height}
            viewBox={`0 0 ${width} ${height}`}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="w-full h-full rounded-md"
        >
            <rect width={width} height={height} fill={bgColor} className="rounded-md" />
            <text
                x="50%"
                y="50%"
                fontSize={width * 0.25} // Increased from 0.2 to 0.25 for better visibility
                fill={textColor}
                textAnchor="middle"
                dominantBaseline="middle"
                fontFamily="system-ui"
            >
                LOGO
            </text>
        </svg>
    </div>
);

const getRoleDisplay = (role) => {
    const roleMap = {
        [ROLES.MERCHANT]: 'Merchant',
        [ROLES.ADMIN]: 'Admin',
        [ROLES.AGENT]: 'Agent'
    };
    return roleMap[role] || role;
};

// Navigation Config
const MERCHANT_MENU_ITEMS = [
    { text: 'Dashboard', icon: <DashboardIcon />, link: '/' },
    { text: 'Landing Pages', icon: <PublicIcon />, link: 'https://pages.eventlah.com' },
    { text: 'Transactions', icon: <AccountBalanceWalletIcon />, link: '/settings/transaction-history' },
    { text: 'Products', icon: <StorefrontIcon />, link: '/settings/product-catalog' },
    { text: 'Orders', icon: <AssignmentIcon />, link: '/order-overview' },
    { text: 'Ticketing', icon: <EventNoteIcon />, link: '/ticketing-overview' },
    { text: 'Bookings', icon: <RoomPreferencesIcon />, link: '/booking-overview' },
    { text: 'Appointments', icon: <CalendarTodayIcon />, link: '/settings/appointments' },
    { text: 'Customers', icon: <PeopleIcon />, link: '/settings/customer-records' },
    { text: 'Agents', icon: <PeopleIcon />, link: '/agents-overview' },
    { text: 'Billings', icon: <ReceiptIcon />, link: '/settings/account-status#billing' },
];

const AGENT_MENU_ITEMS = [
    { text: 'Dashboard', icon: <DashboardIcon />, link: '/agent-dashboard' },
    { text: 'Profile', icon: <PersonAddAlt1Icon />, link: '/agent-profile' },
    { text: 'Commissions', icon: <AttachMoneyIcon />, link: '/agent-commissions' },
    { text: 'Payouts', icon: <PaymentsIcon />, link: '/agent-payouts' },
];

const ADMIN_SUB_MENU_ITEMS = [
    { text: 'Admin Dashboard', link: '/admin/admin-dashboard' },
    { text: 'Onboarding', link: '/admin/onboarding' },
    { text: 'Agreement Management', link: '/admin/agreement' },
    { text: 'Email Management', link: '/admin/email-management' },
    { text: 'Admin Subscriptions', link: '/admin/subscriptions' },
    { text: 'Admin Subscriptions Features', link: '/admin/subscription-features' },
];

// Component Definition
const AccountHeader = ({ storeName, currentRole, availableRoles, onRoleChange }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);
    const handleRoleSelect = (role) => {
        onRoleChange(role);
        handleClose();
    };

    const renderAccountStatus = (status) => {
        let color;
        let icon;
        let label;

        switch (status) {
            case 'verified':
                color = 'success';
                icon = <CheckCircleIcon />;
                label = 'Verified';
                break;
            case 'pending':
                color = 'warning';
                icon = <PendingIcon />;
                label = 'Pending';
                break;
            case 'suspended':
                color = 'error';
                icon = <BlockIcon />;
                label = 'Suspended';
                break;
            default:
                color = 'default';
                icon = <HelpOutlineIcon />;
                label = 'Unknown';
        }

        return (
            <Chip
                icon={icon}
                label={label}
                color={color}
                size="small"
                sx={{ fontSize: 10, fontWeight: 'bold' }}
            />
        );
    };

    return (
        <ListItem>
            <Box sx={{
                display: 'flex',
                alignItems: 'flex-start',
                width: '100%',
                mt: 1, // Reduced from 2
            }}>
                <Box sx={{
                    width: 40, // Reduced from 50
                    height: 40, // Reduced from 50
                    borderRadius: '4px', // Reduced from 6px
                    backgroundColor: '#f0f0f0',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    overflow: 'hidden',
                    mr: 1.5, // Reduced from 2
                }}>
                    <LogoPlaceholder width={40} height={40} /> {/* Reduced from 50 */}
                </Box>

                <Box sx={{ flex: 1 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Typography sx={{ fontWeight: '600', fontSize: '14px' }}>{storeName}</Typography> {/* Reduced from 16px */}
                    </Box>
                    <Typography sx={{ fontWeight: '500', fontSize: '12px' }}> {/* Reduced from 14px */}
                        Role: {getRoleDisplay(currentRole)}
                    </Typography>
                    {renderAccountStatus('verified')}
                </Box>

                {availableRoles.length > 1 && (
                    <Box sx={{ my: "auto" }}>
                        <IconButton
                            onClick={handleClick}
                            size="small"
                            sx={{
                                transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
                                transition: 'transform 0.2s ease-in-out',
                                padding: '4px', // Reduced padding
                            }}
                            aria-controls={open ? 'role-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                        >
                            <KeyboardArrowDownIcon fontSize="small" /> {/* Added fontSize="small" */}
                        </IconButton>
                    </Box>
                )}
            </Box>

            <StyledMenu
                id="role-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{ 'aria-labelledby': 'role-button' }}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                elevation={2}
            >
                {availableRoles.map((role) => (
                    <StyledMenuItem
                        key={role}
                        onClick={() => handleRoleSelect(role)}
                        selected={currentRole === role}
                        sx={{
                            display: 'flex',
                            alignItems: 'flex-start',
                            padding: 1.5, // Reduced from 2
                        }}
                    >
                        <Box sx={{
                            width: 32, // Reduced from 40
                            height: 32, // Reduced from 40
                            borderRadius: '4px', // Reduced from 6px
                            backgroundColor: '#f0f0f0',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            overflow: 'hidden',
                            mr: 1.5, // Reduced from 2
                        }}>
                            <LogoPlaceholder width={32} height={32} /> {/* Reduced from 40 */}
                        </Box>

                        <Box sx={{ flex: 1 }}>
                            <Typography sx={{ fontWeight: '600', fontSize: '12px' }}>{storeName}</Typography> {/* Reduced from 14px */}
                            <Typography sx={{ fontWeight: '500', fontSize: '10px', mt: '2px' }}> {/* Reduced from 12px */}
                                Role: {getRoleDisplay(role)}
                            </Typography>
                        </Box>

                        {currentRole === role && (
                            <CustomListItemIcon sx={{ minWidth: 'auto', marginLeft: 0.5 }}> {/* Reduced marginLeft */}
                                <CheckIcon color="primary" fontSize="small" />
                            </CustomListItemIcon>
                        )}
                    </StyledMenuItem>
                ))}
            </StyledMenu>
        </ListItem>
    );
};

const NavigationItem = ({ text, icon, link }) => (
    <ListItem button component={Link} to={link}>
        <CustomListItemIcon>{icon}</CustomListItemIcon>
        <CustomListItemText primary={text} />
    </ListItem>
);

const MerchantView = ({ storeName, currentRole, availableRoles, onRoleChange }) => (
    <Box>
        <AccountHeader
            storeName={storeName}
            currentRole={currentRole}
            availableRoles={availableRoles}
            onRoleChange={onRoleChange}
        />
        <List>
            {MERCHANT_MENU_ITEMS.map((item) => (
                <NavigationItem key={item.text} {...item} />
            ))}
        </List>
    </Box>
);

const AdminView = ({ currentRole, availableRoles, onRoleChange }) => {
    const [openAdmin, setOpenAdmin] = useState(false);

    return (
        <Box>
            <AccountHeader
                storeName="Admin Dashboard"
                currentRole={currentRole}
                availableRoles={availableRoles}
                onRoleChange={onRoleChange}
            />

            {openAdmin ? (
                <NavigationItem text="Dashboard" icon={<DashboardIcon />} link="/admin/admin-dashboard" />
            ) : (
                <NavigationItem text="Dashboard" icon={<DashboardIcon />} link="/" />
            )}

            <ListItem button onClick={() => setOpenAdmin(!openAdmin)}>
                <CustomListItemIcon><AdminIcon /></CustomListItemIcon>
                <CustomListItemText primary="Merchant Overview" />
                {openAdmin ? <ExpandLess /> : <ExpandMore />}
            </ListItem>

            <Collapse in={openAdmin} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {ADMIN_SUB_MENU_ITEMS.map((item) => (
                        <ListItem
                            key={item.text}
                            button
                            component={Link}
                            to={item.link}
                            sx={{ pl: 9 }}
                        >
                            <CustomListItemText primary={item.text} />
                        </ListItem>
                    ))}
                </List>
            </Collapse>
        </Box>
    );
};

const AgentView = ({ storeName, currentRole, availableRoles, onRoleChange }) => (
    <Box>
        <AccountHeader
            storeName={storeName}
            currentRole={currentRole}
            availableRoles={availableRoles}
            onRoleChange={onRoleChange}
        />
        <List>
            {AGENT_MENU_ITEMS.map((item) => (
                <NavigationItem key={item.text} {...item} />
            ))}
        </List>
    </Box>
);

const Sidebar = ({ isOpen, toggleSidebar }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const storeName = useSelector(state =>
        state.organizationProfiles?.profiles[0]?.data?.name || 'Merchant Store Name'
    );

    const availableRoles = [ROLES.MERCHANT, ROLES.ADMIN, ROLES.AGENT].filter(role =>
        role === ROLES.MERCHANT ? keycloak.hasRealmRole(ROLES.MERCHANT) :
            role === ROLES.ADMIN ? keycloak.hasRealmRole(ROLES.ADMIN) :
                keycloak.hasRealmRole(ROLES.AGENT)
    );

    const [currentRole, setCurrentRole] = useState(availableRoles[0]);

    const handleLogout = () => {
        keycloak.logout();
    };

    const handleRoleChange = (newRole) => {
        setCurrentRole(newRole);
    };

    const renderView = () => {
        switch (currentRole) {
            case ROLES.MERCHANT:
                return (
                    <MerchantView
                        storeName={storeName}
                        currentRole={currentRole}
                        availableRoles={availableRoles}
                        onRoleChange={handleRoleChange}
                    />
                );
            case ROLES.ADMIN:
                return (
                    <AdminView
                        currentRole={currentRole}
                        availableRoles={availableRoles}
                        onRoleChange={handleRoleChange}
                    />
                );
            case ROLES.AGENT:
                return (
                    <AgentView
                        storeName={storeName}
                        currentRole={currentRole}
                        availableRoles={availableRoles}
                        onRoleChange={handleRoleChange}
                    />
                );
            default:
                return null;
        }
    };

    return (
        <Drawer
            variant={isMobile ? "temporary" : "persistent"}
            anchor="left"
            open={isOpen}
            onClose={toggleSidebar}
            sx={{
                width: DRAWER_WIDTH,
                flexShrink: 0,
                [`& .MuiDrawer-paper`]: {
                    width: DRAWER_WIDTH,
                    boxSizing: 'border-box'
                },
            }}
            // sx={{
            //     [`& .${drawerClasses.paper}`]: {
            //         pt: 2.5,
            //         px: 2.5,
            //         overflow: 'unset',
            //         bgcolor: 'var(--layout-nav-bg)',
            //         width: isMobile
            //             ? 'var(--layout-nav-mobile-width)'
            //             : 'var(--layout-nav-vertical-width)',
            //         [theme.breakpoints.up('sm')]: {
            //             width: 'var(--layout-nav-vertical-width)',
            //         },
            //     },
            // }}
        >
            <Toolbar />
            <Box sx={{ overflow: 'auto' }}>
                {renderView()}
                <ListItem button onClick={handleLogout}>
                    <CustomListItemIcon>
                        <ExitToAppIcon />
                    </CustomListItemIcon>
                    <CustomListItemText primary="Logout" />
                </ListItem>
            </Box>
        </Drawer>
    );
};

export default Sidebar;