import React, { useState } from 'react';
import {
    Box,
    IconButton,
    Paper,
    Typography,
    TextField,
    Divider
} from '@mui/material';
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { ViewIcon } from "../../../shared/IconButtons";
import { Delete as DeleteIcon, Edit as EditIcon } from "@mui/icons-material";

const rows = [
    { id: 1, name: 'iftar2023_senior_promo', pricing: 'RM59.99', daysBeforeEvent: 10 },
    { id: 2, name: 'iftar2023_child_promo', pricing: 'RM49.99', daysBeforeEvent: 7 },
    { id: 3, name: 'iftar2023_adult_promo', pricing: 'RM89.99', daysBeforeEvent: 15 },
    { id: 4, name: 'iftar2023_child_EB', pricing: 'RM39.99', daysBeforeEvent: 12 },
    { id: 5, name: 'iftar2023_Adult_EB', pricing: 'RM79.99', daysBeforeEvent: 14 }
];

const columns = [
    {
        field: 'name',
        headerName: 'Name',
        flex: 1,
        renderCell: (params) => (
            <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                <Typography variant="body2">{params.value}</Typography>
            </Box>
        )
    },
    {
        field: 'pricing',
        headerName: 'Pricing',
        flex: 0.5,
        renderCell: (params) => (
            <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                <Typography variant="body2">{params.value}</Typography>
            </Box>
        )
    },
    {
        field: 'daysBeforeEvent',
        headerName: 'Days Before Event',
        flex: 1,
        renderCell: (params) => (
            <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                <Typography variant="body2">{params.value}</Typography>
            </Box>
        )
    },
    {
        field: 'actions',
        headerName: '',
        flex: 0.7,
        renderCell: (params) => (
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', height: '100%', width: '100%' }}>
                <IconButton aria-label="view" onClick={() => console.log('view', params.row)}>
                    <ViewIcon />
                </IconButton>
                <IconButton aria-label="edit" onClick={() => console.log('edit', params.row)}>
                    <EditIcon />
                </IconButton>
                <IconButton aria-label="delete" onClick={() => console.log('delete', params.row.id)}>
                    <DeleteIcon />
                </IconButton>
            </Box>
        ),
    },
];

const Incoming = () => {
    const [searchQuery, setSearchQuery] = useState('');
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 10,
    });

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
        setPaginationModel({ ...paginationModel, page: 0 });
    };

    const handlePaginationModelChange = (newModel) => {
        setPaginationModel(newModel);
    };

    return (
        <Box>
            <Box flex={1}>
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                    <Box />
                    <Box display="flex" gap="12px">
                        <TextField
                            variant="outlined"
                            placeholder="Search by Name"
                            value={searchQuery}
                            onChange={handleSearchChange}
                            size="small"
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    height: '34px',
                                    '& input': {
                                        height: '34px',
                                        padding: '0 14px',
                                    },
                                    '& .MuiInputBase-input': {
                                        height: '34px',
                                        padding: '0 14px',
                                    }
                                }
                            }}
                        />
                    </Box>
                </Box>

                <Paper
                    sx={{
                        width: "100%",
                        borderRadius: "16px",
                        marginTop: '24px',
                        border: "1px solid var(--Gray-200, #EAECF0)",
                        background: "var(--White, #FFF)",
                        boxShadow: "0px 1px 3px 0px rgba(16, 24, 40, 0.10), 0px 1px 2px 0px rgba(16, 24, 40, 0.06)",
                    }}
                >
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '20px 24px' }}>
                        <Typography
                            sx={{
                                color: "var(--Gray-900, #101828)",
                                fontFamily: "Inter",
                                fontSize: "18px",
                                fontStyle: "normal",
                                fontWeight: 500,
                                lineHeight: "28px",
                            }}
                        >
                            All Incoming Appointments
                        </Typography>
                    </Box>

                    <Divider sx={{ my: 0 }} />

                    <DataGrid
                        rows={rows}
                        columns={columns}
                        paginationModel={paginationModel}
                        onPaginationModelChange={handlePaginationModelChange}
                        slots={{
                            toolbar: GridToolbar
                        }}
                        disableRowSelectionOnClick
                        sx={{
                            '& .MuiDataGrid-columnHeaders': {
                                backgroundColor: '#F9FAFB',
                            }
                        }}
                    />
                </Paper>
            </Box>
        </Box>
    );
};

export default Incoming;