import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import { useDispatch } from 'react-redux';
import { createEvent, updateEvent } from '../../../redux/eventsSlice';
import {
    Box,
    Button,
    Divider,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Select,
    Switch,
    TextField,
    Typography,
    Snackbar,
    Alert,
    CircularProgress, Radio, RadioGroup,
} from "@mui/material";
import { styled } from "@mui/system";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {isAfter, isBefore, isWeekend} from 'date-fns';
import StartEndDate from "./AddEvent/StartEndDate";
import AmenitySettings
    from "../../settings/product-settings/BookingSetup/add-new-booking/BookingDetails/AmenitySettings";

const TextFieldTitle = styled(Typography)(({ theme }) => ({
    color: 'var(--Gray-700, #344054)',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '20px',
    width: '312px',
}));

const AddTicketingForm = ({ editEvent, onEventCreated }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [eventData, setEventData] = useState({
        name: '',
        description: '',
        event_start_date: '',
        event_end_date: '',
        event_type_id: 5,
        status: true,
        minimum_pax: '',
        maximum_pax: '',
        daily_events: false,
    });

    // Error state
    const [errors, setErrors] = useState({
        name: '',
        event_type_id: 5,
        event_start_date: '',
        event_end_date: '',
        minimum_pax: '',
        maximum_pax: '',
        schedule_start: '',
        schedule_end: '',
    });

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [loading, setLoading] = useState(false);
    const [touched, setTouched] = useState({});

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [scheduleStart, setScheduleStart] = useState(null);
    const [scheduleEnd, setScheduleEnd] = useState(null);
    const [weekendsOnly, setWeekendsOnly] = useState(false);
    const [useDisabledDays, setUseDisabledDays] = useState(false);
    const [disabledDays, setDisabledDays] = useState({
        0: false, // Sunday
        1: true,  // Monday
        2: false, // Tuesday
        3: true,  // Wednesday
        4: false, // Thursday
        5: true,  // Friday
        6: false  // Saturday
    });

    // When setting data from API or editEvent prop
    useEffect(() => {
        if (editEvent) {
            setEventData(prev => ({
                ...editEvent,
                daily_events: editEvent.daily_events === true || editEvent.daily_events === "true", // Convert to boolean
            }));
        }
    }, [editEvent]);

    // Validation functions
    const validateField = (name, value) => {
        let error = '';
        switch (name) {
            case 'name':
                if (!value.trim()) {
                    error = 'Event name is required';
                } else if (value.length < 3) {
                    error = 'Event name must be at least 3 characters';
                } else if (value.length > 100) {
                    error = 'Event name must not exceed 100 characters';
                }
                break;

            case 'event_type_id':
                if (!value) {
                    error = 'Event type is required';
                }
                break;

            case 'minimum_pax':
                if (value === '') {
                    error = 'Minimum capacity is required';
                } else if (isNaN(value) || value < 1) {
                    error = 'Minimum capacity must be at least 1';
                } else if (eventData.maximum_pax && Number(value) > Number(eventData.maximum_pax)) {
                    error = 'Minimum capacity cannot be greater than maximum capacity';
                }
                break;

            case 'maximum_pax':
                if (value === '') {
                    error = 'Maximum capacity is required';
                } else if (isNaN(value) || value < 1) {
                    error = 'Maximum capacity must be at least 1';
                } else if (eventData.minimum_pax && Number(value) < Number(eventData.minimum_pax)) {
                    error = 'Maximum capacity cannot be less than minimum capacity';
                }
                break;

            // case 'schedule_start':
            //     if (!value) {
            //         error = 'Start date is required';
            //     } else {
            //         const startDate = new Date(value);
            //         const today = new Date();
            //         today.setHours(0, 0, 0, 0);
            //
            //         if (startDate < today) {
            //             error = 'Start date cannot be in the past';
            //         } else if (eventData.schedule_end && isAfter(startDate, new Date(eventData.schedule_end))) {
            //             error = 'Start date cannot be after end date';
            //         } else if (eventData.event_start_date && isAfter(startDate, new Date(eventData.event_start_date))) {
            //             error = 'Schedule start must be before event start';
            //         }
            //     }
            //     break;
            //
            // case 'schedule_end':
            //     if (!value) {
            //         error = 'End date is required';
            //     } else {
            //         const endDate = new Date(value);
            //         const startDate = eventData.schedule_start ? new Date(eventData.schedule_start) : null;
            //
            //         if (startDate && isBefore(endDate, startDate)) {
            //             error = 'End date cannot be before start date';
            //         } else if (eventData.event_end_date && isAfter(endDate, new Date(eventData.event_end_date))) {
            //             error = 'Schedule end must be before event end';
            //         }
            //     }
            //     break;

            case 'event_start_date':
                if (!value) {
                    error = 'Event start date is required';
                } else if (eventData.event_end_date && isAfter(new Date(value), new Date(eventData.event_end_date))) {
                    error = 'Event start date cannot be after event end date';
                }
                break;

            case 'event_end_date':
                if (!value) {
                    error = 'Event end date is required';
                } else if (eventData.event_start_date && isBefore(new Date(value), new Date(eventData.event_start_date))) {
                    error = 'Event end date cannot be before event start date';
                }
                break;

            default:
                break;
        }
        return error;
    };

    const validateAllFields = () => {
        const newErrors = {};
        Object.keys(eventData).forEach(field => {
            newErrors[field] = validateField(field, eventData[field]);
        });
        setErrors(newErrors);
        return !Object.values(newErrors).some(error => error);
    };


    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setEventData(prev => ({
            ...prev,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    // Add a new function to handle date changes
    const handleDateChange = (name) => (date) => {
        setEventData(prev => ({
            ...prev,
            [name]: date ? date.toISOString().split('T')[0] : null
        }));
    };

    const handleBlur = (fieldName) => {
        setTouched(prev => ({
            ...prev,
            [fieldName]: true
        }));
        setErrors(prev => ({
            ...prev,
            [fieldName]: validateField(fieldName, eventData[fieldName])
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        try {
            if (!editEvent) {
                console.log('Current eventData:', eventData);
                console.log('Start Date:', eventData.event_start_date);
                console.log('End Date:', eventData.event_end_date);

                const requiredFields = ['name', 'event_start_date', 'event_end_date'];
                const emptyFields = requiredFields.filter(field => {
                    const isEmpty = !eventData[field];
                    console.log(`Field ${field} is ${isEmpty ? 'empty' : 'filled'}`);
                    return isEmpty;
                });

                if (emptyFields.length > 0) {
                    throw new Error(`Please fill in all required fields: ${emptyFields.join(', ')}`);
                }
            }

            const submissionData = {
                ...eventData,
                amenities: eventData.amenities,
                event_start_date: eventData.event_start_date,
                event_end_date: eventData.event_end_date,
                schedule_start: eventData.schedule_start,
                schedule_end: eventData.schedule_end,
                weekends_only: weekendsOnly,
                use_disabled_days: useDisabledDays,
                disabled_days: disabledDays,
                is_daily_events: eventData.is_daily_events === true, // Ensure it's a boolean
            };

            console.log('Submission data:', submissionData);
            console.log('Daily Events:', submissionData.daily_events);

            let resultAction;
            if (editEvent) {
                resultAction = await dispatch(updateEvent(submissionData));
            } else {
                resultAction = await dispatch(createEvent(submissionData));
            }

            if ((editEvent ? updateEvent.fulfilled : createEvent.fulfilled).match(resultAction)) {
                setSnackbarMessage(editEvent ? "Event updated successfully!" : "Event created successfully!");
                setSnackbarSeverity("success");
                setSnackbarOpen(true);
                // Call the callback with the new event data

                if (onEventCreated) {
                    onEventCreated(resultAction.payload);  // Assuming the payload contains the created/updated event data
                }

                // Navigate to the ticketing setup page
                navigate('/settings/ticketing-setup');

            } else {
                throw new Error(resultAction.error.message || `Failed to ${editEvent ? 'update' : 'create'} event`);
            }
        } catch (error) {
            setSnackbarMessage(error.message || `Failed to ${editEvent ? 'update' : 'create'} event`);
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
        } finally {
            setLoading(false);
        }
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    const handleWeekendToggle = (event) => {
        setWeekendsOnly(event.target.checked);
        setStartDate(null);
        setEndDate(null);
    };

    const handleDisabledDaysToggle = (event) => {
        setUseDisabledDays(event.target.checked);
        setStartDate(null);
        setEndDate(null);
    };

    const handleDayToggle = (day) => (event) => {
        setDisabledDays(prev => ({ ...prev, [day]: event.target.checked }));
        setStartDate(null);
        setEndDate(null);
    };

    const shouldDisableDate = (date) => {
        if (weekendsOnly && !isWeekend(date)) {
            return true;
        }
        if (useDisabledDays) {
            return disabledDays[date.getDay()];
        }
        return false;
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        const updatedData = {
            ...eventData,
            [name]: value
        };
        setEventData(updatedData);
        setEventData?.(updatedData);
    };

    return (
        <Box>
            <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                {/* Event Name Field */}
                <Box sx={{ display: 'flex', gap: '10px', marginY: 2 }}>
                    <TextFieldTitle>Event Name</TextFieldTitle>
                    <TextField
                        name="name"
                        value={eventData.name || ''}
                        onChange={handleChange}
                        fullWidth
                        placeholder='Event name'
                        sx={{paddingRight: { md: '50px', lg: '240px' }}}
                    />
                </Box>

                <Divider />

                {/* Event Type Field */}
                <Box sx={{ display: 'none' }}> {/* Hide the entire box */}
                    <TextFieldTitle>Event Type</TextFieldTitle>
                    <FormControl fullWidth sx={{ paddingRight: { md: '50px', lg: '240px' } }}>
                        <RadioGroup
                            name="event_type_id"
                            value="5" // Set the default value to 5
                            onChange={handleChange}
                            row
                        >
                            <FormControlLabel value="5" control={<Radio />} label="Buffet" />
                            <FormControlLabel value="6" control={<Radio />} label="Birthday" />
                            <FormControlLabel value="7" control={<Radio />} label="Wedding" />
                            <FormControlLabel value="9" control={<Radio />} label="Team Building" />
                        </RadioGroup>
                    </FormControl>
                </Box>

                {/* Description Field */}
                <Box sx={{ display: 'flex', gap: '10px', marginY: 2 }}>
                    <TextFieldTitle>Description</TextFieldTitle>
                    <TextField
                        name="description"
                        value={eventData.description || ''}
                        onChange={handleChange}
                        fullWidth
                        multiline
                        rows={4}
                        placeholder="Enter event description"
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                padding: '15px',
                                '& .MuiOutlinedInput-input': {
                                    padding: 0,
                                },
                            },
                            paddingRight: { md: '50px', lg: '240px' }
                        }}
                    />
                </Box>

                <Divider />

                {/*amenities*/}
                <AmenitySettings
                    amenities={eventData.amenities}
                    onInputChange={handleInputChange}
                />

                <Divider />

                {/* Start Date and End Date Fields */}
                <StartEndDate
                    startDate={eventData.event_start_date ? new Date(eventData.event_start_date) : null}
                    endDate={eventData.event_end_date ? new Date(eventData.event_end_date) : null}
                    onStartDateChange={(date) => handleDateChange('event_start_date')(date)}
                    onEndDateChange={(date) => handleDateChange('event_end_date')(date)}
                    onDailyEventsChange={(isDailyEvents) => setEventData(prev => ({ ...prev, is_daily_events: isDailyEvents }))}
                    isDailyEvents={eventData.is_daily_events === true}
                />

                <Divider />

                {/* Min-Max Capacity Fields */}
                <Box sx={{ display: 'flex', gap: '10px', marginY: 2 }}>
                    <TextFieldTitle>Min - Max Capacity(Pax) per day</TextFieldTitle>
                    <Box sx={{ display: 'flex', gap: '20px', width: '100%', paddingRight: { md: '50px', lg: '240px'} }}>
                        <TextField
                            type="number"
                            name="minimum_pax"
                            value={eventData.minimum_pax || ''}
                            onChange={handleChange}
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                            sx={{ flex: 1 }}
                        />
                        <TextField
                            type="number"
                            name="maximum_pax"
                            value={eventData.maximum_pax || ''}
                            onChange={handleChange}
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                            sx={{ flex: 1 }}
                        />
                        <Box sx={{ flex: 2 }}/>
                    </Box>
                </Box>

                <Divider />

                {/* Schedule of live sales */}
                <Box sx={{ display: 'flex', gap: '10px', marginY: 2 }}>
                    <TextFieldTitle>Schedule of live sales *</TextFieldTitle>
                    <Box sx={{ display: 'flex', gap: '20px', width: '100%', paddingRight: { md: '50px', lg: '240px'} }}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                label="Start Date"
                                value={eventData.schedule_start ? new Date(eventData.schedule_start) : null}
                                onChange={handleDateChange('schedule_start')}
                                onClose={() => handleBlur('schedule_start')}
                                format="dd/MM/yyyy"
                                shouldDisableDate={(date) => {
                                    const eventStartDate = eventData.event_start_date ? new Date(eventData.event_start_date) : null;
                                    const eventEndDate = eventData.event_end_date ? new Date(eventData.event_end_date) : null;
                                    const today = new Date();
                                    today.setHours(0, 0, 0, 0);

                                    // Disable dates before today
                                    if (date < today) return true;

                                    // If event start date is set, disable dates after it
                                    if (eventStartDate && date > eventStartDate) return true;

                                    // If event end date is set, disable dates after it
                                    if (eventEndDate && date > eventEndDate) return true;

                                    return false;
                                }}
                                slotProps={{
                                    textField: {
                                        error: touched.schedule_start && !!errors.schedule_start,
                                        helperText: touched.schedule_start && errors.schedule_start,
                                        fullWidth: true,
                                    },
                                    day: {
                                        sx: {
                                            "&.Mui-selected": {
                                                backgroundColor: "primary.main",
                                            }
                                        }
                                    }
                                }}
                                sx={{ flex: 1 }}
                            />
                            <DatePicker
                                label="End Date"
                                value={eventData.schedule_end ? new Date(eventData.schedule_end) : null}
                                onChange={handleDateChange('schedule_end')}
                                onClose={() => handleBlur('schedule_end')}
                                format="dd/MM/yyyy"
                                minDate={eventData.schedule_start ? new Date(eventData.schedule_start) : new Date()}
                                disabled={!eventData.schedule_start}
                                shouldDisableDate={(date) => {
                                    const scheduleStart = eventData.schedule_start ? new Date(eventData.schedule_start) : null;
                                    const eventEndDate = eventData.event_end_date ? new Date(eventData.event_end_date) : null;

                                    if (!scheduleStart) return true;

                                    // Disable dates before schedule start
                                    if (date < scheduleStart) return true;

                                    // If event end date is set, disable dates after it
                                    if (eventEndDate) {
                                        return date > eventEndDate;
                                    }

                                    return false;
                                }}
                                slotProps={{
                                    textField: {
                                        error: touched.schedule_end && !!errors.schedule_end,
                                        helperText: touched.schedule_end && errors.schedule_end,
                                        fullWidth: true,
                                    },
                                    day: {
                                        sx: {
                                            "&.Mui-selected": {
                                                backgroundColor: "primary.main",
                                            }
                                        }
                                    }
                                }}
                                sx={{ flex: 1 }}
                            />
                            <Box sx={{ flex: 2 }}/>
                        </LocalizationProvider>
                    </Box>
                </Box>

                <Divider/>

                <Box sx={{ display: 'flex', justifyContent: "flex-end", gap: '8px' }}>
                    <Button variant='cancel' type="button">Cancel</Button>
                    <Button variant='save' type="submit" disabled={loading}>
                        {loading ? <CircularProgress size={24} /> : (editEvent ? 'Update' : 'Save')}
                    </Button>
                </Box>
            </form>

            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={snackbarOpen}
                autoHideDuration={5000}
                onClose={handleSnackbarClose}
            >
                <Alert
                    onClose={handleSnackbarClose}
                    severity={snackbarSeverity}
                    sx={{ width: '100%' }}
                    iconMapping={{
                        success: <CheckCircleOutlineIcon />,
                        error: <ErrorOutlineIcon />,
                    }}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default AddTicketingForm;