import React, { useState, useEffect } from 'react';
import {
    Box,
    Typography,
    Card,
    Paper,
    TextField,
    Grid,
    Button,
    IconButton,
    Tooltip,
    Divider,
    Skeleton,
} from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ShareIcon from '@mui/icons-material/Share';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import { Chart, useChart } from '../../material-kit/chart';
import { useTheme } from '@mui/material';
import { alpha as hexAlpha } from '@mui/material/styles';
import AnalyticsWidgetSummary from "../../shared/AnalyticsWidgetSummary";
import { CalendarToday as CalendarTodayIcon, Pending as PendingIcon, Paid as PaidIcon } from '@mui/icons-material';

const AgentDashboard = () => {
    const [loading, setLoading] = useState(true); // Add loading state
    const [bookings, setBookings] = useState([]); // Move bookings state here

    // Simulating data loading
    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 4000); // simulate 4 seconds delay
    }, []);

    const accumulativeSalesData = [
        { sale_date: '2024-10-01', total_sales: 1000.00 },
        { sale_date: '2024-10-05', total_sales: 1200.00 },
        { sale_date: '2024-10-10', total_sales: 1500.00 },
        { sale_date: '2024-10-12', total_sales: 1800.00 },
        { sale_date: '2024-10-15', total_sales: 1900.00 },
        { sale_date: '2024-10-18', total_sales: 2100.00 },
        { sale_date: '2024-10-20', total_sales: 2500.00 },
        { sale_date: '2024-10-25', total_sales: 3000.00 },
        { sale_date: '2024-10-28', total_sales: 4000.00 },
        { sale_date: '2024-10-30', total_sales: 5000.00 },
    ];

    const salesThisWeekData = [
        { sale_date: '2024-10-15', total_sales: 200.00 },
        { sale_date: '2024-10-16', total_sales: 250.00 },
        { sale_date: '2024-10-17', total_sales: 300.00 },
        { sale_date: '2024-10-18', total_sales: 350.00 },
        { sale_date: '2024-10-19', total_sales: 400.00 },
        { sale_date: '2024-10-20', total_sales: 450.00 },
        { sale_date: '2024-10-21', total_sales: 500.00 },
        { sale_date: '2024-10-22', total_sales: 550.00 },
        { sale_date: '2024-10-23', total_sales: 600.00 },
        { sale_date: '2024-10-24', total_sales: 650.00 },
    ];

    const timeRangeAccumulative = 'daily';
    const timeRangeWeekly = 'daily';

    // Booking data (Example data)
    const exampleBookings = [
        {
            id: 1,
            event_date: '2024-12-01',
            purchase_date: '2024-11-15',
            total_quantity: 5,
            total_price: 250,
            customer_name: 'John Doe',
            email: 'john.doe@example.com',
            phone_no: '0123456789',
            status: 1,
            notes: 'Looking forward to the event!'
        },
        {
            id: 2,
            event_date: '2024-12-05',
            purchase_date: '2024-11-20',
            total_quantity: 3,
            total_price: 150,
            customer_name: 'Jane Smith',
            email: 'jane.smith@example.com',
            phone_no: '0987654321',
            status: 0,
            notes: 'Please call if anything changes.'
        }
    ];

    const columns = [
        { field: 'customer_name', headerName: 'Name', flex: 2, minWidth: 150 },
        { field: 'email', headerName: 'Email', flex: 3, minWidth: 200 },
        { field: 'total_quantity', headerName: 'No of Pax', flex: 1, minWidth: 120 },
        { field: 'total_price', headerName: 'Total Price', flex: 1, minWidth: 160 },
        { field: 'phone_no', headerName: 'Phone No', flex: 2, minWidth: 150 },
        {
            field: 'status',
            headerName: 'Status',
            flex: 2,
            minWidth: 150,
            valueFormatter: (params) => params.value === 1 ? 'Paid' : 'Pending payment'
        },
        { field: 'notes', headerName: 'Customer Notes', flex: 3, minWidth: 200 }
    ];

    const [searchQuery, setSearchQuery] = useState('');
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 10
    });

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
        setPaginationModel({ ...paginationModel, page: 0 });
    };

    const handlePaginationModelChange = (newModel) => {
        setPaginationModel(newModel);
    };

    const agentCode = 'AGENT123';
    const allCount = 10;
    const pendingPaymentCount = 3;
    const paidBookingCount = 7;
    const bookingStats = [
        {
            title: 'All Booking',
            count: allCount,
            borderColor: '#12B76A',
            backgroundColor: '#ECFDF3',
        },
        {
            title: 'Pending Payment',
            count: pendingPaymentCount,
            borderColor: '#FFED00',
            backgroundColor: '#FDFFE7',
        },
        {
            title: 'Paid Booking',
            count: paidBookingCount,
            borderColor: '#1999E3',
            backgroundColor: '#F1FAFF',
        }
    ];

    const copyToClipboard = () => {
        const referralLink = `https://www.orchardvilla.com.my/iftar2024/?promo=${agentCode}`;
        navigator.clipboard.writeText(referralLink);
    };

    const theme = useTheme();

    const salesChart = {
        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'],
        series: [
            {
                name: 'Accumulative Sales',
                data: [1000, 1200, 1500, 1800, 1900, 2100, 2500, 3000, 5000]
            },
            {
                name: 'Weekly Sales',
                data: [200, 250, 300, 350, 400, 450, 500, 550, 650]
            }
        ],
    };

    const chartColors = [
        theme.palette.primary.dark,
        hexAlpha(theme.palette.primary.light, 0.64),
    ];

    const chartOptions = useChart({
        colors: chartColors,
        stroke: {
            width: 2,
            colors: ['transparent'],
        },
        xaxis: {
            categories: salesChart.categories,
        },
        legend: {
            show: true,
        },
        tooltip: {
            y: {
                formatter: (value) => `RM ${value}`,
            },
        },
    });

    return (
        <Box sx={{
            padding: '32px',
            maxWidth:'96%',
            // marginLeft: { sm: '250px' },  // Match sidebar width
            // width: {
            //     xs: '100%',
            //     sm: `calc(100% - 250px)`  // Subtract sidebar width
            // },
            // transition: 'margin-left 0.2s ease-in-out, width 0.2s ease-in-out',
        }}>
            {/* Referral Section */}
            <Card sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                margin: '0 auto',    // Center the card
                borderRadius: '8px',
                padding: '32px',
            }}>
                <Box sx={{ textAlign: 'center', my: 3, width: '100%' }}>
                    <Typography variant="h6">Your Agent Referral Program</Typography>
                    <Paper elevation={0} sx={{ bgcolor: 'grey.50', py: 2, px: 3, display: 'inline-block', borderRadius: '8px' }}>
                        {loading ? <Skeleton variant="text" width={100} height={40} /> : <Typography sx={{ fontWeight: '800', fontSize: '28px' }}>{agentCode}</Typography>}
                    </Paper>
                </Box>

                <Box sx={{ textAlign: 'center', mb: 3 }}>
                    <EmojiEventsIcon sx={{ fontSize: 40, color: 'primary.main', mb: 1 }} />
                    <Typography variant="h6" gutterBottom>Earn While You Share!</Typography>
                    <Typography variant="body2" color="text.secondary">Share your unique link and earn commission for each successful booking.</Typography>
                </Box>

                <Box sx={{ textAlign: 'center', my: 3, width: '100%' }}> {/* Added width: '100%' */}
                    <Typography variant="subtitle2">Promotional Video</Typography>
                    {loading ? (
                        <Skeleton
                            variant="rectangular"
                            width="550px"  // Changed from 70% to match iframe's maxWidth
                            height={300}
                            sx={{
                                margin: '0 auto',
                                borderRadius: '8px',
                                maxWidth: '100%' // Add this to ensure it's responsive like the iframe
                            }}
                        />
                    ) : (
                        <iframe
                            width="100%"
                            height="300"
                            src="https://www.youtube.com/embed/U85Ovt8142s?si=ii9D0aCSga4ep7Ig"
                            title="Promotional Video"
                            frameBorder="0"
                            allowFullScreen
                            style={{
                                maxWidth: '550px',
                                width: '100%',
                                margin: '0 auto',
                                borderRadius: '8px',
                                border: '1px solid #EAECF0'
                            }}
                        />
                    )}
                </Box>

                <Divider sx={{ my: 3 }} />

                <Box sx={{ mb: 3 }}>
                    <Typography variant="subtitle2">Your Referral Link</Typography>
                    <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                        {loading ? (
                            <Skeleton variant="rectangular" width="100%" height={40} />
                        ) : (
                            <TextField
                                fullWidth
                                size="small"
                                value={`https://www.orchardvilla.com.my/iftar2024/?promo=${agentCode}`}
                                InputProps={{ readOnly: true }}
                                sx={{ bgcolor: 'grey.50', '& .MuiOutlinedInput-root': { height: '40px' } }}
                            />
                        )}
                        {loading ? (
                            <Skeleton variant="circular" width={40} height={40} />
                        ) : (
                            <Tooltip title="Copy link">
                                <IconButton onClick={copyToClipboard} sx={{ border: 1, borderColor: 'divider', borderRadius: '8px' }}>
                                    <ContentCopyIcon />
                                </IconButton>
                            </Tooltip>
                        )}
                    </Box>
                </Box>

                <Button variant="contained" color="primary" fullWidth size="large" startIcon={<ShareIcon />} sx={{ py: 1.5 }} disabled={loading}>
                    Share Now
                </Button>
            </Card>

            <Divider sx={{ my: 10 }} />

            {/* Booking Stats */}
            <Typography variant="h5" sx={{ mb: 2 }}>
                Booking Stats
            </Typography>
            <Grid container spacing={3} sx={{ mb: 3 }}>
                <Grid item xs={12} sm={6} md={4}>
                    <AnalyticsWidgetSummary
                        title="All Bookings"
                        percent={2.5}
                        total={3842}
                        icon={<CalendarTodayIcon sx={{ width: 32, height: 32, color: '#2196f3' }} />}
                        chart={{
                            categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug'],
                            series: [2500, 3000, 2800, 3200, 3400, 3600, 3500, 3842],
                        }}
                    />
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                    <AnalyticsWidgetSummary
                        title="Pending Payment"
                        percent={-1.2}
                        total={547}
                        color="warning"
                        icon={<PendingIcon sx={{ width: 32, height: 32, color: 'warning.main' }} />}
                        chart={{
                            categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug'],
                            series: [400, 300, 450, 500, 480, 520, 550, 547],
                        }}
                    />
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                    <AnalyticsWidgetSummary
                        title="Paid Bookings"
                        percent={4.8}
                        total={3295}
                        color="success"
                        icon={<PaidIcon sx={{ width: 32, height: 32, color: 'success.main' }} />}
                        chart={{
                            categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug'],
                            series: [2100, 2700, 2350, 2700, 2920, 3080, 2950, 3295],
                        }}
                    />
                </Grid>
            </Grid>

            {/* Sales */}
            <Grid container spacing={3} sx={{mt:2}}>
                <Grid item xs={12}>
                    <Typography variant="h5" sx={{ mb: 2 }}>
                        Sales Overview
                    </Typography>
                    <Card>
                        {loading ? (
                            <Skeleton variant="rectangular" width="100%" height={400} sx={{ borderRadius: '8px' }} />
                        ) : (
                            <>
                                <Box sx={{ width: '100%', p: 3, pb: 1 }}>
                                    <Chart
                                        type="bar"
                                        series={salesChart.series}
                                        options={{
                                            ...chartOptions,
                                            chart: {
                                                ...chartOptions.chart,
                                                width: '100%',
                                                parentHeightOffset: 0
                                            }
                                        }}
                                        height={364}
                                        width="100%"
                                    />
                                </Box>
                            </>
                        )}
                    </Card>
                </Grid>
            </Grid>

            <Divider sx={{ my: 10 }} />

            {/* Table Section */}
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Box display="flex" width="100%" justifyContent="flex-end">
                    <TextField
                        variant="outlined"
                        placeholder="Search bookings"
                        value={searchQuery}
                        onChange={handleSearchChange}
                        size="small"
                        sx={{
                            maxWidth: '300px',
                            '& .MuiOutlinedInput-root': {
                                height: '34px',
                                '& input': { height: '34px', padding: '0 14px' },
                                '& .MuiInputBase-input': { height: '34px', padding: '0 14px' }
                            }
                        }}
                    />
                </Box>
                {loading ? (
                    <Skeleton variant="rectangular" width="100%" height={400} sx={{ marginTop: '16px', borderRadius: '8px' }} />
                ) : (
                    <Paper
                        sx={{
                            width: "100%",
                            borderRadius: "8px",
                            border: "1px solid var(--Gray-200, #EAECF0)",
                            background: "var(--White, #FFF)",
                            boxShadow: "0px 1px 3px 0px rgba(16, 24, 40, 0.10), 0px 1px 2px 0px rgba(16, 24, 40, 0.06)",
                            marginTop: '16px',
                        }}
                    >
                        <Typography sx={{ color: "var(--Gray-900, #101828)", padding: "20px 24px" }}>
                            Booking Details
                        </Typography>

                        <DataGrid
                            slots={{ toolbar: GridToolbar }}
                            rows={exampleBookings}
                            columns={columns}
                            paginationModel={paginationModel}
                            onPaginationModelChange={handlePaginationModelChange}
                            pageSizeOptions={[5, 10, 20]}
                            paginationMode="client"
                            checkboxSelection
                            disableRowSelectionOnClick
                            rowHeight={70}
                            sx={{
                                '& .MuiDataGrid-toolbarContainer .MuiButtonBase-root': {
                                    backgroundColor: '#737373',
                                    color: '#fff',
                                    height: '34px',
                                    my: 1,
                                    minWidth: 'auto',
                                    '&:hover': {
                                        backgroundColor: '#404040',
                                    },
                                },
                            }}
                        />
                    </Paper>
                )}
            </Box>
        </Box>
    );
};

export default AgentDashboard;
