import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import {
    Box,
    Typography,
    IconButton,
    Paper,
    TextField,
    Divider,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button,
    Tabs,
    Tab,
    CircularProgress
} from '@mui/material';
import { DeleteIcon, EditIcon } from "../shared/IconButtons";
import SettingsIcon from '@mui/icons-material/Settings';
import { useNavigate } from 'react-router-dom';
import { fetchAdminOrganizations, deleteAdminOrganization } from '../../redux/adminOrganizationSlice';

const Admin = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchQuery, setSearchQuery] = useState('');
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [organizationToDelete, setOrganizationToDelete] = useState(null);
    const [currentTab, setCurrentTab] = useState(0);

    // Safely access the Redux state with default values
    const adminOrganizations = useSelector(state => state.adminOrganizations);
    const {
        organizations = [],
        pagination = {},
        status = 'idle',
        error = null
    } = adminOrganizations || {};

    const tabStatus = ['pending', 'verified', 'rejected'];

    useEffect(() => {
        dispatch(fetchAdminOrganizations({ status: tabStatus[currentTab] }));
    }, [dispatch, currentTab]);

    const handleTabChange = (event, newValue) => {
        setCurrentTab(newValue);
    };

    const handleSettings = (id) => {
        navigate(`/admin/settings/${id}`);
    };

    const handleEdit = (organization) => {
        console.log('edit', organization);
    };

    const handleDeleteClick = (organization) => {
        setOrganizationToDelete(organization);
        setDeleteDialogOpen(true);
    };

    const handleDeleteConfirm = async () => {
        if (organizationToDelete) {
            await dispatch(deleteAdminOrganization(organizationToDelete.id));
            // Refresh the list after deletion
            dispatch(fetchAdminOrganizations({ status: tabStatus[currentTab] }));
        }
        setDeleteDialogOpen(false);
        setOrganizationToDelete(null);
    };

    const handleDeleteCancel = () => {
        setDeleteDialogOpen(false);
        setOrganizationToDelete(null);
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const filteredRows = Array.isArray(organizations) ? organizations.filter((org) =>
        org?.name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        org?.description?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        org?.registrationNumber?.toLowerCase().includes(searchQuery.toLowerCase())
    ) : [];

    const columns = [
        { field: 'name', headerName: 'Company', flex: 1 },
        { field: 'description', headerName: 'Description', flex: 1 },
        { field: 'registrationNumber', headerName: 'Registration Number', flex: 1 },
        {
            field: 'actions',
            headerName: '',
            flex: 0.7,
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', height: '100%', width: '100%' }}>
                    <IconButton aria-label="settings" onClick={() => handleSettings(params.row.id)}>
                        <SettingsIcon />
                    </IconButton>
                    <IconButton aria-label="edit" onClick={() => handleEdit(params.row)}>
                        <EditIcon />
                    </IconButton>
                    <IconButton aria-label="delete" onClick={() => handleDeleteClick(params.row)}>
                        <DeleteIcon />
                    </IconButton>
                </Box>
            )
        }
    ];

    if (status === 'loading') {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="400px">
                <CircularProgress />
            </Box>
        );
    }

    if (status === 'failed') {
        return (
            <Box sx={{ padding: '32px' }}>
                <Typography color="error" variant="h6">
                    Error: {typeof error === 'string' ? error : 'Failed to load organizations'}
                </Typography>
            </Box>
        );
    }

    return (
        <Box sx={{ padding: '32px' }}>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                <Typography variant="h3">Admin</Typography>
                <TextField
                    variant="outlined"
                    placeholder="Search..."
                    value={searchQuery}
                    onChange={handleSearchChange}
                    size="small"
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            height: '34px',
                            '& input': {
                                height: '34px',
                                padding: '0 14px',
                            },
                            '& .MuiInputBase-input': {
                                height: '34px',
                                padding: '0 14px',
                            }
                        }
                    }}
                />
            </Box>

            <Divider />

            <Tabs value={currentTab} onChange={handleTabChange} aria-label="organization status tabs">
                <Tab label="Pending" />
                <Tab label="Verified" />
                <Tab label="Rejected" />
            </Tabs>

            <Paper
                sx={{
                    width: "100%",
                    borderRadius: "16px",
                    marginTop: '24px',
                    border: "1px solid var(--Gray-200, #EAECF0)",
                    background: "var(--White, #FFF)",
                    boxShadow: "0px 1px 3px 0px rgba(16, 24, 40, 0.10), 0px 1px 2px 0px rgba(16, 24, 40, 0.06)",
                }}
            >
                <Typography
                    sx={{
                        color: "var(--Gray-900, #101828)",
                        fontFamily: "Inter",
                        fontSize: "18px",
                        fontStyle: "normal",
                        fontWeight: 500,
                        lineHeight: "28px",
                        padding: "20px 24px",
                    }}
                >
                    {`${tabStatus[currentTab].charAt(0).toUpperCase() + tabStatus[currentTab].slice(1)} Organizations`}
                </Typography>
                <DataGrid
                    slots={{ toolbar: GridToolbar }}
                    rows={filteredRows}
                    columns={columns}
                    checkboxSelection
                    disableSelectionOnClick
                    rowHeight={70}
                    pageSizeOptions={[5, 10, 20]}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 6,
                            },
                        },
                    }}
                    sx={{
                        '& .MuiDataGrid-toolbarContainer .MuiButtonBase-root': {
                            backgroundColor: '#737373',
                            color: '#fff',
                            height: '34px',
                            my: 1,
                            minWidth: 'auto',
                            '&:hover': {
                                backgroundColor: '#404040',
                            },
                        },
                    }}
                />
            </Paper>

            <Dialog
                open={deleteDialogOpen}
                onClose={handleDeleteCancel}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete the organization "{organizationToDelete?.name}"?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant='cancel' onClick={handleDeleteCancel}>Cancel</Button>
                    <Button variant='save' onClick={handleDeleteConfirm} autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default Admin;