import React, { useState } from 'react';
import {
    Switch,
    FormControlLabel,
    Typography,
    Box,
    OutlinedInput,
    Button,
    TableCell,
    Table,
    TableContainer, TableRow, TableBody, TableHead, InputAdornment
} from '@mui/material';

import {Search} from "@mui/icons-material";
import FilterListIcon from '@mui/icons-material/FilterList';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import AddIcon from '@mui/icons-material/Add';

const Team = () => {
    const [twoStepAuth, setTwoStepAuth] = useState(false);
    const [hideSupportMessages, setHideSupportMessages] = useState(true);
    const handleTwoStepAuthChange = (event) => {
        setTwoStepAuth(event.target.checked);
    };
    const handleHideSupportMessagesChange = (event) => {
        setHideSupportMessages(event.target.checked);
    };

    return (
        <Box sx={{marginTop:'-10px'}}>
            {/*<Box sx={{height:'60px'}}>*/}
            {/*    <FormControlLabel*/}
            {/*        control={<Switch checked={twoStepAuth} onChange={handleTwoStepAuthChange} />}*/}
            {/*        label={*/}
            {/*            <Box>*/}
            {/*                <Typography variant="h6">*/}
            {/*                    Require two-step authentication for your team*/}
            {/*                </Typography>*/}
            {/*                <Typography variant="body2">*/}
            {/*                    This will require any team member without two-step authentication to enable it the next time they sign in.*/}
            {/*                </Typography>*/}
            {/*            </Box>*/}
            {/*        }*/}
            {/*        labelPlacement="end"*/}
            {/*    />*/}
            {/*</Box>*/}
            {/*<Box sx={{height:'60px'}}>*/}
            {/*    <FormControlLabel*/}
            {/*        control={<Switch checked={hideSupportMessages} onChange={handleHideSupportMessagesChange} />}*/}
            {/*        label={*/}
            {/*            <Box>*/}
            {/*                <Typography variant="h6">*/}
            {/*                    Hide support messages for non-Administrator users*/}
            {/*                </Typography>*/}
            {/*                <Typography variant="body2">*/}
            {/*                    This will prevent non-Administrator users from viewing support messages for your account.*/}
            {/*                </Typography>*/}
            {/*            </Box>*/}
            {/*        }*/}
            {/*        labelPlacement="end"*/}
            {/*    />*/}
            {/*</Box>*/}

            <Box sx={{marginTop:'32px'}}>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <OutlinedInput
                        startAdornment={
                            <InputAdornment position="start">
                                <Search />
                            </InputAdornment>
                        }
                        placeholder="Filter by name or email..."
                        sx={{ height:'34px'}}
                        inputProps={{
                            sx: {
                                padding: 0
                            }
                        }}
                    />
                    <Box>
                        <Button variant="cancel" startIcon={<FilterListIcon />}>
                            <Typography variant="button">Filter</Typography>
                        </Button>
                        <Button variant="cancel" startIcon={<FileDownloadIcon />} sx={{ ml: 1 }}>
                            <Typography variant="button">Export</Typography>
                        </Button>
                        <Button variant="save" startIcon={<AddIcon />} sx={{ ml: 1 }}>
                            <Typography variant="button">New member</Typography>
                        </Button>
                    </Box>
                </Box>

                <TableContainer sx={{marginTop:"32px"}}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <Typography variant="subtitle2">
                                        MEMBER
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="subtitle2">
                                        ROLES
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="subtitle2">
                                        AUTH
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="subtitle2">
                                        LAST LOGIN
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>
                                    <Typography variant="body2">
                                        john.doe@example.com <Box component="span" sx={{ ml: 1, bgcolor: 'lightblue', p: 0.5, borderRadius: 1 }}>You</Box>
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="body2">Administrator</Typography>
                                    <Typography variant="caption">(Owner)</Typography>
                                </TableCell>
                                <TableCell></TableCell>
                                <TableCell>
                                    <Typography variant="body2">
                                        Jul 29, 2024, 6:35 PM
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>

                <Box display="flex" justifyContent="flex-end" mt={2}>
                    <Typography variant="subtitle2">1 member</Typography>
                </Box>
            </Box>
        </Box>
    );
};

export default Team;