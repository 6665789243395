import {
    Box,
    Typography,
    TextField,
    Grid,
    InputAdornment,
    styled,
    Paper,
    useMediaQuery,
    Alert
} from '@mui/material';
import { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import StyledDatePickerContainer from "./StyledDatePickerContainer";

const DurationField = styled(Box)({
    '& .MuiTextField-root': {
        marginRight: '8px'
    }
});

const PreviewPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(2),
    height: '100%',
    backgroundColor: theme.palette.primary[50],
    border: `1px solid ${theme.palette.grey[200]}`,
}));

const CustomTextField = styled(TextField)(({ theme }) => ({
    '& .MuiOutlinedInput-root': {
        borderRadius: '8px',
        backgroundColor: 'var(--White, #FFF)',
        boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
        '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: 'var(--Gray-300, #D0D5DD)',
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: 'var(--Gray-300, #D0D5DD)',
        },
    },
    '& .MuiOutlinedInput-input': {
        borderRadius: '8px',
        backgroundColor: 'var(--White, #FFF)',
        padding: '10px',
    },
    '& .MuiInputLabel-root': {
        color: 'var(--gray-300, #B0B0B0)',
        fontFamily: 'Inter',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '24px',
        transform: 'translate(15px, 10px) scale(1)',
        '&.MuiInputLabel-shrink': {
            transform: 'translate(20px, -7px) scale(0.60)',
            color: 'var(--gray-300, #B0B0B0)',
        },
    },
}));

const CustomDuration = ({ settings, handleSettingChange }) => {
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [dateError, setDateError] = useState('');
    const isMobile = useMediaQuery('(max-width:600px)');

    const generatePreviewTimes = () => {
        const minDuration = parseInt(settings.minDurationHours) * 60 + parseInt(settings.minDurationMinutes);
        const maxDuration = parseInt(settings.maxDurationHours) * 60 + parseInt(settings.maxDurationMinutes);
        const increment = parseInt(settings.timeIncrementHours) * 60 + parseInt(settings.timeIncrementMinutes);

        if (minDuration >= maxDuration || increment <= 0) return [];

        const times = [];
        for (let i = minDuration; i <= maxDuration; i += increment) {
            times.push(`${Math.floor(i / 60)}h ${i % 60}m`);
        }
        return times;
    };

    const getInitialStartDate = () => {
        const date = new Date();
        date.setHours(9, 0, 0, 0);
        return date;
    };

    const minStartDate = getInitialStartDate();

    const filterStartTime = (time) => {
        const currentDate = new Date();
        const selectedDate = new Date(time);

        if (selectedDate.toDateString() === currentDate.toDateString()) {
            return time.getHours() >= Math.max(9, currentDate.getHours()) && time.getHours() < 17;
        }
        return time.getHours() >= 9 && time.getHours() < 17;
    };

    const filterEndTime = (time) => {
        if (!startDate) return false;

        const minDurationMs = (parseInt(settings.minDurationHours) * 60 + parseInt(settings.minDurationMinutes)) * 60 * 1000;
        const maxDurationMs = (parseInt(settings.maxDurationHours) * 60 + parseInt(settings.maxDurationMinutes)) * 60 * 1000;
        const selectedDate = new Date(time);
        const minimumEndTime = new Date(startDate.getTime() + minDurationMs);
        const maximumEndTime = new Date(startDate.getTime() + maxDurationMs);

        return selectedDate >= minimumEndTime &&
            selectedDate <= maximumEndTime &&
            selectedDate.getHours() >= 9 &&
            selectedDate.getHours() < 17;
    };

    const handleStartDateChange = (date) => {
        setStartDate(date);
        setEndDate(null);
        setDateError('');
    };

    const handleEndDateChange = (date) => {
        if (!startDate) {
            setDateError('Please select a start date first');
            return;
        }

        const duration = (date.getTime() - startDate.getTime()) / (60 * 1000); // duration in minutes
        const minDuration = parseInt(settings.minDurationHours) * 60 + parseInt(settings.minDurationMinutes);
        const maxDuration = parseInt(settings.maxDurationHours) * 60 + parseInt(settings.maxDurationMinutes);

        if (duration < minDuration || duration > maxDuration) {
            setDateError(`Duration must be between ${minDuration} and ${maxDuration} minutes`);
            return;
        }

        setEndDate(date);
        setDateError('');
    };

    const generateTimeSlots = (date) => {
        const slots = [];
        const incrementMinutes = parseInt(settings.timeIncrementHours) * 60 + parseInt(settings.timeIncrementMinutes);

        if (incrementMinutes <= 0) return slots;

        const startTime = new Date(date);
        startTime.setHours(9, 0, 0, 0);
        const endTime = new Date(date);
        endTime.setHours(17, 0, 0, 0);

        while (startTime <= endTime) {
            slots.push(new Date(startTime));
            startTime.setMinutes(startTime.getMinutes() + incrementMinutes);
        }

        return slots;
    };

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
                <DurationField>
                    <Typography variant="subtitle1" gutterBottom>
                        Minimum Duration
                    </Typography>
                    <Box display="flex" alignItems="center">
                        <TextField
                            type="number"
                            label="Hours"
                            value={settings.minDurationHours}
                            onChange={(e) => handleSettingChange('minDurationHours', e.target.value)}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">h</InputAdornment>,
                            }}
                        />
                        <TextField
                            type="number"
                            label="Minutes"
                            value={settings.minDurationMinutes}
                            onChange={(e) => handleSettingChange('minDurationMinutes', e.target.value)}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">m</InputAdornment>,
                            }}
                        />
                    </Box>

                    <Typography variant="subtitle1" gutterBottom sx={{ mt: 2 }}>
                        Maximum Duration
                    </Typography>
                    <Box display="flex" alignItems="center">
                        <TextField
                            type="number"
                            label="Hours"
                            value={settings.maxDurationHours}
                            onChange={(e) => handleSettingChange('maxDurationHours', e.target.value)}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">h</InputAdornment>,
                            }}
                        />
                        <TextField
                            type="number"
                            label="Minutes"
                            value={settings.maxDurationMinutes}
                            onChange={(e) => handleSettingChange('maxDurationMinutes', e.target.value)}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">m</InputAdornment>,
                            }}
                        />
                    </Box>

                    <Typography variant="subtitle1" gutterBottom sx={{ mt: 2 }}>
                        Time Increment
                    </Typography>
                    <Box display="flex" alignItems="center">
                        <TextField
                            type="number"
                            label="Hours"
                            value={settings.timeIncrementHours}
                            onChange={(e) => handleSettingChange('timeIncrementHours', e.target.value)}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">h</InputAdornment>,
                            }}
                        />
                        <TextField
                            type="number"
                            label="Minutes"
                            value={settings.timeIncrementMinutes}
                            onChange={(e) => handleSettingChange('timeIncrementMinutes', e.target.value)}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">m</InputAdornment>,
                            }}
                        />
                    </Box>
                </DurationField>
            </Grid>
            <Grid item xs={12} md={6}>
                <PreviewPaper elevation={0}>
                    <Typography variant="h6" gutterBottom>
                        Preview
                    </Typography>
                    <Typography variant="body2" color="textSecondary" paragraph>
                        Based on your settings, customers will be able to book the following durations:
                    </Typography>
                    <Typography
                        sx={{
                            fontWeight: 600,
                            fontSize: isMobile ? '14px' : '16px',
                            color: 'grey',
                            mb: 1,
                        }}
                    >
                        Try the booking experience:
                    </Typography>
                    <Box
                        sx={{
                            display: 'grid',
                            gridTemplateColumns: isMobile ? '1fr' : '1fr 48px 1fr',
                            alignItems: 'start',
                            mt: 1,
                            position: 'relative',
                        }}
                    >
                        <StyledDatePickerContainer>
                            <DatePicker
                                selected={startDate}
                                onChange={handleStartDateChange}
                                showTimeSelect
                                minDate={minStartDate}
                                filterTime={filterStartTime}
                                dateFormat="MMMM d, yyyy h:mm aa"
                                placeholderText="Start date"
                                timeCaption="Start Time"
                                includeTimes={generateTimeSlots(new Date())}
                                customInput={
                                    <CustomTextField
                                        fullWidth
                                    />
                                }
                            />
                        </StyledDatePickerContainer>

                        <Typography
                            sx={{
                                textAlign: 'center',
                                fontSize: '16px',
                                lineHeight: '40px',
                                display: isMobile ? 'none' : 'block',
                            }}
                        >
                            to
                        </Typography>

                        <StyledDatePickerContainer>
                            <DatePicker
                                selected={endDate}
                                onChange={handleEndDateChange}
                                showTimeSelect
                                minDate={startDate || minStartDate}
                                filterTime={filterEndTime}
                                dateFormat="MMMM d, yyyy h:mm aa"
                                placeholderText="End date"
                                timeCaption="End Time"
                                disabled={!startDate}
                                customInput={
                                    <CustomTextField
                                        fullWidth
                                    />
                                }
                            />
                        </StyledDatePickerContainer>
                    </Box>
                    {dateError && (
                        <Alert severity="error" sx={{ mt: 2 }}>
                            {dateError}
                        </Alert>
                    )}
                </PreviewPaper>
            </Grid>
        </Grid>
    );
};

export default CustomDuration;