import React, { useState, useEffect } from 'react';
import {
    Box,
    Button,
    IconButton,
    Typography,
    Paper,
    ImageList,
    ImageListItem,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    Pagination,
    Stack,
    Skeleton,
    Tooltip,
    Chip, Alert, Snackbar
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import LinkIcon from '@mui/icons-material/Link';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { getToken } from '../../../utils/authUtils';
import {map} from "lodash";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const IMAGE_URL = 'https://s3.ap-southeast-5.amazonaws.com/my.merchants/';
const API_URL = process.env.REACT_APP_API_BASE_URL;
const ITEMS_PER_PAGE = 10;

const Uploads = () => {
    const [confirmDialog, setConfirmDialog] = useState({ open: false, id: null });
    const [media, setMedia] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [hoveredItem, setHoveredItem] = useState(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const getSkeletonArray = () => new Array(ITEMS_PER_PAGE).fill(null);

    // Helper function to format file size
    const formatFileSize = (bytes) => {
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const sizes = ['Bytes', 'KB', 'MB', 'GB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
    };

    const getAuthHeaders = async () => {
        const token = await getToken();
        return {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        };
    };

    useEffect(() => {
        fetchMedia(page, ITEMS_PER_PAGE);
    }, [page]);

    const fetchMedia = async (page = 1, limit = ITEMS_PER_PAGE) => {
        setIsLoading(true);
        setError(null);

        try {
            const headers = await getAuthHeaders();
            const response = await fetch(`${API_URL}/uploads?page=${page}&limit=${limit}`, {
                headers
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();

            if (data.code === 200 && data.data.uploads) {

                const mediaItems = data.data.uploads.map((upload) => ({
                    id: upload.id,
                    title: upload.filename,
                    thumbnail: IMAGE_URL + upload.s3Key,
                    image_url: IMAGE_URL + upload.s3Key,
                    filename: upload.s3Key,
                    isVideo: false,
                    mimeType: upload.contentType,
                    size: upload.size
                }));

                setMedia(mediaItems);
                setTotalPages(data.data.pagination.totalPages);
            } else {
                throw new Error(data.message || 'Failed to process media data');
            }
        } catch (error) {
            console.error('Error fetching media:', error);
            setError('Failed to load media. Please try again later.');
        } finally {
            setIsLoading(false);
        }
    };

    // useEffect(() => {
    //     fetchMedia();
    // }, []);

    const handlePageChange = (event, value) => {
        setPage(value);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const handleCopyUrl = async (item) => {
        try {
            await navigator.clipboard.writeText(item.image_url);
            // Could add a toast notification here
        } catch (err) {
            console.error('Failed to copy URL:', err);
        }
    };

    const handlePreview = (item) => {
        window.open(item.image_url, '_blank');
    };

    const handleDelete = (uploadId) => {
        setConfirmDialog({ open: true, id: uploadId });
    };

    const handleConfirmDelete = async () => {
        setIsLoading(true);
        setError(null);

        try {
            const headers = await getAuthHeaders();
            const response = await fetch(`${API_URL}/uploads?id=${confirmDialog.id}`, {
                method: 'DELETE',
                headers
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            // Remove the deleted item from the local state immediately
            setMedia(prevMedia => prevMedia.filter(item => item.id !== confirmDialog.id));

            // Fetch updated data in the background
            fetchMedia().catch(error => {
                console.error('Error refreshing media after delete:', error);
                // If fetch fails, we don't revert the UI as the delete was successful
            });

            // Show success message
            setSnackbarMessage({ open: true, message: 'Image deleted successfully', severity: 'success' });
        } catch (error) {
            console.error('Error deleting image:', error);
            setError('Failed to delete image. Please try again later.');
            setSnackbarMessage({ open: true, message: 'Failed to delete image', severity: 'error' });
        } finally {
            setIsLoading(false);
            setConfirmDialog({ open: false, id: null });
        }
    };

    const validateFileSize = (file) => {
        const maxSize = 5 * 1024 * 1024; // 5MB
        if (file.size > maxSize) {
            throw new Error('File size exceeds 5MB limit');
        }
    };

    const validateFileType = (file) => {
        const allowedTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/svg+xml'];
        if (!allowedTypes.includes(file.type)) {
            throw new Error('Invalid file type. Please upload JPG, PNG, GIF, or SVG files only');
        }
    };

    const handleFileUpload = async (event) => {
        const file = event.target.files[0];
        if (!file) return;

        setIsLoading(true);
        setError(null);

        try {
            validateFileSize(file);
            validateFileType(file);

            const token = await getToken();

            const presignedUrlResponse = await fetch(`${API_URL}/uploads`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    organizationId: 1,
                    filename: file.name,
                    contentType: file.type,
                    size: file.size,
                }),
            });

            if (!presignedUrlResponse.ok) {
                throw new Error('Failed to get pre-signed URL');
            }

            const { data: { presignedUrl, key, uploadId } } = await presignedUrlResponse.json();

            const uploadResponse = await fetch(presignedUrl, {
                method: 'PUT',
                body: file,
                headers: {
                    'Content-Type': file.type,
                },
            });

            if (!uploadResponse.ok) {
                throw new Error('Failed to upload file to S3');
            }

            const confirmResponse = await fetch(`${API_URL}/uploads?id=${uploadId}&confirm=true`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ key }),
            });

            if (!confirmResponse.ok) {
                throw new Error('Failed to confirm upload');
            }

            await fetchMedia();
            setPage(1); // Reset to first page to show the newly uploaded image
        } catch (error) {
            console.error('Error details:', error);
            setError(`Upload failed: ${error.message}`);
        } finally {
            setIsLoading(false);
            event.target.value = '';
        }
    };

    const handleDragOver = (event) => {
        event.preventDefault();
        event.stopPropagation();
    };

    const handleDrop = (event) => {
        event.preventDefault();
        event.stopPropagation();

        const files = event.dataTransfer.files;
        if (files.length) {
            const fakeEvent = { target: { files } };
            handleFileUpload(fakeEvent);
        }
    };

    return (
        <><Box>
            <Box flex={1}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                    alignSelf: 'stretch',
                    width: '100%'
                }}>
                    <Box>
                        <Typography variant='h4'>Uploads</Typography>
                        <Typography variant='h5'>Manage and organize your uploaded files and media content.</Typography>
                    </Box>
                </Box>
            </Box>

            <Divider/>

            <Paper
                elevation={3}
                sx={{padding: 1, borderRadius: 2}}
                onDragOver={handleDragOver}
                onDrop={handleDrop}
            >
                <Box display="flex" sx={{backgroundColor: '#f4f6f8'}}>
                    <Box padding="24px">
                        <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42" fill="none">
                            <path
                                d="M33.5339 7.95652L27.087 1.50957C26.7609 1.18339 26.3186 1.0001 25.8574 1H2.73913C2.27788 1 1.83553 1.18323 1.50938 1.50938C1.18323 1.83553 1 2.27788 1 2.73913V39.2609C1.0001 39.7221 1.18339 40.1644 1.50957 40.4904L33.7374 8.26261C33.6812 8.15329 33.613 8.05062 33.5339 7.95652Z"
                                fill="white"/>
                            <path
                                d="M7.95605 13.1741C7.95605 14.0966 8.32251 14.9813 8.97481 15.6336C9.62711 16.2859 10.5118 16.6523 11.4343 16.6523C12.3568 16.6523 13.2415 16.2859 13.8938 15.6336C14.5461 14.9813 14.9126 14.0966 14.9126 13.1741C14.9126 12.2516 14.5461 11.3669 13.8938 10.7146C13.2415 10.0623 12.3568 9.6958 11.4343 9.6958C10.5118 9.6958 9.62711 10.0623 8.97481 10.7146C8.32251 11.3669 7.95605 12.2516 7.95605 13.1741Z"
                                fill="#E7E7E7"/>
                            <path
                                d="M20.8682 17.8311C20.79 17.706 20.6814 17.6029 20.5524 17.5314C20.4234 17.4599 20.2783 17.4224 20.1308 17.4224C19.9833 17.4224 19.8383 17.4599 19.7093 17.5314C19.5803 17.6029 19.4716 17.706 19.3934 17.8311L14.8039 25.1737L12.9343 22.1789C12.8561 22.0538 12.7474 21.9507 12.6184 21.8792C12.4894 21.8077 12.3444 21.7702 12.1969 21.7702C12.0494 21.7702 11.9044 21.8077 11.7754 21.8792C11.6464 21.9507 11.5377 22.0538 11.4595 22.1789L6.21777 30.565H28.8265L20.8682 17.8311Z"
                                fill="#E7E7E7"/>
                            <path
                                d="M7.95605 13.1741C7.95605 14.0966 8.32251 14.9813 8.97481 15.6336C9.62711 16.2859 10.5118 16.6523 11.4343 16.6523C12.3568 16.6523 13.2415 16.2859 13.8938 15.6336C14.5461 14.9813 14.9126 14.0966 14.9126 13.1741C14.9126 12.2516 14.5461 11.3669 13.8938 10.7146C13.2415 10.0623 12.3568 9.6958 11.4343 9.6958C10.5118 9.6958 9.62711 10.0623 8.97481 10.7146C8.32251 11.3669 7.95605 12.2516 7.95605 13.1741Z"
                                stroke="#00303E" strokeLinecap="round" strokeLinejoin="round"/>
                            <path
                                d="M21.9465 19.5563L20.8682 17.8311C20.79 17.706 20.6814 17.6029 20.5524 17.5314C20.4234 17.4599 20.2783 17.4224 20.1308 17.4224C19.9833 17.4224 19.8383 17.4599 19.7093 17.5314C19.5803 17.6029 19.4716 17.706 19.3934 17.8311L14.8039 25.1737L12.9343 22.1789C12.8561 22.0538 12.7474 21.9507 12.6184 21.8792C12.4894 21.8077 12.3444 21.7702 12.1969 21.7702C12.0494 21.7702 11.9044 21.8077 11.7754 21.8792C11.6464 21.9507 11.5377 22.0538 11.4595 22.1789L6.21777 30.565H14.9134"
                                stroke="#00303E" strokeLinecap="round" strokeLinejoin="round"/>
                            <path
                                d="M18.3913 41H2.73913C2.27788 41 1.83553 40.8168 1.50938 40.4906C1.18323 40.1645 1 39.7221 1 39.2609V2.73913C1 2.27788 1.18323 1.83553 1.50938 1.50938C1.83553 1.18323 2.27788 1 2.73913 1H25.8574C26.3186 1.0001 26.7609 1.18339 27.087 1.50957L33.5339 7.95652C33.8601 8.2826 34.0434 8.72488 34.0435 9.18609V16.6522"
                                stroke="#00303E" strokeLinecap="round" strokeLinejoin="round"/>
                            <path
                                d="M30.5656 40.9804C32.6294 40.9804 34.6469 40.3684 36.3629 39.2218C38.0789 38.0752 39.4163 36.4456 40.2061 34.5388C40.9959 32.6321 41.2026 30.5341 40.7999 28.5099C40.3973 26.4858 39.4035 24.6265 37.9441 23.1671C36.4848 21.7078 34.6255 20.714 32.6014 20.3113C30.5772 19.9087 28.4791 20.1154 26.5724 20.9051C24.6657 21.6949 23.036 23.0324 21.8894 24.7484C20.7428 26.4644 20.1309 28.4818 20.1309 30.5456C20.1309 33.3131 21.2302 35.9672 23.1871 37.9241C25.144 39.881 27.7982 40.9804 30.5656 40.9804Z"
                                fill="#E7E7E7"/>
                            <path
                                d="M30.5656 40.9804C32.6294 40.9804 34.6469 40.3684 36.3629 39.2218C38.0789 38.0752 39.4163 36.4456 40.2061 34.5388C40.9959 32.6321 41.2026 30.5341 40.7999 28.5099C40.3973 26.4858 39.4035 24.6265 37.9441 23.1671C36.4848 21.7078 34.6255 20.714 32.6014 20.3113C30.5772 19.9087 28.4791 20.1154 26.5724 20.9051C24.6657 21.6949 23.036 23.0324 21.8894 24.7484C20.7428 26.4644 20.1309 28.4818 20.1309 30.5456C20.1309 33.3131 21.2302 35.9672 23.1871 37.9241C25.144 39.881 27.7982 40.9804 30.5656 40.9804Z"
                                stroke="#00303E" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M30.5654 25.3281V35.7629" stroke="#00303E" strokeLinecap="round"
                                  strokeLinejoin="round"/>
                            <path d="M35.7824 30.5459H25.3477" stroke="#00303E" strokeLinecap="round"
                                  strokeLinejoin="round"/>
                        </svg>
                    </Box>
                    <Box sx={{flexGrow: 1}}>
                        <Typography
                            sx={{
                                color: 'var(--dark-500, #09090B)',
                                fontFamily: 'Inter',
                                fontSize: '14px',
                                fontStyle: 'normal',
                                fontWeight: 600,
                                lineHeight: '20px',
                                paddingTop: '24px',
                            }}
                        >
                            Click to upload or drag and drop
                        </Typography>
                        <Typography
                            sx={{
                                color: 'var(--Gray-500, #667085)',
                                fontFamily: 'Inter',
                                fontSize: '12px',
                                fontStyle: 'normal',
                                fontWeight: 400,
                                lineHeight: '18px',
                            }}
                        >
                            SVG, PNG, JPG or GIF (max. 5MB)
                        </Typography>
                    </Box>

                    <Box sx={{paddingTop: '24px', paddingRight: '24px'}}>
                        <Button
                            variant="cancel"
                            onClick={() => document.getElementById('file-upload-input').click()}
                        >
                            + Add Image
                        </Button>
                        <input
                            id="file-upload-input"
                            type="file"
                            hidden
                            onChange={handleFileUpload}
                            accept="image/jpeg,image/png,image/gif,image/svg+xml"/>
                    </Box>
                </Box>
            </Paper>

            {error && (
                <Box sx={{color: 'error.main', textAlign: 'center', my: 2}}>
                    {error}
                </Box>
            )}

            <ImageList
                sx={{
                    width: '100%',
                    height: 'auto',
                    marginTop: 2,
                    overflow: 'hidden',
                    gap: '16px !important'
                }}
                cols={5}
                rowHeight={200}
            >
                {isLoading ? (
                    getSkeletonArray().map((_, index) => (
                        <ImageListItem key={`skeleton-${index}`}>
                            <Skeleton
                                variant="rectangular"
                                width="100%"
                                height={200}
                                animation="wave"
                                sx={{
                                    bgcolor: 'grey.100',
                                    borderRadius: 2
                                }}/>
                        </ImageListItem>
                    ))
                ) : (
                    media?.map((item) => (
                        <ImageListItem
                            key={item.id}
                            sx={{
                                position: 'relative',
                                overflow: 'hidden',
                                borderRadius: 2,
                                boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
                                transition: 'all 0.3s ease',
                                '&:hover': {
                                    transform: 'translateY(-4px)',
                                    boxShadow: '0 8px 12px rgba(0,0,0,0.15)'
                                }
                            }}
                            onMouseEnter={() => setHoveredItem(item.id)}
                            onMouseLeave={() => setHoveredItem(null)}
                        >
                            <img
                                src={item.thumbnail}
                                alt={item.title}
                                loading="lazy"
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                }}/>
                            {hoveredItem === item.id && (
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        right: 0,
                                        bottom: 0,
                                        bgcolor: 'rgba(0, 0, 0, 0.6)',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'space-between',
                                        p: 2,
                                        transition: 'opacity 0.2s',
                                    }}
                                >
                                    <Box sx={{display: 'flex', justifyContent: 'flex-end', gap: 1}}>
                                        <Tooltip title="Preview">
                                            <IconButton
                                                size="small"
                                                onClick={() => handlePreview(item)}
                                                sx={{color: 'white'}}
                                            >
                                                <ZoomInIcon/>
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Copy URL">
                                            <IconButton
                                                size="small"
                                                onClick={() => handleCopyUrl(item)}
                                                sx={{color: 'white'}}
                                            >
                                                <LinkIcon/>
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Delete">
                                            <IconButton
                                                size="small"
                                                onClick={() => handleDelete(item.id)}
                                                sx={{color: 'white'}}
                                            >
                                                <DeleteIcon/>
                                            </IconButton>
                                        </Tooltip>
                                    </Box>
                                    <Box>
                                        <Typography
                                            variant="caption"
                                            sx={{
                                                color: 'white',
                                                display: 'block',
                                                mb: 0.5
                                            }}
                                        >
                                            {item.title}
                                        </Typography>
                                        <Chip
                                            label={formatFileSize(parseInt(item.size))}
                                            size="small"
                                            sx={{
                                                bgcolor: 'rgba(255,255,255,0.2)',
                                                color: 'white'
                                            }}/>
                                    </Box>
                                </Box>
                            )}
                        </ImageListItem>
                    ))
                )}
            </ImageList>

            <Box sx={{display: 'flex', justifyContent: 'center', mt: 4, mb: 4}}>
                <Stack spacing={2}>
                    <Pagination
                        count={totalPages}
                        page={page}
                        onChange={handlePageChange}
                        color="primary"
                        size="large"/>
                </Stack>
            </Box>

            <Dialog
                open={confirmDialog.open}
                onClose={() => setConfirmDialog({...confirmDialog, open: false})}
            >
                <DialogTitle>Confirm Deletion</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this image? This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setConfirmDialog({...confirmDialog, open: false})} variant="cancel">
                        Cancel
                    </Button>
                    <Button onClick={handleConfirmDelete} variant="save" autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </Box><Snackbar
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            open={snackbarOpen}
            autoHideDuration={5000}
            onClose={handleSnackbarClose}
        >
            <Alert
                onClose={handleSnackbarClose}
                severity={snackbarSeverity}
                sx={{width: '100%'}}
                iconMapping={{
                    success: <CheckCircleOutlineIcon/>,
                    error: <ErrorOutlineIcon/>,
                }}
            >
                {snackbarMessage}
            </Alert>
        </Snackbar></>
    );
};

const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
        return;
    }
    setSnackbarOpen(false);
};

export default Uploads;