import { useState } from 'react';
import { Box, Tabs, Tab, Divider, Typography } from '@mui/material';
import ConfirmationEmail from './BrandingContents/ConfirmationEmail';
import TicketTab from './BrandingContents/TicketTab';
import InvoiceTab from './BrandingContents/InvoiceTab';
import ReceiptTab from './BrandingContents/ReceiptTab';
import CheckoutTab from './BrandingContents/CheckoutTab';

const Branding = () => {
    const [value, setValue] = useState(0);
    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box display='flex' flexDirection='column' sx={{ width: '100%', padding: { xs: 1, md: 3 } }}>
            <Box>
                <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, alignItems: 'flex-start', width: '100%' }}>
                    <Box>
                        <Typography variant='h4'>
                            Branding
                        </Typography>
                        <Typography variant='h5' sx={{ marginTop: { xs: 1, md: 2 } }}>
                            Personalize Your Presence with Custom Templates
                        </Typography>
                    </Box>
                    <Box sx={{ ml: 'auto', display: 'flex', gap: '12px', mt: { xs: 2, md: 0 } }}>
                        {/* Button or actions here */}
                    </Box>
                </Box>

                <Box sx={{ height: '38px', paddingTop: { xs: '16px', md: '32px' } }}>
                    <Tabs
                        value={value}
                        onChange={handleTabChange}
                        aria-label="service tabs"
                        variant="scrollable"
                        scrollButtons="auto"
                    >
                        <Tab label="Ticket" />
                        <Tab label="Email" />
                        <Tab label="Invoice" />
                        <Tab label="Receipt" />
                        <Tab label="Checkout" />
                    </Tabs>
                </Box>

                <Divider sx={{ margin: { xs: '24px 0', md: '41px 0 32px 0' } }} />

                <Box>
                    {value === 0 && <TicketTab />}
                    {value === 1 && <ConfirmationEmail />}
                    {value === 2 && <InvoiceTab />}
                    {value === 3 && <ReceiptTab />}
                    {value === 4 && <CheckoutTab />}
                </Box>
            </Box>
        </Box>
    );
};

export default Branding;
