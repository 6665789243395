import React, { useState } from "react";
import { Box, Button, Divider, Paper, Typography, Stepper, Step, StepLabel } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import FirstStepForm from "./FirstStepForm";
import { useFormContext } from '../context/FormContext';
import { setCurrentStep, updateOnboardingRecord } from "../../redux/accountOnboardingSlice";
import { useDispatch } from "react-redux";
import keycloak from "../../keycloak";

const steps = ['Step 1', 'Step 2', 'Step 3', 'Step 4', 'Step 5'];

const FirstStep = () => {
    const dispatch = useDispatch();
    const [activeStep, setActiveStep] = useState(0);
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const navigate = useNavigate();

    const { formData, updateFormData } = useFormContext();

    const handleConfirm = async () => {
        if (activeStep === steps.length - 1) {
            // alert("All steps completed");
        } else {
            dispatch(setCurrentStep('second'));
            const token = keycloak.tokenParsed;
            try {
                const result = await dispatch(updateOnboardingRecord({
                    email: token?.email,
                    onboardingData: {
                        current_step: 'second',
                        completed_steps: ['first']
                    }
                })).unwrap();
            } catch (error) {
                console.error('Failed to update server:', error);
                // Handle the error (e.g., show an error message to the user)
            }
        }
    };

    const handleBack = () => {
        if (activeStep > 0) {
            setActiveStep((prevActiveStep) => prevActiveStep - 1);
            navigate('/previous');
        }
    };

    const handleFormSuccess = (updatedData) => {
        // Update form data in context
        Object.entries(updatedData).forEach(([key, value]) => {
            updateFormData(key, value);
        });
        setIsFormSubmitted(true);
    }

    return (
        <>
            <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label, index) => (
                    <Step key={index}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>

            <Paper elevation={5} height='100vh' sx={{ padding: '32px', margin: '32px', borderRadius: '12px' }}>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', alignSelf: 'stretch', width: '100%' }}>
                    <Box>
                        <Typography variant='h4'>
                            Organization Details
                        </Typography>
                        <Typography variant='h5'>
                            Please provide information about your organization to help us better understand your needs and requirements
                        </Typography>
                    </Box>
                </Box>

                <Divider sx={{ my: 2 }} />
                <FirstStepForm
                    organizationData={{
                        name: formData.companyName,
                        registration_number: formData.registrationNo,
                        company_address_1: formData.addressLine1,
                        company_postcode: formData.postcode,
                        company_state: formData.state,
                        company_country: formData.country,
                    }}
                    setOrganizationData={handleFormSuccess}
                    requiredFields={['name', 'registration_number', 'company_address_1', 'company_postcode', 'company_state', 'company_country']}
                    onSave={handleFormSuccess}
                />
                <Box sx={{ ml: 'auto', mt:5, display: 'flex', gap: '12px', justifyContent: 'flex-end' }}>
                    <Button variant="cancel" onClick={handleBack} disabled={activeStep === 0}>Previous</Button>
                    <Button variant="save" onClick={handleConfirm} disabled={!isFormSubmitted}>Next</Button>
                </Box>
            </Paper>
        </>
    );
};

export default FirstStep;