import { useState, useEffect } from "react";
import {
    Box,
    Divider,
    TextField,
    Typography,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Switch,
    FormControlLabel,
    Snackbar, Alert
} from "@mui/material";
import { styled } from "@mui/system";
import { useNavigate, useLocation } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { useDispatch } from 'react-redux';
import { createProduct, updateProduct } from "../../../../../redux/productSlice";
import keycloak from "../../../../../keycloak";
import SharedSelect from "../../../../shared/SharedSelect";
import {
    createProductCategory,
    fetchProductCategories,
    clearNewlyCreatedCategory
} from "../../../../../redux/productCategorySlice";
import PropTypes from 'prop-types';

const TextFieldTitle = styled(Typography)(() => ({
    color: 'var(--Gray-700, #344054)',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '20px',
    width: '312px',
}));

const CustomizeProduct = ({ product, onVariantsChange, onProductSave }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const [organization, setOrganization] = useState(null);
    const [loading, setLoading] = useState(false);
    const [successDialogOpen, setSuccessDialogOpen] = useState(false);
    const [selectedCategoryId, setSelectedCategoryId] = useState('');
    const [hasVariants, setHasVariants] = useState(product?.has_variants || false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');

    const { control, handleSubmit, formState: { errors }, setValue } = useForm({
        defaultValues: {
            id: product?.id || '',
            title: product?.name || '',
            category: '',
            has_variants: product?.has_variants || false,
            price: product?.price || '',
            description: product?.description || '',
            stock_quantity: product?.stock_quantity || '',
            low_stock_threshold: product?.low_stock_threshold || ''
        }
    });

    useEffect(() => {
        // Set the category ID after component mount if product has one
        if (product?.product_category_id) {
            setSelectedCategoryId(product.product_category_id.toString());
            setValue('category', product.product_category_id.toString());
        }
    }, [product?.product_category_id, setValue]);

    useEffect(() => {
        const initKeycloak = async () => {
            try {
                if (keycloak.authenticated) {
                    const token = keycloak.tokenParsed;
                    setOrganization(token?.organization);
                }
            } catch (error) {
                console.error("Keycloak initialization failed:", error);
            }
        };

        initKeycloak();
    }, []);

    const onSubmit = async (data) => {
        setLoading(true);

        const postData = {
            name: data.title,
            product_category_id: parseInt(selectedCategoryId, 10),
            has_variants: hasVariants,
            price: parseFloat(data.price),
            minimum_pax: 0,
            maximum_pax: 0,
            minimum_amount: parseFloat(data.price),
            maximum_amount: parseFloat(data.price),
            description: data.description,
            stock_quantity: parseInt(data.stock_quantity),
            low_stock_threshold: parseInt(data.low_stock_threshold),
            user_organization_id: 1,
            user_role_id: 3,
            categoryId: parseInt(selectedCategoryId, 10),
            minimumCurrencyId: 2,
            maximumCurrencyId: 2,
            statusOwnershipId: 15,
        };

        try {
            let savedProduct;
            if (data.id) {
                savedProduct = await dispatch(updateProduct({ id: data.id, postData })).unwrap();
                setSnackbarMessage('Product updated successfully');
            } else {
                savedProduct = await dispatch(createProduct(postData)).unwrap();
                setSnackbarMessage('Product created successfully');
            }
            setSnackbarSeverity('success');
            setSnackbarOpen(true);
            onProductSave(savedProduct);
        } catch (error) {
            console.error("Error creating/updating product:", error);
            setSnackbarMessage('Error saving product. Please try again.');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
        } finally {
            setLoading(false);
        }
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    const handleCategoryChange = (value) => {
        setSelectedCategoryId(value.toString());
        setValue('category', value.toString());
    };

    const handleVariantsChange = (event) => {
        const newValue = event.target.checked;
        setHasVariants(newValue);
        if (onVariantsChange) {
            onVariantsChange(newValue);
        }
    };

    return (
        <Box>
            <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                <Box display="flex" sx={{paddingRight: { xs: '24px', sm: '44px', md: '74px', lg: '264px' }}}>
                    <TextFieldTitle>Product Category</TextFieldTitle>
                    <Controller
                        name="category"
                        control={control}
                        render={({ field }) => (
                            <SharedSelect
                                {...field}
                                label="Select or Enter the category for product"
                                value={selectedCategoryId}
                                onChange={handleCategoryChange}
                                options={[
                                    { name: 'name', label: 'Category Name' },
                                ]}
                                createNewOption={createProductCategory}
                                fetchOptions={fetchProductCategories}
                                selectSelector={(state) => state.productCategories.productCategories}
                                createSelector={(state) => state.productCategories.newlyCreatedCategory}
                                clearNewlyCreatedSelector={clearNewlyCreatedCategory}
                                errorSelector={(state) => state.productCategories.error}
                                sx={{ width: '100%' }}
                            />
                        )}
                    />
                </Box>

                <Divider />

                <Box display="flex" sx={{paddingRight: { xs: '24px', sm: '44px', md: '74px', lg: '264px' }}}>
                    <TextFieldTitle>Product Name</TextFieldTitle>
                    <Controller
                        name="title"
                        control={control}
                        rules={{ required: "Product name is required" }}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                label="Enter the name for product"
                                fullWidth
                                error={!!errors.title}
                                helperText={errors.title?.message}
                            />
                        )}
                    />
                </Box>

                <Divider />

                <Box display="flex" sx={{paddingRight: { xs: '24px', sm: '44px', md: '74px', lg: '264px' }}}>
                    <TextFieldTitle>Multiple Variants</TextFieldTitle>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={hasVariants}
                                onChange={handleVariantsChange}
                                color="primary"
                            />
                        }
                        label={hasVariants ? "Yes" : "No"}
                        sx={{ width: '100%' }}
                    />
                </Box>

                <Divider />

                <Box display="flex" sx={{paddingRight: { xs: '24px', sm: '44px', md: '74px', lg: '264px' }}}>
                    <TextFieldTitle>Product Price</TextFieldTitle>
                    <Controller
                        name="price"
                        control={control}
                        rules={{
                            required: "Price is required",
                            validate: {
                                positive: value => parseFloat(value) > 0 || "Please enter a valid price. Use numbers only (e.g., 59.99)",
                                float: value => /^[+-]?\d+(\.\d+)?$/.test(value) || "Please enter a valid price. Use numbers only (e.g., 59.99)"
                            }
                        }}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                label="Enter the pricing for product"
                                fullWidth
                                error={!!errors.price}
                                helperText={errors.price?.message}
                                inputProps={{
                                    inputMode: 'decimal',
                                    pattern: '[0-9.]*',
                                    step: '0.01',
                                }}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    if (/^\d*\.?\d*$/.test(value)) {
                                        field.onChange(value);
                                    }
                                }}
                            />
                        )}
                    />
                </Box>

                <Divider />

                <Box display="flex" sx={{paddingRight: { xs: '24px', sm: '44px', md: '74px', lg: '264px' }}}>
                    <TextFieldTitle>Description for Product</TextFieldTitle>
                    <Controller
                        name="description"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                label="Enter the description for product"
                                fullWidth
                                multiline
                                rows={7}
                                inputProps={{ maxLength: 250 }}
                                helperText={`${field.value.length}/250`}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        padding: '15px',
                                        '& .MuiOutlinedInput-input': {
                                            padding: 0,
                                        },
                                    },
                                }}
                            />
                        )}
                    />
                </Box>

                <Divider />

                <Box display="flex" sx={{paddingRight: { xs: '24px', sm: '44px', md: '74px', lg: '264px' }}}>
                    <TextFieldTitle>Stock Quantity*</TextFieldTitle>
                    <Controller
                        name="stock_quantity"
                        control={control}
                        rules={{
                            required: "Stock quantity is required",
                            validate: {
                                positive: value => parseInt(value) >= 0 || "Stock quantity must be 0 or greater",
                                integer: value => Number.isInteger(Number(value)) || "Stock quantity must be a whole number"
                            }
                        }}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                label="Enter the stock quantity"
                                fullWidth
                                error={!!errors.stock_quantity}
                                helperText={errors.stock_quantity?.message}
                                inputProps={{
                                    inputMode: 'numeric',
                                    pattern: '[0-9]*'
                                }}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    if (/^\d*$/.test(value)) {
                                        field.onChange(value);
                                    }
                                }}
                                value={field.value || ''}
                            />
                        )}
                    />
                </Box>

                <Divider />

                <Box display="flex" sx={{paddingRight: { xs: '24px', sm: '44px', md: '74px', lg: '264px' }}}>
                    <TextFieldTitle>Low Stock Alert Threshold*</TextFieldTitle>
                    <Controller
                        name="low_stock_threshold"
                        control={control}
                        rules={{
                            required: "Low stock threshold is required",
                            validate: {
                                positive: value => parseInt(value) >= 0 || "Threshold must be 0 or greater",
                                integer: value => Number.isInteger(Number(value)) || "Threshold must be a whole number"
                            }
                        }}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                label="Enter the low stock alert threshold"
                                fullWidth
                                error={!!errors.low_stock_threshold}
                                helperText={errors.low_stock_threshold?.message}
                                inputProps={{
                                    inputMode: 'numeric',
                                    pattern: '[0-9]*'
                                }}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    if (/^\d*$/.test(value)) {
                                        field.onChange(value);
                                    }
                                }}
                                value={field.value || ''}
                            />
                        )}
                    />
                </Box>

                <Divider />

                <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '16px' }}>
                    <Button
                        variant="save"
                        type="submit"
                        disabled={loading}
                    >
                        {loading ? 'Saving...' : product?.id ? 'Update Product' : 'Create Product'}
                    </Button>
                </Box>
            </form>

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>

        </Box>
    );
};

export default CustomizeProduct;

CustomizeProduct.propTypes = {
    product: PropTypes.object,
    onVariantsChange: PropTypes.func,
    onProductSave: PropTypes.func
};