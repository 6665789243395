import {Box, Button, Divider, Tab, Tabs, Typography} from "@mui/material";

import React, {useState} from "react";
import PaymentMethod from "./PaymentMethod/PaymentMethod";
import PaymentDomain from "./PaymentDomain";

const Payment = () => {
    const [value, setValue] = useState(0);
    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <Box display='flex'>
            <Box flex={1}>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', alignSelf: 'stretch', width: '100%'}}>
                    <Box>
                        <Typography variant='h4'>
                            Payment
                        </Typography >
                        <Typography variant='h5'>
                            Set up and manage payment methods and transactions.
                        </Typography>
                    </Box>
                </Box>

                <Box height='38px' paddingTop='32px'>
                    <Tabs value={value} onChange={handleTabChange} aria-label="service tabs">
                        <Tab label="Payment Method" />
                        <Tab label="Payment Domain" />
                    </Tabs>
                </Box>

                <Divider sx={{margin: '41px 0 32px 0'}}/>

                <Box>
                    { value === 0 && <PaymentMethod/> }
                    { value === 1 && <PaymentDomain/> }
                </Box>
            </Box>
        </Box>
    )
}

export default Payment;