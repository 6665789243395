import React, { useState, useEffect } from 'react';
import { Box, Typography, TextField, Divider, FormControl, InputLabel, Select, MenuItem, Button, CircularProgress } from '@mui/material';
import { styled } from "@mui/system";

const TextFieldTitle = styled(Typography)(({ theme }) => ({
    color: 'var(--Gray-700, #344054)',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '20px',
    width: '100%',
    display: 'flex',
    marginTop: 4,
    [theme.breakpoints.up('md')]: {
        width: '312px',
    }
}));

const RequiredIndicator = styled('span')({
    color: 'red',
    marginLeft: '4px',
});

const FirstStepForm = ({ organizationData = {}, setOrganizationData, requiredFields = [], onSave }) => {
    const [formData, setFormData] = useState({
        name: '',
        registration_number: '',
        company_address_1: '',
        company_address_2: '',
        company_postcode: '',
        company_city: '',
        company_state: '',
        company_country: 'Malaysia',
        ...organizationData  // This will overwrite the default values with any provided data
    });
    const [isFormValid, setIsFormValid] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const isValid = requiredFields.every(field => formData[field] && formData[field].trim() !== '');
        setIsFormValid(isValid);
    }, [formData, requiredFields]);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        if (name === 'company_postcode') {
            // Restrict input to 5 digits
            const postcode = value.replace(/\D/g, '').slice(0, 5);
            setFormData(prev => ({ ...prev, [name]: postcode }));
        } else {
            setFormData(prev => ({ ...prev, [name]: value }));
        }
    };

    const handleSave = async () => {
        setIsLoading(true);
        try {
            setOrganizationData(formData);
            await onSave(formData);
        } catch (error) {
            console.error("Error saving form data:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const malaysiaStates = [
        "Johor", "Kedah", "Kelantan", "Malacca", "Negeri Sembilan",
        "Pahang", "Penang", "Perak", "Perlis", "Sabah",
        "Sarawak", "Selangor", "Terengganu", "Kuala Lumpur"
    ];

    const renderField = (label, field, required = false, options = null) => (
        <>
            <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} sx={{ marginBottom: '16px' }}>
                <TextFieldTitle>
                    {label} {required && <RequiredIndicator>*</RequiredIndicator>}
                </TextFieldTitle>
                {options ? (
                    <FormControl fullWidth sx={{ paddingRight: { md: '50px', lg: '240px' } }}>
                        <InputLabel id={`${field}-select-label`}>Select {label}</InputLabel>
                        <Select
                            labelId={`${field}-select-label`}
                            id={`${field}-select`}
                            name={field}
                            value={formData[field] || ''}
                            onChange={handleInputChange}
                            required={requiredFields.includes(field)}
                            label={`Select ${label}`}
                        >
                            {options.map((option) => (
                                <MenuItem key={option} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                ) : (
                    <TextField
                        name={field}
                        value={formData[field] || ''}
                        onChange={handleInputChange}
                        fullWidth
                        label={`Enter ${label}`}
                        required={requiredFields.includes(field)}
                        sx={{ paddingRight: { md: '50px', lg: '240px' } }}
                        inputProps={field === 'company_postcode' ? {
                            maxLength: 5,
                            pattern: '[0-9]*',
                            inputMode: 'numeric'
                        } : {}}
                        error={field === 'company_postcode' && formData[field].length > 0 && formData[field].length < 5}
                        helperText={field === 'company_postcode' && formData[field].length > 0 && formData[field].length < 5 ? "Postcode must be 5 digits" : ""}
                    />
                )}
            </Box>
            <Divider />
        </>
    );

    return (
        <Box component="form" noValidate autoComplete="off">
            <Box sx={{marginBottom: 2}}>
                <Typography variant='h6'>
                    Business Details
                </Typography>
            </Box>
            {renderField('Company Name', 'name', true)}
            {renderField('Registration No.', 'registration_number', true)}
            {renderField('Company Address Line 1', 'company_address_1', true)}
            {renderField('Company Address Line 2', 'company_address_2')}
            {renderField('Postcode', 'company_postcode', true)}
            {renderField('City', 'company_city')}
            {renderField('State', 'company_state', true, malaysiaStates)}
            {renderField('Country', 'company_country', true, ['Malaysia'])}
            <Box sx={{ display: 'flex', justifyContent: 'flex-start', mt: 2 }}>
                <Button
                    variant="save"
                    onClick={handleSave}
                    disabled={!isFormValid || isLoading}
                    startIcon={isLoading ? <CircularProgress size={20} color="inherit" /> : null}
                >
                    {isLoading ? 'Saving...' : 'Save'}
                </Button>
            </Box>
        </Box>
    );
};

export default FirstStepForm;