import { useState } from 'react';
import {
    Box,
    Paper,
    Typography,
    TextField,
    Divider,
} from '@mui/material';
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

const AgentCommissions = () => {
    const [searchQuery, setSearchQuery] = useState('');
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 10
    });

    const data = [
        {id: 1, booking_id: "B001", booking_date: '2024-02-20', event_date: '2024-03-01', ticket_price: 'RM 240', quantity: 5, total_sales: 'RM 1200', commission_rate: '10%', commission_amount: 'RM 120', customer_name: 'John Doe', payment_status: 'Paid', commission_status: 'Disbursed', payout_date: '2024-03-15'},
        {id: 2, booking_id: "B002", booking_date: '2024-02-21', event_date: '2024-03-02', ticket_price: 'RM 317', quantity: 3, total_sales: 'RM 950', commission_rate: '15%', commission_amount: 'RM 142.50', customer_name: 'Jane Smith', payment_status: 'Pending', commission_status: 'Pending', payout_date: '-'},
        {id: 3, booking_id: "B003", booking_date: '2024-02-22', event_date: '2024-03-03', ticket_price: 'RM 275', quantity: 4, total_sales: 'RM 1100', commission_rate: '10%', commission_amount: 'RM 110', customer_name: 'Alex Brown', payment_status: 'Paid', commission_status: 'Disbursed', payout_date: '2024-03-15'},
        {id: 4, booking_id: "B004", booking_date: '2024-02-23', event_date: '2024-03-04', ticket_price: 'RM 375', quantity: 2, total_sales: 'RM 750', commission_rate: '12%', commission_amount: 'RM 90', customer_name: 'Chris Green', payment_status: 'Paid', commission_status: 'Processing', payout_date: '-'},
        {id: 5, booking_id: "B005", booking_date: '2024-02-24', event_date: '2024-03-05', ticket_price: 'RM 233', quantity: 6, total_sales: 'RM 1400', commission_rate: '15%', commission_amount: 'RM 210', customer_name: 'Taylor White', payment_status: 'Pending', commission_status: 'Pending', payout_date: '-'},
        {id: 6, booking_id: "B006", booking_date: '2024-02-25', event_date: '2024-03-06', ticket_price: 'RM 250', quantity: 8, total_sales: 'RM 2000', commission_rate: '10%', commission_amount: 'RM 200', customer_name: 'Morgan Black', payment_status: 'Paid', commission_status: 'Disbursed', payout_date: '2024-03-15'},
        {id: 7, booking_id: "B007", booking_date: '2024-02-26', event_date: '2024-03-07', ticket_price: 'RM 250', quantity: 10, total_sales: 'RM 2500', commission_rate: '15%', commission_amount: 'RM 375', customer_name: 'Jordan Grey', payment_status: 'Pending', commission_status: 'Pending', payout_date: '-'},
        {id: 8, booking_id: "B008", booking_date: '2024-02-27', event_date: '2024-03-08', ticket_price: 'RM 500', quantity: 1, total_sales: 'RM 500', commission_rate: '10%', commission_amount: 'RM 50', customer_name: 'Sam Blue', payment_status: 'Paid', commission_status: 'Disbursed', payout_date: '2024-03-15'},
        {id: 9, booking_id: "B009", booking_date: '2024-02-28', event_date: '2024-03-09', ticket_price: 'RM 250', quantity: 12, total_sales: 'RM 3000', commission_rate: '12%', commission_amount: 'RM 360', customer_name: 'Casey Violet', payment_status: 'Paid', commission_status: 'Processing', payout_date: '-'},
        {id: 10, booking_id: "B010", booking_date: '2024-03-01', event_date: '2024-03-10', ticket_price: 'RM 250', quantity: 7, total_sales: 'RM 1750', commission_rate: '15%', commission_amount: 'RM 262.50', customer_name: 'Riley Gold', payment_status: 'Pending', commission_status: 'Pending', payout_date: '-'}
    ];

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
        setPaginationModel({ ...paginationModel, page: 0 });
    };

    const handlePaginationModelChange = (newModel) => {
        setPaginationModel(newModel);
    };

    const columns = [
        { field: 'booking_id', headerName: 'Booking ID', flex: 1.2, minWidth: 130 },
        { field: 'booking_date', headerName: 'Booking Date', flex: 1.8, minWidth: 150 },
        { field: 'event_date', headerName: 'Event Date', flex: 1.8, minWidth: 150 },
        { field: 'customer_name', headerName: 'Customer', flex: 2, minWidth: 180 },
        { field: 'ticket_price', headerName: 'Ticket Price', flex: 1.5, minWidth: 130 },
        { field: 'quantity', headerName: 'Quantity', flex: 1.5, minWidth: 130 },
        { field: 'total_sales', headerName: 'Total Sales', flex: 1.5, minWidth: 130 },
        { field: 'commission_rate', headerName: 'Commission Rate', flex: 1.7, minWidth: 170 },
        { field: 'commission_amount', headerName: 'Commission Amount', flex: 1.8, minWidth: 190 },
        {
            field: 'payment_status',
            headerName: 'Payment Status',
            flex: 1.5,
            minWidth: 160,
        },
        {
            field: 'commission_status',
            headerName: 'Commission Status',
            flex: 1.5,
            minWidth: 170,
        },
        { field: 'payout_date', headerName: 'Payout Date', flex: 1.5, minWidth: 140 },
    ];

    return (
        <Box sx={{ padding:{xs:1,md:'32px'} }}>
            <Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', alignSelf: 'stretch', width: '100%'}}>
                    <Box>
                        <Typography variant='h4'>Agent Commissions</Typography>
                        <Typography variant='h5'>Track your commission earnings from ticket sales</Typography>
                    </Box>
                    <Box sx={{ ml: 'auto', mr:'32px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        {/*addbuttonhere*/}
                    </Box>
                </Box>

                <Divider/>

                <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                    <Box/>
                    <Box display="flex" gap="12px">
                        <TextField
                            variant="outlined"
                            placeholder="Search bookings"
                            value={searchQuery}
                            onChange={handleSearchChange}
                            size="small"
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    height: '34px',
                                    '& input': {
                                        height: '34px',
                                        padding: '0 14px',
                                    },
                                    '& .MuiInputBase-input': {
                                        height: '34px',
                                        padding: '0 14px',
                                    }
                                }
                            }}
                        />
                    </Box>
                </Box>

                <Paper
                    sx={{
                        width: "100%",
                        borderRadius: "16px",
                        marginTop: '24px',
                        border: "1px solid var(--Gray-200, #EAECF0)",
                        background: "var(--White, #FFF)",
                        boxShadow: "0px 1px 3px 0px rgba(16, 24, 40, 0.10), 0px 1px 2px 0px rgba(16, 24, 40, 0.06)",
                    }}
                >
                    <Typography
                        sx={{
                            color: "var(--Gray-900, #101828)",
                            fontFamily: "Inter",
                            fontSize: "18px",
                            fontStyle: "normal",
                            fontWeight: 500,
                            lineHeight: "28px",
                            padding: "20px 24px",
                        }}
                    >
                        Commission Details
                    </Typography>

                    <Divider sx={{my:0}}/>

                    <DataGrid
                        slots={{ toolbar: GridToolbar }}
                        rows={data}
                        columns={columns}
                        paginationModel={paginationModel}
                        onPaginationModelChange={handlePaginationModelChange}
                        paginationMode="client"
                        checkboxSelection
                    />
                </Paper>
            </Box>
        </Box>
    );
};

export default AgentCommissions;