import React, { useState } from 'react';
import { Search, Link2, ExternalLink } from 'lucide-react';
import {
  Box,
  Typography,
  Button,
  Tabs,
  Tab,
  TextField,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Paper
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

const StyledTextField = styled(TextField)({
  '& .MuiInputBase-root': {
    paddingLeft: '40px',
  },
});

const DomainSetup = () => {
    const [selectedTab, setSelectedTab] = useState(0);
    const navigate = useNavigate();

    const domains = [
        {
            name: 'kopaarena.com',
            type: 'Primary for Online Store',
            status: 'Online Store'
        },
        {
            name: '1dsfa34.eventlah.com',
            type: 'Redirects to kopaarena.com',
            status: 'Online Store'
        },
        {
            name: 'kopaarena.eventlah.com',
            type: 'Redirects to kopaarena.com',
            status: 'Online Store'
        }
    ];

    const handleRedirect = (domainName) => {
        navigate(`/settings/domain-verification?domain=${domainName}`);
    };

    return (
        <Box sx={{ maxWidth: '1200px', margin: 'auto', p: 3, bgcolor: 'background.paper' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 4 }}>
                <Box>
                    <Typography variant="h4" component="h1" gutterBottom>Domains</Typography>
                    <Typography variant="body1" color="text.secondary">Manage and configure your domain settings</Typography>
                </Box>
                <Button variant="contained" color="primary">
                    Buy new domain
                </Button>
            </Box>

            <Tabs value={selectedTab} onChange={(e, newValue) => setSelectedTab(newValue)} sx={{ mb: 3 }}>
                <Tab label="Connect existing domain" />
                <Tab label="Transfer Domain" />
            </Tabs>

            <Box sx={{ position: 'relative', mb: 3 }}>
                <Search style={{ position: 'absolute', left: '10px', top: '50%', transform: 'translateY(-50%)', color: 'action.active' }} size={20} />
                <StyledTextField
                    fullWidth
                    variant="outlined"
                    placeholder="Search domains"
                />
            </Box>

            <List>
                {domains.map((domain, index) => (
                    <ListItem
                        key={index}
                        component={Paper}
                        elevation={0}
                        sx={{
                            mb: 2,
                            '&:hover': { bgcolor: 'action.hover' }
                        }}
                    >
                        <ListItemIcon>
                            <Link2 />
                        </ListItemIcon>
                        <ListItemText
                            primary={domain.name}
                            secondary={domain.type}
                        />
                        <ListItemSecondaryAction>
                            <Typography variant="body2" color="text.secondary" sx={{ mr: 1 }}>
                                {domain.status}
                            </Typography>
                            <IconButton edge="end" aria-label="external link" onClick={() => handleRedirect(domain.name)}>
                                <ExternalLink size={16} />
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                ))}
            </List>
        </Box>
    );
};

export default DomainSetup;