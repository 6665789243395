// eventlah-react-merchant/src/components/settings/product-settings/ProductCatalog/AddProduct/ProductVariant/VariantForm.jsx
import React, {useEffect, useState} from 'react';
import {
    Box,
    Button,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from "@mui/icons-material/Add";
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import {update} from "lodash";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import {getToken} from "../../../../../../utils/authUtils";

const IMAGE_URL = 'https://s3.ap-southeast-5.amazonaws.com/my.merchants/';
const API_URL = 'http://localhost:3001/api';

const VariantForm = ({ control, errors, onSubmit, loading, variants }) => {
    console.log('Variants in VariantForm:', variants);

    const [uploading, setUploading] = useState(false);
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success'
    });

    useEffect(() => {
        if (fields.length === 0) {
            addNewVariant();
        }
    }, []);

    const { fields, append, remove, update } = useFieldArray({
        control,
        name: "variants"
    });

    useEffect(() => {
    }, [fields]);

    const handleImageUpload = async (event, variantIndex, optionIndex) => {
        const file = event.target.files[0];
        if (!file) return;

        setUploading(true);

        console.log('Fields:', JSON.stringify(fields, null, 2));
        console.log('Variant Index:', variantIndex);
        console.log('Option Index:', optionIndex);

        try {
            const token = await getToken();

            // Check if variantIndex is valid
            if (typeof variantIndex !== 'number' || isNaN(variantIndex) || variantIndex < 0 || variantIndex >= fields.length) {
                throw new Error(`Invalid variant index: ${variantIndex}`);
            }

            const currentVariant = fields[variantIndex];

            if (!currentVariant) {
                throw new Error(`Variant at index ${variantIndex} is undefined`);
            }

            if (!currentVariant.options || !Array.isArray(currentVariant.options)) {
                throw new Error(`Options for variant at index ${variantIndex} are undefined or not an array`);
            }

            // Check if optionIndex is valid
            if (optionIndex === undefined || optionIndex < 0 || optionIndex >= currentVariant.options.length) {
                throw new Error(`Invalid option index: ${optionIndex}`);
            }

            const currentOption = currentVariant.options[optionIndex];

            if (!currentOption) {
                throw new Error(`Option at index ${optionIndex} is undefined`);
            }

            // Request pre-signed URL
            const presignedUrlResponse = await fetch(`${API_URL}/product-variants?upload=true`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    variantId: currentOption.id,
                    organizationId: 1,
                    filename: file.name,
                    contentType: file.type,
                    size: file.size,
                }),
            });

            if (!presignedUrlResponse.ok) {
                throw new Error('Failed to get pre-signed URL');
            }

            const { data: { presignedUrl, key, uploadId } } = await presignedUrlResponse.json();

            // Upload to S3
            const uploadResponse = await fetch(presignedUrl, {
                method: 'PUT',
                body: file,
                headers: {
                    'Content-Type': file.type,
                },
            });

            if (!uploadResponse.ok) {
                throw new Error('Failed to upload file to S3');
            }


            // Confirm upload
            const confirmResponse = await fetch(`${API_URL}/product-variants?id=${uploadId}&confirm=true`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ key }),
            });

            if (!confirmResponse.ok) {
                throw new Error('Failed to confirm upload');
            }

            const { data: upload } = await confirmResponse.json();

            // After successful upload, update the form value
            const uploadedImageUrl = upload.variant_image;
            update(variantIndex, {
                ...fields[variantIndex],
                options: fields[variantIndex].options.map((option, index) =>
                    index === optionIndex ? { ...option, image: uploadedImageUrl } : option
                )
            });

        } catch (error) {
            console.error('Upload error:', error);
            // Handle the error appropriately (e.g., show an error message to the user)
        } finally {
            setUploading(false);
        }
    };

    const addNewVariant = () => {
        append({ optionName: '', options: [{ optionValue: '', price: '', stock: '', image: '' }] });
    };

    const addNewOption = (variantIndex) => {
        const currentVariant = fields[variantIndex];
        const updatedOptions = [
            ...(currentVariant.options || []),
            { optionValue: '', price: '', stock: '', image: '' }
        ];
        update(variantIndex, { ...currentVariant, options: updatedOptions });
    };

    useEffect(() => {
        console.log('Variants updated:', variants);
    }, [variants]);

    useEffect(() => {
        console.log('Fields updated:', fields);
    }, [fields]);

    return (
        <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
            <Typography variant="h6" gutterBottom sx={{ fontSize: '12px' }}>
                Product Variants
            </Typography>
            <form onSubmit={onSubmit}>
                {fields.map((variant, variantIndex) => (
                    <Box key={variant.id} sx={{mb: 4}}>
                        <Typography variant="subtitle1" gutterBottom sx={{ fontSize: '14px' }}>
                            Variant {variantIndex + 1}
                        </Typography>
                        <Controller
                            name={`variants.${variantIndex}.optionName`}
                            control={control}
                            rules={{required: "Option name is required"}}
                            render={({field}) => (
                                <TextField
                                    {...field}
                                    label="Option Name"
                                    fullWidth
                                    margin="normal"
                                    error={!!errors.variants?.[variantIndex]?.optionName}
                                    helperText={errors.variants?.[variantIndex]?.optionName?.message}
                                    InputProps={{ style: { fontSize: 14} }}
                                    InputLabelProps={{ style: { fontSize: 14 } }}
                                />
                            )}
                        />
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ fontSize: '12px' }}>Image</TableCell>
                                        <TableCell sx={{ fontSize: '12px' }}>Option Value</TableCell>
                                        <TableCell sx={{ fontSize: '12px' }}>Price</TableCell>
                                        <TableCell sx={{ fontSize: '12px' }}>Stock</TableCell>
                                        <TableCell sx={{ fontSize: '12px' }}>Actions</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {variant.options && variant.options.map((option, optionIndex) => (
                                        <TableRow key={option.id || optionIndex}>
                                            <TableCell>
                                                <Controller
                                                    name={`variants.${variantIndex}.options.${optionIndex}.image`}
                                                    control={control}
                                                    render={({ field: { onChange, value } }) => (
                                                        <Box
                                                            sx={{
                                                                width: 60,
                                                                height: 60,
                                                                position: 'relative',
                                                                cursor: 'pointer',
                                                                border: '1px dashed #ccc',
                                                                borderRadius: '4px',
                                                                overflow: 'hidden',
                                                            }}
                                                            onClick={() => document.getElementById(`file-upload-${variantIndex}-${optionIndex}`).click()}
                                                        >
                                                            {value ? (
                                                                <img
                                                                    src={`${IMAGE_URL}${value}`}
                                                                    alt="Variant"
                                                                    style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                                                                />
                                                            ) : (
                                                                <Box
                                                                    sx={{
                                                                        display: 'flex',
                                                                        flexDirection: 'column',
                                                                        justifyContent: 'center',
                                                                        alignItems: 'center',
                                                                        height: '100%',
                                                                        color: '#999',
                                                                    }}
                                                                >
                                                                    <AddPhotoAlternateIcon sx={{ fontSize: 24 }} />
                                                                    <Typography variant="caption" sx={{ fontSize: '0.6rem' }}>Add</Typography>
                                                                </Box>
                                                            )}
                                                            <Box
                                                                sx={{
                                                                    position: 'absolute',
                                                                    left: 0,
                                                                    right: 0,
                                                                    bottom: 0,
                                                                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                                                    display: 'flex',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                    padding: '2px',
                                                                }}
                                                            >
                                                                <Typography variant="caption" sx={{ color: 'white', fontSize: '0.6rem' }}>
                                                                    {value ? 'Change' : 'Upload'}
                                                                </Typography>
                                                            </Box>
                                                            <input
                                                                id={`file-upload-${variantIndex}-${optionIndex}`}
                                                                type="file"
                                                                accept="image/*"
                                                                style={{ display: 'none' }}
                                                                onChange={(e) => handleImageUpload(e, variantIndex, optionIndex)}
                                                            />
                                                        </Box>
                                                    )}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <Controller
                                                    name={`variants.${variantIndex}.options.${optionIndex}.optionValue`}
                                                    control={control}
                                                    rules={{required: "Option value is required"}}
                                                    render={({field}) => (
                                                        <TextField
                                                            {...field}
                                                            error={!!errors.variants?.[variantIndex]?.options?.[optionIndex]?.optionValue}
                                                            helperText={errors.variants?.[variantIndex]?.options?.[optionIndex]?.optionValue?.message}
                                                            InputProps={{ style: { fontSize: 12 } }}
                                                        />
                                                    )}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <Controller
                                                    name={`variants.${variantIndex}.options.${optionIndex}.price`}
                                                    control={control}
                                                    rules={{required: "Price is required"}}
                                                    render={({field}) => (
                                                        <TextField
                                                            {...field}
                                                            type="number"
                                                            error={!!errors.variants?.[variantIndex]?.options?.[optionIndex]?.price}
                                                            helperText={errors.variants?.[variantIndex]?.options?.[optionIndex]?.price?.message}
                                                            InputProps={{ style: { fontSize: 14 } }}
                                                        />
                                                    )}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <Controller
                                                    name={`variants.${variantIndex}.options.${optionIndex}.stock`}
                                                    control={control}
                                                    rules={{required: "Stock is required"}}
                                                    render={({field}) => (
                                                        <TextField
                                                            {...field}
                                                            type="number"
                                                            error={!!errors.variants?.[variantIndex]?.options?.[optionIndex]?.stock}
                                                            helperText={errors.variants?.[variantIndex]?.options?.[optionIndex]?.stock?.message}
                                                            InputProps={{ style: { fontSize: 14 } }}
                                                        />
                                                    )}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <IconButton onClick={() => remove(optionIndex)}>
                                                    <DeleteIcon/>
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                            <Button
                                variant="cancel"
                                color="primary"
                                onClick={() => addNewOption(variantIndex)}
                                sx={{ fontSize: '12px', mt: 2}}
                            >
                                ADD OPTION
                            </Button>
                        </TableContainer>
                    </Box>
                ))}
                <Box sx={{mt: 2, display: 'flex', justifyContent: 'space-between'}}>
                    <Button
                        variant="cancel"
                        color="primary"
                        onClick={addNewVariant}
                        sx={{ fontSize: '12px' }}
                    >
                        Add New Variant
                    </Button>
                    <Button
                        type="submit"
                        variant="save"
                        color="primary"
                        disabled={loading}
                        sx={{ fontSize: '12px' }}
                    >
                        {loading ? 'Saving...' : 'Save Variants'}
                    </Button>
                </Box>
            </form>
        </Paper>
    );
};

export default VariantForm;