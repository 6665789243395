import { useState } from 'react';
import {
    Box,
    Dialog,
    DialogContent,
    IconButton,
    Typography,
    Grid,
    Chip,
    Divider,
    Modal,
    Stack,
    Button,
} from '@mui/material';
import {
    KeyboardArrowLeft as ArrowLeftIcon,
    KeyboardArrowRight as ArrowRightIcon,
    ZoomIn as ZoomInIcon,
    Close as CloseIcon,
    CameraAlt as CameraIcon,
    ShoppingCart as CartIcon,
    LocalShipping as ShippingIcon,
    Inventory as StockIcon,
    Warning as WarningIcon,
    KeyboardArrowUp as ArrowUpIcon,
    KeyboardArrowDown as ArrowDownIcon,
} from '@mui/icons-material';

const ProductPlaceholder = ({ size = 'default' }) => {
    const styles = {
        default: {
            container: {
                width: '100%',
                height: '100%',
                gap: 1,
            },
            icon: { fontSize: '24px' },
            text: { fontSize: '12px' }
        },
        small: {
            container: {
                width: '24px',
                height: '24px',
                gap: 0.2,
            },
            icon: { fontSize: '12px' },
            text: { display: 'none' }
        }
    };

    const sizeStyle = styles[size];

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: '#f5f5f5',
                border: '1px dashed #bdbdbd',
                borderRadius: '4px',
                ...sizeStyle.container
            }}
        >
            <CameraIcon sx={{ color: '#bdbdbd', ...sizeStyle.icon }} />
            {size === 'default' && (
                <Typography variant="caption" sx={{ color: '#757575', ...sizeStyle.text }}>
                    No image
                </Typography>
            )}
        </Box>
    );
};

const ProductDetailsDialog = ({ open, onClose, product }) => {
    const [selectedImageIndex, setSelectedImageIndex] = useState(0);
    const [showZoomHint, setShowZoomHint] = useState(false);
    const [zoomOpen, setZoomOpen] = useState(false);
    const [startIndex, setStartIndex] = useState(0);
    const [selectedVariants, setSelectedVariants] = useState({});
    const [quantity, setQuantity] = useState(1);
    const IMAGE_URL = 'https://s3.ap-southeast-5.amazonaws.com/my.merchants/';

    if (!product) return null;

    const allImages = product.product_images || [];
    const visibleThumbnails = allImages.slice(startIndex, startIndex + 5);
    const paddedThumbnails = [...visibleThumbnails];
    while (paddedThumbnails.length < 5) {
        paddedThumbnails.push(null);
    }

    const getCurrentImage = () => {
        if (allImages.length > 0) {
            return IMAGE_URL + allImages[selectedImageIndex].image_url;
        }
        return null;
    };

    const handleZoomOpen = () => setZoomOpen(true);
    const handleZoomClose = () => setZoomOpen(false);

    const isLowStock = product.stock_quantity <= product.low_stock_threshold;
    const isOutOfStock = product.stock_quantity === 0;

    // Group variants by their names (e.g., "Size", "Color")
    const groupedVariants = product.product_variants.reduce((acc, variant) => {
        if (!acc[variant.variant_name]) {
            acc[variant.variant_name] = [];
        }
        acc[variant.variant_name].push(variant);
        return acc;
    }, {});

    const styles = {
        dialog: {
            '& .MuiDialog-paper': {
                maxWidth: '1200px',
                width: '100%',
                margin: '16px',
                borderRadius: '12px',
                overflow: 'hidden'
            }
        },
        header: {
            bgcolor: '#ffeb3b',
            py: 2,
            px: 3,
            borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
        },
        imageContainer: {
            display: 'flex',
            padding: 2,
            gap: 2,
            bgcolor: '#fff'
        },
        thumbnailColumn: {
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            width: '100px'
        },
        mainImageSection: {
            width: '100%',
            height: '500px',
            overflow: 'hidden',
            position: 'relative',
            bgcolor: '#f5f5f5',
            borderRadius: '8px',
            marginTop:'60px',
        },
        contentSection: {
            p: 4,
            marginTop:'20px',
        },
        variantButton: {
            minWidth: 'auto',
            px: 3,
            py: 1,
            borderRadius: '20px',
            border: '2px solid',
            m: 0.5
        },
        infoCard: {
            bgcolor: '#fff',
            p: 3,
            borderRadius: 2,
            border: '1px solid #e0e0e0',
            height: '100%',
            boxShadow: '0 2px 8px rgba(0,0,0,0.1)'
        },
        stockChip: {
            borderRadius: '4px',
            px: 1,
            py: 0.5,
            fontSize: '0.875rem',
            fontWeight: 500
        },
        thumbnailImage: {
            width: '100px',
            height: '100px',
            borderRadius: '4px',
            cursor: 'pointer',
            objectFit: 'cover',
            border: '2px solid transparent',
            transition: 'all 0.2s ease',
            '&:hover': {
                borderColor: '#ffeb3b',
                transform: 'translateY(-1px)',
            }
        }
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="lg"
            fullWidth
            sx={styles.dialog}
        >
            {/* Header */}
            <Box sx={styles.header}>
                <Typography variant="h6" fontWeight="600" color="black">
                    Product Details
                </Typography>
                <IconButton onClick={onClose} sx={{ color: 'black' }}>
                    <CloseIcon />
                </IconButton>
            </Box>

            <DialogContent sx={{ p: 0 }}>
                <Grid container>
                    {/* Left Column - Images */}
                    <Grid item xs={12} md={7}>
                        <Box sx={styles.imageContainer}>
                            {/* Thumbnail Column */}
                            <Box sx={styles.thumbnailColumn}>
                                <IconButton
                                    size="small"
                                    onClick={() => setStartIndex(prev => Math.max(0, prev - 1))}
                                    disabled={startIndex === 0}
                                    sx={{ alignSelf: 'center' }}
                                >
                                    <ArrowUpIcon />
                                </IconButton>

                                <Stack spacing={1}>
                                    {paddedThumbnails.map((image, index) => (
                                        <Box
                                            key={`thumb-${index}`}
                                            sx={{
                                                width: '100px',
                                                height: '100px',
                                                position: 'relative',
                                                border: selectedImageIndex === (startIndex + index)
                                                    ? '2px solid #ffeb3b'
                                                    : '2px solid transparent',
                                                borderRadius: '4px',
                                                overflow: 'hidden',
                                            }}
                                        >
                                            {image ? (
                                                <Box
                                                    component="img"
                                                    src={`${IMAGE_URL}${image.image_url}`}
                                                    alt={`Thumbnail ${index + 1}`}
                                                    onClick={() => setSelectedImageIndex(startIndex + index)}
                                                    sx={styles.thumbnailImage}
                                                />
                                            ) : (
                                                <ProductPlaceholder size="default" />
                                            )}
                                        </Box>
                                    ))}
                                </Stack>

                                <IconButton
                                    size="small"
                                    onClick={() => setStartIndex(prev => Math.min(prev + 1, Math.max(0, allImages.length - 5)))}
                                    disabled={startIndex >= Math.max(0, allImages.length - 5)}
                                    sx={{ alignSelf: 'center' }}
                                >
                                    <ArrowDownIcon />
                                </IconButton>
                            </Box>

                            {/* Main Image */}
                            <Box sx={styles.mainImageSection}>
                                <Box
                                    sx={{
                                        position: 'relative',
                                        height: '100%',
                                        '&:hover .zoom-hint': { opacity: 1 },
                                    }}
                                    onMouseEnter={() => setShowZoomHint(true)}
                                    onMouseLeave={() => setShowZoomHint(false)}
                                >
                                    {getCurrentImage() ? (
                                        <>
                                            <Box
                                                component="img"
                                                src={getCurrentImage()}
                                                alt={product.name}
                                                onClick={handleZoomOpen}
                                                sx={{
                                                    width: '100%',
                                                    height: '100%',
                                                    objectFit: 'contain',
                                                    cursor: 'zoom-in',
                                                }}
                                            />
                                            <Box
                                                className="zoom-hint"
                                                sx={{
                                                    position: 'absolute',
                                                    top: '50%',
                                                    left: '50%',
                                                    transform: 'translate(-50%, -50%)',
                                                    bgcolor: 'rgba(0, 0, 0, 0.6)',
                                                    color: 'white',
                                                    px: 2,
                                                    py: 1,
                                                    borderRadius: '20px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: 1,
                                                    opacity: 0,
                                                    transition: 'opacity 0.3s ease',
                                                    pointerEvents: 'none',
                                                }}
                                            >
                                                <ZoomInIcon sx={{ fontSize: 20 }} />
                                                <Typography variant="body2">Click to zoom</Typography>
                                            </Box>
                                        </>
                                    ) : (
                                        <ProductPlaceholder size="default" />
                                    )}
                                </Box>
                            </Box>
                        </Box>
                    </Grid>

                    {/* Right Column - Product Info */}
                    <Grid item xs={12} md={5} sx={{ borderLeft: '1px solid #eee' }}>
                        <Box sx={styles.contentSection}>
                            <Typography variant="h5" fontWeight="600" gutterBottom>
                                {product.name}
                            </Typography>

                            {/*<Box sx={{ my: 2 }}>*/}
                            {/*    <Typography variant="h4" color="error" fontWeight="600" gutterBottom>*/}
                            {/*        RM {product.price}*/}
                            {/*    </Typography>*/}

                            {/*    {isOutOfStock ? (*/}
                            {/*        <Chip*/}
                            {/*            label="Out of Stock"*/}
                            {/*            color="error"*/}
                            {/*            icon={<WarningIcon />}*/}
                            {/*            sx={styles.stockChip}*/}
                            {/*        />*/}
                            {/*    ) : isLowStock ? (*/}
                            {/*        <Chip*/}
                            {/*            label={`Low Stock: ${product.stock_quantity} left`}*/}
                            {/*            color="warning"*/}
                            {/*            icon={<WarningIcon />}*/}
                            {/*            sx={styles.stockChip}*/}
                            {/*        />*/}
                            {/*    ) : (*/}
                            {/*        <Chip*/}
                            {/*            label={`In Stock: ${product.stock_quantity} available`}*/}
                            {/*            color="success"*/}
                            {/*            icon={<StockIcon />}*/}
                            {/*            sx={styles.stockChip}*/}
                            {/*        />*/}
                            {/*    )}*/}
                            {/*</Box>*/}

                            <Divider sx={{ my: 2 }} />

                            <Box sx={{ mb: 3 }}>
                                <Typography variant="body1" color="text.secondary">
                                    {product.description}
                                </Typography>
                            </Box>

                            {/* Variants */}
                            {product.has_variants && Object.entries(groupedVariants).map(([variantName, variants]) => (
                                <Box key={variantName} sx={{ mb: 3 }}>
                                    <Typography variant="subtitle1" fontWeight="600" gutterBottom>
                                        Select {variantName}
                                    </Typography>
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                                        {variants.map((variant) => (
                                            <Button
                                                key={variant.id}
                                                variant={selectedVariants[variantName] === variant.variant_value ? "contained" : "outlined"}
                                                sx={{
                                                    ...styles.variantButton,
                                                    bgcolor: selectedVariants[variantName] === variant.variant_value ? '#ffeb3b' : 'transparent',
                                                    color: 'black',
                                                    borderColor: selectedVariants[variantName] === variant.variant_value ? '#ffeb3b' : '#bdbdbd',
                                                    '&:hover': {
                                                        bgcolor: selectedVariants[variantName] === variant.variant_value ? '#ffeb3b' : 'rgba(255, 235, 59, 0.1)',
                                                    }
                                                }}
                                                onClick={() => setSelectedVariants({
                                                    ...selectedVariants,
                                                    [variantName]: variant.variant_value
                                                })}
                                            >
                                                {variant.variant_value}
                                            </Button>
                                        ))}
                                    </Box>
                                </Box>
                            ))}

                            {/* Additional Info */}
                            {/*<Box sx={styles.infoCard}>*/}
                            {/*    <Stack spacing={2}>*/}
                            {/*        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>*/}
                            {/*            <StockIcon />*/}
                            {/*            <Typography>Stock Level: {product.stock_quantity}</Typography>*/}
                            {/*        </Box>*/}
                            {/*    </Stack>*/}
                            {/*</Box>*/}
                        </Box>
                    </Grid>
                </Grid>
            </DialogContent>

            {/* Zoom Modal */}
            <Modal
                open={zoomOpen}
                onClose={handleZoomClose}
                aria-labelledby="zoom-modal"
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    '& .MuiBackdrop-root': {
                        backgroundColor: 'rgba(0, 0, 0, 0.9)',
                    },
                }}
            >
                <Box sx={{ position: 'relative', maxWidth: '90vw', maxHeight: '90vh' }}>
                    <IconButton
                        onClick={handleZoomClose}
                        sx={{
                            position: 'absolute',
                            top: -40,
                            right: 0,
                            color: 'white',
                            backgroundColor: 'rgba(255, 255, 255, 0.1)',
                            '&:hover': {
                                backgroundColor: 'rgba(255, 255, 255, 0.2)',
                            },
                        }}
                    >
                        <CloseIcon />
                    </IconButton>

                    {getCurrentImage() ? (
                        <Box
                            component="img"
                            src={`${IMAGE_URL}${getCurrentImage()}`}
                            alt={product.name}
                            sx={{
                                maxWidth: '90vw',
                                maxHeight: '90vh',
                                objectFit: 'contain',
                                backgroundColor: 'transparent',
                            }}
                        />
                    ) : (
                        <ProductPlaceholder size="default" />
                    )}

                    {/* Navigation Arrows */}
                    {allImages.length > 1 && (
                        <>
                            <IconButton
                                sx={{
                                    position: 'absolute',
                                    left: 16,
                                    top: '50%',
                                    transform: 'translateY(-50%)',
                                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                    '&:hover': {
                                        backgroundColor: 'rgba(255, 255, 255, 0.2)',
                                    },
                                    color: 'white',
                                }}
                                onClick={() => setSelectedImageIndex((prev) =>
                                    prev === 0 ? allImages.length - 1 : prev - 1
                                )}
                            >
                                <ArrowLeftIcon />
                            </IconButton>
                            <IconButton
                                sx={{
                                    position: 'absolute',
                                    right: 16,
                                    top: '50%',
                                    transform: 'translateY(-50%)',
                                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                    '&:hover': {
                                        backgroundColor: 'rgba(255, 255, 255, 0.2)',
                                    },
                                    color: 'white',
                                }}
                                onClick={() => setSelectedImageIndex((prev) =>
                                    prev === allImages.length - 1 ? 0 : prev + 1
                                )}
                            >
                                <ArrowRightIcon />
                            </IconButton>
                        </>
                    )}

                    {/* Image Counter */}
                    <Box
                        sx={{
                            position: 'absolute',
                            bottom: 16,
                            left: '50%',
                            transform: 'translateX(-50%)',
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                            color: 'white',
                            padding: '4px 12px',
                            borderRadius: '12px',
                        }}
                    >
                        <Typography variant="body2">
                            {selectedImageIndex + 1} / {allImages.length}
                        </Typography>
                    </Box>
                </Box>
            </Modal>
        </Dialog>
    );
};

export default ProductDetailsDialog;