import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Box, Typography } from '@mui/material';

const Breadcrumb = () => {
    const location = useLocation();

    // Split the pathname into an array, removing empty strings
    const pathnames = location.pathname.split('/').filter(x => x);

    // Function to format the breadcrumb labels
    const formatBreadcrumbName = (name) => {
        // Capitalize the first letter and replace hyphens with spaces
        return name.charAt(0).toUpperCase() + name.slice(1).replace(/-/g, ' ');
    };

    return (
        <nav aria-label="breadcrumb">
            <Box sx={{ display:'flex', alignItems:'center', margin:'12px 0 0 32px' }}>
                <Typography component={Link} to="/" sx={{color:'#c2c2c2', fontSize:'12px', fontWeight:'500', textDecoration:'none', mr:'4px'}}>
                    Home
                </Typography>
                {pathnames.map((value, index) => {
                    // Build the path to this segment
                    const to = `/${pathnames.slice(0, index + 1).join('/')}`;

                    // If this is the last item, it should be active and not a link
                    const isLast = index === pathnames.length - 1;

                    return (
                        <Box key={to} display="flex" alignItems="center">
                            <Typography component={Link} to="/" sx={{color:'#8f8f8f', fontSize:'12px', fontWeight:'500', textDecoration:'none', mr:'4px'}}>
                                {" > "}
                            </Typography>
                            {isLast ? (
                                <Typography component={Link} sx={{color:'orange', fontSize:'12px', fontWeight:'500', textDecoration:'none', mr:'4px'}}>
                                    {formatBreadcrumbName(value)}
                                </Typography>
                            ) : (
                                <Typography component={Link} to={to} sx={{color:'#c2c2c2', fontSize:'12px', fontWeight:'500', textDecoration:'none', mr:'4px'}}>
                                    {formatBreadcrumbName(value)}
                                </Typography>
                            )}
                        </Box>
                    );
                })}
            </Box>
        </nav>
    );
};

export default Breadcrumb;
