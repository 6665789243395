import {useState} from 'react';
import {Box, Button, MenuItem, Select, TextField, Typography, FormControl, InputLabel, Divider} from '@mui/material';
import {styled} from "@mui/system";

const TextFieldTitle = styled(Typography)(({ theme }) => ({
    color: 'var(--Gray-700, #344054)',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '20px',
    margin:'12px 0 -12px 0',
}));


const TaxDetails = () => {
    const [businessType, setBusinessType] = useState('');
    const handleBusinessTypeChange = (event) => {
        setBusinessType(event.target.value);
    };
    const [focused, setFocused] = useState(false);

    return (
        <Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', alignSelf: 'stretch', width: '100%'}}>
                <Box>
                    <Typography variant='h4'>
                        Tax Details
                    </Typography >
                    <Typography variant='h5'>
                        We need to collect this information so we can submit accurate tax reports.
                    </Typography>
                </Box>
                <Box sx={{ ml: 'auto', display: 'flex', gap: '12px' }}>
                </Box>
            </Box>

            <Divider/>

            <Box sx={{marginTop:'32px'}}>
                <Typography variant="body2" color="textSecondary" gutterBottom>
                    Updating this information may introduce new account requirements, listed on your <a href="#">business information page</a>. You will have a 7-day grace period during which account functionality remains the same.
                    To prevent disabled charges or payouts, you must resolve all requirements by the end of the grace period.
                </Typography>

                <TextFieldTitle>Type of business</TextFieldTitle>
                <FormControl fullWidth margin="normal">
                    <InputLabel>Please choose an option</InputLabel>
                    <Select
                        value={businessType}
                        onChange={handleBusinessTypeChange}
                        label='Please choose an option'
                    >
                        <MenuItem value="sole_proprietorship">Sole Proprietorship</MenuItem>
                        <MenuItem value="partnership">Partnership</MenuItem>
                        <MenuItem value="corporation">Corporation</MenuItem>
                        <MenuItem value="llc">LLC</MenuItem>
                        {/* Add more options as needed */}
                    </Select>
                </FormControl>

                <TextFieldTitle>The name you provide must exactly match the name associated with your MyCoID.</TextFieldTitle>
                <FormControl fullWidth margin="normal">
                    <TextField
                        variant="outlined"
                    />
                </FormControl>

                <TextFieldTitle>Company number (MyCoID)</TextFieldTitle>
                <FormControl fullWidth margin="normal">
                    <TextField
                        variant="outlined"
                        label={!focused ? '123456-A' : ''}
                        defaultValue=""
                        InputLabelProps={{
                            shrink: false,
                        }}
                        onFocus={() => setFocused(true)}
                        onBlur={() => setFocused(false)}
                    />
                </FormControl>

                <Box display="flex" justifyContent="flex-end" mt={2}>
                    <Button variant="cancel" color="inherit" sx={{ mr: 1 }}>
                        Cancel
                    </Button>
                    <Button variant="save" color="primary">
                        Update
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};

export default TaxDetails;
